(function() {
  'use strict';

  function AllEventsController($state, $rootScope, Profile, Event, EventSection, Events,
                               EventSearch, APIList, Utils) {
    var ctrl = this;
    ctrl.options = {
      highlightField: 'text'
    };

    Utils.setPageTitle('All events for other users');

    ctrl.searchModel = Profile.getListPreferences('allevents').current;
    var listName = 'allevents';
    ctrl.options.search = ctrl.searchModel;

    var makeDocs = function(item) {
      if (item.type === 'event') {
        return new Event(item, { noExtra: true, isLocal: false });
      } else if (item.type === 'eventSection') {
        return new EventSection(item, { noExtra: true, isLocal: false });
      }
    };

    var loadEvents = function() {
      return ctrl.list.doLoadItems()
      // return $q.all([
      //   EventTypes.findAll(),
      //   Blueprints.findAll()
      // ])
      // .then(function() {
      //   return ctrl.list.doLoadItems();
      // })
      .catch(function(error) {
        Utils.showError(error, function() {
          $state.go('epf.users.index');
        });
      });
    };

    function setupList(search) {
      ctrl.list = new APIList(Events, {
        search: search,
        model: ctrl.searchModel,
        makeDocs: makeDocs
      });
      return loadEvents();
    }

    EventSearch.getSearch('allsearch')
      .then(function(search) {
        return setupList(search);
      })
      .then(function() {
        ctrl.loaded = true;
        $rootScope.$broadcast('KZCurrentListChange', { list: listName, search: ctrl.list.search });
      });
  }

  AllEventsController.$inject = ['$state', '$rootScope', 'ProfileService',
    'EventFactory', 'EventSectionFactory', 'EventsService', 'EventSearch',
    'APIListFactory', 'UtilsService'];

  angular.module('component.events')
    .controller('AllEventsController', AllEventsController);
})();
