(function() {
  'use strict';

  function validate(url, domElement) {
    var isValid = true;
    var warnings = [];
    var errors = [];

    if (url && domElement) {
      isValid = false;
      warnings.push('You are trying to print both a DOM element and a URL.');
      warnings.push('Ignoring the DOM element...');
    }

    if (!url && !domElement) {
      isValid = false;
      errors.push(
        'You need to specify either the "url" or "target" props for the print-pdf directive!'
      );
    }

    return { isValid: isValid, warnings: warnings, errors: errors };
  }

  function withColors(content, colors) {
    return '<body style="' + colors + '">' + content + '</body>';
  }

  function PrintPdfController(
    $scope,
    $window,
    $q,
    $timeout,
    PdfService,
    Styles,
    Auth,
    Notify,
    Utils,
    PDF_DEFAULTS,
    PDF_MAX_PAYLOAD_SIZE
  ) {
    var ctrl = this;
    var error;
    var type = $scope.url ? 'url' : 'html';

    ctrl.buttonTitle = $scope.buttonTitle || PDF_DEFAULTS.buttonTitle;
    ctrl.loading = false;

    var validation = validate($scope.url, $scope.domElement);

    if (!validation.isValid) {
      if (validation.warnings.length > 0) {
        console.warn(validation.warnings.join('\n'));
      }

      if (validation.errors.length > 0) {
        error = validation.errors.join('\n');
        console.error(error);
      }
    }

    var setupPrintPayload = function() {
      return $q.when()
        .then(function() {
          var payload = {};

          if ($scope.url) {
            payload.url = $scope.url;
          }

          if ($scope.filename) {
            payload.filename = PdfService.ensurePdfExt($scope.filename);
          }

          return payload;
        })
        .then(function(payload) {
          if ($scope.domElement && !$scope.url) {
            var el = document.getElementById($scope.domElement);
            var baseStyleUrl = $window.location.protocol + '//' + $window.location.host;
            var stylesheets = Array.from(
              document.head.getElementsByTagName('link')
            ).filter(function(link) {
              return link.getAttribute('rel') === 'stylesheet';
            });

            payload.styleUrls = stylesheets.map(function(link) {
              return baseStyleUrl + '/' + link.getAttribute('href');
            });

            if (el) {
              var html = PdfService.format(el);
              var colors = Styles.getColors(Auth.currentOrganisation());
              var styles = Styles.getColorProperties(colors);
              payload.html = withColors(html, styles);
              return payload;
            }

            Notify.error('Oops, looks like there is nothing to print...');
            error = 'The element you specified does not exist on the page!';
          }

          return payload;
        });
    };

    var print = function(payload) {
      var payloadSize = Utils.getMemorySizeOfObject(payload);

      if (payloadSize > PDF_MAX_PAYLOAD_SIZE) {
        Notify.error(
          'You are trying to print too much information at once. Please split ' +
          'your print job across multiple downloads.', 'File too large',
          0
        );
        ctrl.loading = false;
      } else {
        PdfService.print(type, payload)
          .then(function(response) {
            if (response.status === 200 && response.headers('content-type') === 'application/pdf') {
              // response.headers('Content-Disposition') comes back null, no idea why
              PdfService.savePdf(response.data, $scope.filename || PDF_DEFAULTS.filename);
              error = null;
            } else {
              console.error(response);
              error = 'There was a problem with the request to print!';
            }
            ctrl.loading = false;
          })
          .catch(function(err) {
            if (err.status === 413) {
              Notify.error(
                'You are trying to print too much information at once. Please split ' +
                'your print job across multiple downloads.', 'File too large',
                0
              );
            } else if (err.status === -1) {
              Notify.warning(
                'Either check your connectivity or use your built-in browser print functionality.',
                'Printing not available offline',
                0
              );
            } else if (err.status === 400) {
              PdfService.parseError(err.data)
                .then(function(json) {
                  console.error(json);
                  Notify.error(json.errors.join('\n'), json.message, 0);
                });
            }
            ctrl.loading = false;
            error = null;
          });
      }
    };

    ctrl.pdf = function() {
      ctrl.loading = true;

      if (error || validation.errors.length > 0) {
        Notify.error('Oops, looks like there is nothing to print...');
        console.error(error);
        ctrl.loading = false;
        return;
      }

      // Because of the $digest cycle,
      // we need to wait a while before the element is fully present on the page
      var delayMs = type === 'url' ? 0 : 300;

      $timeout(function() {
        setupPrintPayload()
          .then(print);
      }, delayMs);
    };
  }

  function PrintPdfButton() {
    return {
      scope: {
        buttonTitle: '@?',
        url: '@?',
        domElement: '@?',
        filename: '@?'
      },
      replace: true,
      restrict: 'E',
      templateUrl: 'app/blocks/pdf/print-pdf.html',
      controller: PrintPdfController,
      controllerAs: 'pdfPrintCtrl'
    };
  }

  PrintPdfController.$inject = [
    '$scope',
    '$window',
    '$q',
    '$timeout',
    'PdfService',
    'StylesService',
    'AuthService',
    'NotifyService',
    'UtilsService',
    'PDF_DEFAULTS',
    'PDF_MAX_PAYLOAD_SIZE'
  ];

  angular.module('blocks.pdf')
    .directive('kzPrintPdfButton', PrintPdfButton)
    .controller('PrintPdfController', PrintPdfController);
})();
