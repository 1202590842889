(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider

      .state('epf.importer', {
        url: 'importer',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],

          access: [
            '$q', 'ImporterService',
            function($q, Importer) {
              return Importer.getAllUserPermissions()
                .then(function(userPerms) {
                  if (_.isEmpty(userPerms)) {
                    var msg = 'You are not allowed to view import area';
                    return $q.reject({ status: 403, message: msg });
                  }
                });
            }]
        }
      })
      .state('epf.importer.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/importer/importTasks.html',
            controller: 'ImportTasksController',
            controllerAs: 'importTasksCtrl'
          }
        }
      })
      .state('epf.importer.new', {
        url: '/new',
        views: {
          content: {
            templateUrl: 'app/components/importer/import.html',
            controller: 'ImportController',
            controllerAs: 'importCtrl'
          }
        }
      })
      .state('epf.importer.edit', {
        url: '/edit/:id',
        views: {
          content: {
            templateUrl: 'app/components/importer/import.html',
            controller: 'ImportController',
            controllerAs: 'importCtrl'
          }
        }
      })
      .state('epf.importer.view', {
        url: '/view/:id',
        views: {
          content: {
            templateUrl: 'app/components/importer/importView.html',
            controller: 'ImportViewController',
            controllerAs: 'importViewCtrl'
          }
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.importer')
    .config(config);
})();
