import './announcements.module.js';
import './directives';
import './widgets';
import './announcements.controller.js';
import './announcements-reorder.controller.js';
import './announcement.controller.js';
import './announcements.route.js';
import './announcements.utils.js';
import './announcements.service.js';
import './announcement-preview.controller.js';
import './inbox';
