(function() {
  'use strict';

  function PdfService($http, Utils, PDF_API_URLS, PDF_URL_ENDPOINTS) {
    var service = {};

    function createImageAsString(src, id) {
      return '<img src="' + src + '" id="' + id + '" />';
    }

    // This performs a mutation on the element,
    // by adding a data - image - id attribute to each canvas element.
    // We track which image corresponds to each canvas,
    // so that we can use a regex to replace it later
    function convertImagesFromCanvas(element) {
      return Array.from(element.querySelectorAll('canvas'))
        .map(function(canvas) {
          var dataUrl = canvas.toDataURL();
          var id = Utils.guid();
          // We use the ID to track which image corresponds to which canvas
          canvas.setAttribute('data-image-id', id);
          return {
            id: id,
            html: createImageAsString(dataUrl, id)
          };
        });
    }

    function replaceCanvasWithImage(html, images) {
      images.forEach(function(image) {
        var regex =
          new RegExp('<canvas(?:.+?)data-image-id="' + image.id + '"(><\/canvas>|\/>)', 'igm');
        html = html.replace(regex, image.html);
      });
      return html;
    }

    service.print = function(type, payload) {
      var request = {
        method: 'POST',
        url: PDF_API_URLS[PDF_URL_ENDPOINTS[type]],
        data: payload,
        responseType: 'blob',
        headers: {
          accept: 'application/pdf'
        }
      };

      return $http(request);
    };

    service.savePdf = function(data, filename) {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(data);
      link.download = service.ensurePdfExt(filename);
      link.innerText = 'Download';
      link.click();
      // document.body.appendChild(link);
      // window.URL.revokeObjectURL(link.href);
      // link.remove();
    };

    service.parseError = function(data) {
      return data.text()
        .then(function(text) {
          return JSON.parse(text);
        });
    };

    service.ensurePdfExt = function(filename) {
      var ext = filename.split('.').pop();
      return ext === 'pdf' ? filename : filename + '.pdf';
    };

    service.format = function(element) {
      var imagesFromCanvas = convertImagesFromCanvas(element);
      var html = replaceCanvasWithImage(element.innerHTML, imagesFromCanvas);

      return html;
    };

    return service;
  }

  PdfService.$inject = ['$http', 'UtilsService', 'PDF_API_URLS', 'PDF_URL_ENDPOINTS'];

  angular.module('blocks.pdf')
    .service('PdfService', PdfService);
})();
