(function() {
  'use strict';

  function ImageController($scope, orderBy, ImageService, IMAGE_MISSING_SRC) {
    var ctrl = this;

    var cache = ImageService.setCacheStrategy($scope.cache);
    var prefetch = ImageService.setPrefetch($scope.prefetch);
    var signed = typeof $scope.signed === 'undefined' ? false : $scope.signed;

    if ($scope.formats) {
      if (!_.isArray($scope.formats)) {
        console.warn('Image formats expected an Array, you passed ', typeof $scope.formats);
      } else {
        var formats = ImageService.validFormats($scope.formats);
        ctrl.formats = orderBy(formats, ImageService.getFormatSortOrder);
      }
    }

    if ($scope.sizes && !_.isObject($scope.sizes)) {
      console.warn('Image sizes expected an Object, you passed ', typeof $scope.sizes);
    } else {
      ctrl.sizes = $scope.sizes;
    }

    ctrl.image = {
      sources: {},
      src: '',
      layout: ImageService.setLayout($scope.layout),
      alt: $scope.alt || ''
    };

    if ($scope.src) {
      ctrl.image.src = $scope.src;
    }

    function useLoader(loader) {
      // These are all the elements that constitute an unique API call
      var imageOptions = {
        url: loader,
        signed: signed,
        formats: $scope.formats,
        sizes: $scope.sizes
      };

      var cacheOptions = { cache: cache, maxAge: $scope.maxAge, forceUpdate: false };

      ImageService.loadImage(imageOptions, cacheOptions)
        .then(function prefetchOrLoad(imageData) {
          // If we prefetch, we're going to set the image content in the catch!
          return prefetch ? ImageService.prefetch(imageData.content, imageData, signed) : imageData;
        })
        .then(function setImageSources(imageData) {
          // At this point we have either real image or the missing image
          ctrl.image.sources = imageData.sources || {};
          ctrl.image.src = imageData.content;
          var shouldRevalidate =
            cache === 'stale-while-revalidate' && imageData.servedFrom !== 'api';

          if (shouldRevalidate) {
            // The image is refetched and recached, but the UI is not updated
            var updatedCacheOptions = _.assign({}, cacheOptions, { forceUpdate: true });
            return ImageService.loadImage(imageOptions, updatedCacheOptions);
          }
        })
        .catch(function imageLoaderError(err) {
          ctrl.image.src = IMAGE_MISSING_SRC;
          console.error(err);
        });
    }

    if ($scope.loader && !$scope.src) {
      useLoader($scope.loader);
    }

    if ($scope.loader && $scope.src) {
      console.warn('You\'re trying to set an image\'s source from both an URL and an API');
    }

    $scope.$watch('loader', function(value) {
      if (!_.isUndefined(value)) {
        useLoader(value);
      }
    });
  }

  function Image() {
    return {
      scope: {
        src: '@?',
        loader: '@?',
        signed: '=?',
        prefetch: '=?',
        cache: '@?',
        maxAge: '=?',
        layout: '@?',
        sizes: '=?',
        formats: '=?',
        alt: '@'
      },
      replace: true,
      restrict: 'E',
      templateUrl: function(_elem, attr) {
        var tmpl = typeof attr.formats === 'undefined' ? 'image' : 'picture';
        return 'app/blocks/image/' + tmpl + '.html';
      },
      controller: ImageController,
      controllerAs: 'imageCtrl'
    };
  }

  ImageController.$inject = [
    '$scope',
    'orderByFilter',
    'ImageService',
    'IMAGE_MISSING_SRC'
  ];

  angular
    .module('blocks.image')
    .directive('kzImage', Image)
    .controller('ImageController', ImageController);
})();
