(function() {
  'use strict';

  var mainMenu = [
    {
      title: 'Dashboard',
      extendBy: 'dashboard.index',
      links: [
        {
          title: 'Dashboard',
          state: 'dashboard.index',
          placeholder: true
        },
        {
          title: 'Manage dashboard templates',
          state: 'epf.dashboard-templates.index',
          permission: 'dashboardTemplates.view'
        },
        {
          title: 'Manage summary templates',
          state: 'epf.profile-templates.index',
          permission: 'profileTemplates.view'
        }
      ]
    },
    {
      title: 'Timeline',
      extendBy: 'epf.events.index',
      links: [
        {
          title: 'Timeline',
          state: 'epf.events.index',
          permission: 'events.view.own',
          placeholder: true
        },
        {
          title: 'Manage categories',
          state: 'epf.events.manage-timelines',
          permission: 'organisation.manage-timelines'
        }
      ]
    },
    {
      title: 'Documents',
      state: 'epf.documents.index',
      permission: 'documents.view.own'
    },
    {
      title: 'Content',
      links: [
        {
          title: 'FAQs',
          state: 'epf.faqs.index',
          permission: 'faqs.view'
        },
        {
          title: 'Event types',
          state: 'epf.event-types.index',
          permission: 'eventTypes.view'
        },
        {
          title: 'Blueprints',
          state: 'epf.blueprints.index',
          permission: 'blueprints.view'
        },
        {
          title: 'Blueprint Maps',
          state: 'epf.blueprintMaps.index',
          permission: 'blueprints.view',
          requireFeature: 'blueprint-maps'
        },
        {
          title: 'Announcements',
          state: 'epf.announcements.index',
          permission: 'announcements.view'
        },
        {
          title: 'Email templates',
          state: 'epf.email-templates.index',
          permission: 'emailTemplates.view'
        },
        {
          title: 'Import Data',
          state: 'epf.importer.index',
          permission: [
            'ImporterService',
            function(Importer) {
              return Importer.getAllUserPermissions()
                .then(function(userPerms) {
                  return !_.isEmpty(userPerms);
                });
            }
          ]
        }
      ]
    },
    {
      title: 'Goals',
      extendBy: 'epf.goals.index',
      links: [
        {
          title: 'Goals',
          state: 'epf.goals.index',
          permission: 'goals.work.own',
          placeholder: true
        },
        {
          title: 'Manage categories',
          state: 'epf.goals.manage-categories',
          permission: 'organisation.manage-categories'
        }
      ]
    },
    {
      title: 'Reports',
      links: [
        {
          title: 'Reports',
          state: 'epf.reportTemplates.index',
          permission: 'reports.view'
        }
      ]
    },
    {
      title: 'User management',
      links: [
        {
          title: 'Users',
          state: 'epf.users.index',
          permission: 'users.view'
        },
        {
          title: 'All events',
          state: 'epf.allevents.index',
          permission: 'events.view'
        },
        {
          title: 'Pending sections',
          state: 'epf.admin.pending-sections',
          permission: [
            'AdminService', 'SecurityService',
            function(AdminService, Security) {
              // Implementing or below - lets check the permission first
              // if no permission try the reviewable sections
              return Security.hasPermission('events.reviewPending')
                .catch(function() {
                  return AdminService.getReviewableSections()
                    .then(function(sections) {
                      return sections.length > 0;
                    });
                });
            }
          ]
        },
        {
          title: 'User fields',
          state: 'epf.user-fields.index',
          permission: 'userFields.view'
        },
        {
          title: 'Relations',
          state: 'epf.relations.index',
          permission: 'relations.view'
        },
        {
          title: 'Roles',
          state: 'epf.roles.index',
          permission: 'roles.view'
        },
        {
          title: 'Organisations',
          state: 'epf.organisations.index',
          permission: ['SecurityService', function(Security) {
            return Security.hasRole('system:superadmin');
          }]
        }
      ]
    }
      ],
      menu = {
        main: mainMenu
      };

  angular.module('epf.layout')
    .constant('HEADER_MENU', menu);
})();
