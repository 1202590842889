(function() {
  'use strict';

  function SlotController($q, $scope, $uibModal, $stateParams, $rootScope,
    BlueprintUtils, Notify, ICON_FILES_EXTENSIONS_MAP, LocalizationService) {
    var ctrl = this;
    if (!$scope.item.loaded) {
      $scope.item.init();
      $scope.item.loadExtra();
    }

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

    ctrl.username = $stateParams.user;
    ctrl.fileIcon = ICON_FILES_EXTENSIONS_MAP[$scope.item.doc.documentType];

    function openRenameDialog() {
      $uibModal.open({
        animation: true,
        size: 'lg',
        templateUrl: 'app/components/documents/document.rename.html',
        controller: 'DocumentRenameController',
        controllerAs: 'renameCtrl',
        resolve: {
          resolvedDoc: function() {
            return $scope.item;
          },
          options: {}
        }
      });
    }

    ctrl.buildPath = function() {
      return '/' + $scope.item.doc.path.join('/');
    };

    ctrl.actionButtons = [];
    function loadActionButtons() {
      var optionActions = [];
      if ($scope.options && $scope.options.actionButtons) {
        optionActions = $scope.options.actionButtons($scope.item);
      }
      ctrl.actionButtons = [
        {
          label: 'Rename',
          icon: 'icon-edit',
          onClick: openRenameDialog,
          showCondition: function() {
            return ctrl.username === undefined;
          },
          klass: 'text-info'
        },
        {
          label: 'Make private',
          icon: 'icon-lock',
          onClick: function() {
            $scope.item.makePrivate()
              .then(function() {
                if ($scope.item.isFolder()) {
                  Notify.success('Folder is now hidden from navigation');
                } else {
                  Notify.success('Document is now private');
                }
              })
              .catch(function() {
                Notify.warning('There was an error.');
              })
              .finally(function() {
                loadActionButtons();
              });
          },
          showCondition: function() {
            if ($scope.item.isFolder()) {
              this.label = 'Hide from navigation';
            }
            return ctrl.username === undefined && $scope.item.doc.visibility === 'public';
          },
          klass: 'text-info'
        },
        {
          label: 'Share',
          icon: 'icon-lock-open',
          onClick: function() {
            $scope.item.makePublic()
              .then(function() {
                if ($scope.item.isFolder()) {
                  Notify.success('Folder is now visible');
                } else {
                  Notify.success('Document is now shared');
                }
              })
              .catch(function() {
                Notify.warning('There was an error.');
              })
              .finally(function() {
                loadActionButtons();
              });
          },
          showCondition: function() {
            if ($scope.item.isFolder()) {
              this.label = 'Show in navigation';
            }
            return ctrl.username === undefined && $scope.item.doc.visibility === 'private';
          },
          klass: 'text-info'
        },
        {
          label: 'Delete',
          icon: 'icon-trash',
          onClick: function() {
            $scope.item.remove()
              .then(function() {
                Notify.success('Document is now deleted');
                $rootScope.$broadcast('KZItemRemoved', { item: $scope.item });
              })
              .catch(function(error) {
                if (error && error.status === 510) {
                  return;
                }
                Notify.warning('There was an error.');
              });
          },
          showCondition: function() {
            return ctrl.username === undefined;
          },
          klass: 'text-danger'
        }
      ].concat(optionActions);
    }

    loadActionButtons();

    ctrl.toggleDescription = function() {
      ctrl.shownDescription = !ctrl.shownDescription;
      // This is a hack - not sure how else to do it
      ctrl.secondaryActionButtons[0].label = ctrl.shownDescription ? 'Show less' : 'Show more';
    };


    ctrl.secondaryActionButtons = [
      {
        label: 'Show more',
        icon: 'icon-history',
        onClick: ctrl.toggleDescription,
        klass: 'text-info'
      }
    ];

    ctrl.openTagsPopup = function() {
      var def = $q.defer();
      var popup = $uibModal.open({
        animation: true,
        templateUrl: 'app/components/events/directives/tagspopup.html',
        size: 'dialog',
        controller: ['FormsService', '$uibModalInstance',
          function(Form, $uibModalInstance) {
            var popctrl = this;
            this.form = new Form();
            BlueprintUtils.getBlueprintsField($scope.item.doc.user)
              .then(function(data) {
                if (data === undefined) {
                  popctrl.nodata = true;
                  return;
                }
                popctrl.form.addField(data);
              });
            this.eventExtras = {};
            this.user = $scope.item.doc.user;
            this.secondaryActions = [];
            this.actions = [
              {
                label: 'Save',
                icon: 'icon-save',
                color: 'success',
                type: 'button',
                position: 0,
                onClick: function() {
                  $scope.item.event.addExtra(popctrl.eventExtras)
                    .then(function() {
                      return $scope.item.loadExtra();
                    })
                    .then(function() {
                      if (popctrl.eventExtras.blueprints.length > 1) {
                        Notify.success('Tags have been added');
                      } else {
                        Notify.success('Tag has been added');
                      }
                      popctrl.success();
                    })
                    .catch(function(err) {
                      console.log(err);
                      Notify.error('Could not add the tag(s)');
                    });
                }
              }
            ];

            this.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };

            this.success = function() {
              $uibModalInstance.close();
            };
          }
        ],
        controllerAs: 'ctrl'
      });

      popup.result.finally(function() {
        def.resolve();
      });
      return def.promise;
    };
  }

  SlotController.$inject = [
    '$q',
    '$scope',
    '$uibModal',
    '$stateParams',
    '$rootScope',
    'BlueprintUtils',
    'NotifyService',
    'ICON_FILES_EXTENSIONS_MAP',
    'LocalizationService'
  ];

  function SlotDirective() {
    return {
      scope: {
        item: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/documents/document.slot.html',
      controller: SlotController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.documents')
    .directive('documentSlot', SlotDirective)
    .controller('DocumentSlotController', SlotController);
})();
