(function() {
  'use strict';

  var msWordFilesExtensions = ['doc', 'docx', 'txt'];
  var msExcelFilesExtensions = ['xls', 'xlsx', 'xlr', 'csv'];
  var acrobatFilesExtensions = ['pdf'];
  var presentationFilesExtensions = ['key', 'pps', 'ppt', 'pptx'];
  var imagesFilesExtensions = [
    'bmp', 'gif', 'jpg', 'jpeg', 'png', 'psd', 'pspimage', 'thm', 'tif', 'yuv'
  ];
  var audioFilesExtensions = [
    'aif', 'iff', 'm3u', 'm4a', 'mid', 'mp3', 'mpa', 'ra', 'wav', 'wma'
  ];
  var compressedFilesExtensions = [
    '7z', 'gz', 'pkg', 'rar', 'rpm', 'sit', 'sitx', 'tar.gz', 'zip', 'zipx'
  ];

  var authorizedExtensions = msWordFilesExtensions.concat(
    msExcelFilesExtensions,
    acrobatFilesExtensions,
    presentationFilesExtensions,
    imagesFilesExtensions,
    audioFilesExtensions,
    compressedFilesExtensions
  );
  angular.module('component.documents')
    .constant('AUTHORIZED_FILES_EXTENSIONS', authorizedExtensions);

  var iconFilesExtensionsMap = {
    folder: 'folder'
  };

  authorizedExtensions.forEach(function logArrayElements(element) {
    if (msWordFilesExtensions.indexOf(element) >= 0) {
      iconFilesExtensionsMap[element] = 'file-word';
    } else if (msExcelFilesExtensions.indexOf(element) >= 0) {
      iconFilesExtensionsMap[element] = 'file-excel';
    } else if (acrobatFilesExtensions.indexOf(element) >= 0) {
      iconFilesExtensionsMap[element] = 'file-pdf';
    } else if (presentationFilesExtensions.indexOf(element) >= 0) {
      iconFilesExtensionsMap[element] = 'file-powerpoint';
    } else if (imagesFilesExtensions.indexOf(element) >= 0) {
      iconFilesExtensionsMap[element] = 'file-image';
    } else if (audioFilesExtensions.indexOf(element) >= 0) {
      iconFilesExtensionsMap[element] = 'file-audio';
    } else if (compressedFilesExtensions.indexOf(element) >= 0) {
      iconFilesExtensionsMap[element] = 'file-archive';
    }
  });
  angular.module('component.documents')
    .constant('ICON_FILES_EXTENSIONS_MAP', iconFilesExtensionsMap);
})();
