
(function() {
  'use strict';

  function GoalsDefineController($q, $scope, Blueprints, Form, Goal, Target, Utils) {
    var ctrl = this;

    if (
      !$scope.readOnly &&
      !$scope.editMode &&
      $scope.atLeastOne &&
      (_.isUndefined($scope.model) || _.isEmpty($scope.model))
    ) {
      $scope.model = [
        Goal.setDefaults()
      ];
    }

    if ($scope.readonly) {
      $scope.options = {
        formState: { readOnly: true, type: 'goal' }
      };
    }

    ctrl.splitAchievementCount = function(condition) {
      condition.periodMeasurements = [{ count: condition.count }];
    };

    ctrl.targetsShown = {};
    ctrl.showTarget = function(targetId) {
      ctrl.targetsShown[targetId] = true;
    };

    ctrl.hideTarget = function(targetId) {
      // check first that the form is valid. Otherwise the user can save the event type with some
      // invalid forms.
      var formController = ctrl.targetForms[targetId];
      if (!_.isUndefined(formController) && !formController.$valid) {
        Utils.swal({
          title: 'There are some mandatory settings missing within this target, please make sure ' +
            'everything is completely correctly.',
          type: 'error'
        });
        return;
      }

      if (!_.isUndefined(ctrl.targetsShown[targetId])) {
        ctrl.targetsShown[targetId] = false;
      }
    };

    ctrl.goalForms = {};
    ctrl.targetBasicForms = {};
    ctrl.linkingFiltersForms = {};
    ctrl.progressFiltersForms = {};

    function createForm(typeOfForm, objId) {
      var formList,
          form;
      switch (typeOfForm) {
        case 'goalForm':
          formList = ctrl.goalForms;
          form = new Form([
            {
              id: 'title',
              type: 'string',
              label: 'Goal title',
              required: true
            },
            {
              id: 'description',
              type: 'text',
              label: 'Goal description',
              required: false
            }
          ]);
          break;
        case 'targetBasicForm':
          formList = ctrl.targetBasicForms;
          form = new Form([
            {
              id: 'title',
              type: 'string',
              label: 'Target title',
              required: true
            }
          ]);
          break;
        case 'linkingFiltersForm':
          var autoLinkOpts = [
            {
              _id: 'autoLink',
              key: 'link',
              name: 'Automatically link new events which match this filter to this target'
            },
            {
              _id: 'doNotAutoLink',
              key: 'doNotAutoLink',
              name: 'Don\'t automatically link new events to this target, I\'ll manually link ' +
                'them myself'
            }
          ];

          formList = ctrl.linkingFiltersForms;
          form = new Form([
            {
              id: 'filters',
              type: 'filters',
              label: 'Choose how to link events to this target by setting filters below.',
              required: true
            },
            {
              id: 'autolinked',
              type: 'discrete',
              label: 'How would you like to link?',
              defaultValue: 'doNotAutoLink',
              required: true,
              options: autoLinkOpts,
              getTitle: function(val) {
                var item = _.find(autoLinkOpts, { _id: val });
                if (item) {
                  return item.name;
                }

                return val;
              }
            }
          ]);
          break;
        case 'progressFiltersForm':
          formList = ctrl.progressFiltersForms;
          form = new Form([
            {
              id: 'differentAsLinkingFilters',
              type: 'discrete',
              label: 'By default progress is measured using the same filters as those for ' +
                     'linking events. Would you like to define different filters for ' +
                     'measuring progress?',
              helpText: '',
              required: true,
              options: [
                { _id: false, key: false, name: 'Use the same filters' },
                { _id: true, key: true, name: 'Define different filters to measure progress' }
              ]
            },
            {
              id: 'progressFilters',
              type: 'filters',
              label: 'Choose how to measure progress against this target by setting filters ' +
                'below. Progress is calculated using the events which are linked to this ' +
                'target.',
              helpText: ' If no filters are defined to measure progress all linked events ' +
                'will be counted.',
              required: false,
              hideExpression: function(_$viewValue, _$modelValue, $scope) {
                return !$scope.model.differentAsLinkingFilters;
              }
            },
            {
              id: 'count',
              type: 'numeric',
              label: 'How many do you want to achieve? (0 for unlimited)',
              required: true,
              hideExpression: function(_$viewValue, _$modelValue, $scope) {
                return !_.isUndefined($scope.model.filterMeasurements) ||
                  !_.isUndefined($scope.model.periodMeasurements);
              }
            }
          ]);
          break;
        default:
          console.error('type of form ' + typeOfForm + 'is invalid');
      }

      formList[objId] = form;
    }

    ctrl.add = function(type, list) {
      var map = {
        goal: Goal.setDefaults(),
        target: Target.setDefaults(),
        condition: Target.setDefaultsCondition(),
        filter: Target.setDefaultsFilter()
      };
      var obj = map[type];

      list.push(obj);

      // Hm, ugly but no idea how else to do it
      if (type === 'condition') {
        var conditionKey = 'condition_' + Utils.hashDoc(obj._id);
        ctrl.setupWatchForCondition(obj, conditionKey);
      } else if (type === 'target') {
        ctrl.targetsShown[obj._id] = true;
      }

      // create new instance of form and add it to the list of forms
      var formCreationMap = {
        goal: ['goalForm'],
        target: ['targetBasicForm'],
        condition: ['linkingFiltersForm', 'progressFiltersForm']
      };
      _.forEach(formCreationMap[type], function(formCreationName) {
        createForm(formCreationName, obj._id);
      });
    };

    function _remove(list, idToRemove) {
      var position;
      _.forEach(list, function(obj, i) {
        if (obj._id === idToRemove) {
          position = i;
        }
      });

      list.splice(position, 1);
    }

    ctrl.remove = function(type, list, idToRemove, hideAlert) {
      if (hideAlert) {
        _remove(list, idToRemove);
        return;
      }

      var title = 'Are you sure you want to remove this ' + type + '?';
      if (type === 'target') {
        var target = _.find(list, function(target) {
          return target._id === idToRemove;
        });
        if (!_.isEmpty(target.linkedEvents)) {
          title += ' You will lose all linked events.';
        }
      }

      Utils.swal({
        title: title,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          _remove(list, idToRemove);

          var formRemoveMap = {
            goal: [ctrl.goalForms],
            target: [ctrl.targetBasicForms],
            condition: [ctrl.linkingFiltersForms, ctrl.progressFiltersForms]
          };
          _.forEach(formRemoveMap[type], function(formsList) {
            delete formsList[idToRemove];
          });
        }
      });
    };

    function getFilterMeasurements(progressFilters) {
      return Blueprints.findNumericBlueprints()
        .then(function(numericBlueprints) {
          var numericBlInFilters = [];
          _.forEach(progressFilters, function(progressFilter) {
            if (progressFilter.dataType === 'blueprint') {
              // all numeric blueprints
              var blIds = _.map(numericBlueprints, function(bl) {
                return bl.doc._id;
              });

              // check if any in the progressFilter
              var newBlInFilters = _.filter(progressFilter.value, function(categoryId) {
                return _.indexOf(blIds, categoryId) > -1;
              });

              _.forEach(newBlInFilters, function(bl) {
                numericBlInFilters.push(bl);
              });
            }
          });

          if (numericBlInFilters.length === 0) {
            return [];
          }
          return [{
            count: 0,
            blueprints: numericBlInFilters
          }];
          // return _.map(numericBlInFilters, function(blId) {
          //   return { blueprintId: blId, count: 0 };
          // });
        });
    }

    function removeFilterMeasurements(model) {
      if (model.periodMeasurements) {
        _.forEach(model.periodMeasurements, function(measurement) {
          delete measurement.filterMeasurements;
        });
      } else {
        delete model.filterMeasurements;
      }
    }

    function applyNumericBlueprintsToFilter(root, filterMeasurements) {
      // the function getFilterMeasurements returns a unique element on a list
      var uniqueFilterMeasurement = angular.copy(filterMeasurements[0]);

      if (_.isUndefined(root.filterMeasurements)) {
        uniqueFilterMeasurement.count = root.count || 0;
        root.filterMeasurements = [uniqueFilterMeasurement];
        delete root.count;
      } else {
        // just apply blueprints without overriding count
        root.filterMeasurements[0].blueprints = uniqueFilterMeasurement.blueprints;
      }
    }

    function applyFilterMeasurements(condition, filters) {
      if (!_.isUndefined(filters) && !_.isEmpty(filters)) {
        // if they are some filters check that they are some numeric fields
        getFilterMeasurements(filters)
          .then(function(filterMeasurements) {
            if (filterMeasurements.length) {
              // if they are some numeric fields check if they are already a period
              // measurements in place
              if (condition.periodMeasurements) {
                // replace count by the filter Measurement on every period measurement
                _.forEach(condition.periodMeasurements, function(measurement) {
                  applyNumericBlueprintsToFilter(measurement, filterMeasurements);
                });
              } else {
                applyNumericBlueprintsToFilter(condition, filterMeasurements);
              }
            } else {
              removeFilterMeasurements(condition);
            }
          });
      } else {
        removeFilterMeasurements(condition);
      }
    }

    ctrl.setupWatchForCondition = function(condition, conditionKey) {
      $scope[conditionKey] = condition;

      $scope.$watchCollection(conditionKey + '.differentAsLinkingFilters', function(value) {
        if (!value) {
          delete condition.progressFilters;
          applyFilterMeasurements(condition, condition.filters);
        } else {
          applyFilterMeasurements(condition, condition.progressFilters);
        }
      });

      $scope.$watchCollection(conditionKey + '.filters', function(linkingFilters) {
        // each time the progress filter is changed
        if (!condition.differentAsLinkingFilters) {
          applyFilterMeasurements(condition, linkingFilters);
        }
      });

      $scope.$watchCollection(conditionKey + '.progressFilters', function(progressFilters) {
        // each time the progress filter is changed
        if (condition.differentAsLinkingFilters) {
          applyFilterMeasurements(condition, progressFilters);
        }
      });

      var key = conditionKey + '.periodMeasurements';
      $scope.$watchCollection(key, function(periodMeasurements) {
        // each time the period measurements is changed
        if (!_.isUndefined(periodMeasurements) && !_.isEmpty(periodMeasurements)) {
          // replace count by period measurements
          delete condition.count;

          // copy filterMeasurements if they exists
          var prom;
          if (!_.isUndefined(condition.filterMeasurements)) {
            prom = $q.when(angular.copy(condition.filterMeasurements));
            delete condition.filterMeasurements;
          } else {
            prom = getFilterMeasurements(condition.progressFilters);
          }

          prom
            .then(function(filterMeasurements) {
              _.forEach(condition.periodMeasurements, function(periodMeasurement) {
                if (
                  _.isUndefined(periodMeasurement.filterMeasurements) &&
                  !_.isEmpty(filterMeasurements)
                ) {
                  periodMeasurement.filterMeasurements = angular.copy(filterMeasurements);
                }
              });
            });
        } else {
          if (!_.isUndefined(condition.periodMeasurements)) {
            delete condition.periodMeasurements;
          }

          getFilterMeasurements(condition.progressFilters)
            .then(function(filterMeasurements) {
              if (
                _.isUndefined(condition.filterMeasurements) &&
                !_.isEmpty(filterMeasurements)
              ) {
                condition.filterMeasurements = angular.copy(filterMeasurements);
              }
            });
        }
      });
    };

    _.forEach($scope.model, function(goal, i) {
      createForm('goalForm', goal._id);

      _.forEach(goal.targets, function(target, j) {
        createForm('targetBasicForm', target._id);

        _.forEach(target.conditions, function(condition, k) {
          createForm('linkingFiltersForm', condition._id);
          createForm('progressFiltersForm', condition._id);

          if (!$scope.readonly) {
            if (_.isUndefined(condition.differentAsLinkingFilters)) {
              condition.differentAsLinkingFilters = !angular.equals(
                condition.filters,
                condition.progressFilters
              );
            }

            // watch if progressFilter changed.
            var conditionKey = 'condition_' + i + '_' + j + '_' + k;
            ctrl.setupWatchForCondition(condition, conditionKey);
          }
        });
      });
    });
  }

  GoalsDefineController.$inject = [
    '$q',
    '$scope',
    'BlueprintsService',
    'FormsService',
    'GoalFactory',
    'TargetFactory',
    'UtilsService'
  ];

  function GoalsDefineDirective() {
    return {
      scope: {
        model: '=',
        goalSetPeriods: '=',
        eventTypeId: '=',
        readonly: '=',
        editMode: '=',
        atLeastOne: '=',
        include: '=', //  might be obsolete
        exclude: '=' //  might be obsolete
      },
      restrict: 'AE',
      templateUrl: 'app/components/goals/directives/goals.define.html',
      replace: true,
      controller: GoalsDefineController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('goalsDefine', GoalsDefineDirective)
    .controller('GoalsDefineController', GoalsDefineController);
})();
