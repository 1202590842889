(function() {
  'use strict';

  function FileUploadDirective(FileUpload, Notify) {
    function link(scope, element) {
      var showPreview = typeof scope.showPreview !== 'undefined' ? scope.showPreview : false;
      var $element = angular.element(element);
      var input = $element.find('input[type="file"]');
      var preview = $element.find('.preview');
      // Hack to always set to zero as it is always just one - this is to be changed
      // when we implement a proper single file field
      scope.turnitinMaxAttempts = 0;
      if (scope.fileLimit === undefined) {
        scope.fileLimit = 0;
      }

      function updateSelected(file) {
        // TODO: How do we update the selected here for multiple files?
        if (file.vendor === 's3') {
          if (!_.isArray(scope.selected)) {
            scope.selected = [];
          }
          scope.selected.push(file);
        } else {
          scope.selected = {
            content: file.content,
            contentType: file.contentType,
            filetype: file.type,
            filesize: file.size
          };
        }
      }

      function thumb(file) {
        var img = document.createElement('img');
        preview.append(img);
        img.src = file.content;
      }

      function prepForUpload(files, onFileLoad) {
        return files.reduce(function(uploads, file) {
          var transformedFile = {
            name: file.name,
            type: file.type,
            contentType: file.type.split('/')[1],
            size: file.size
          };

          if (scope.direct === 'true') {
            FileUpload.uploadToS3(file, { oid: scope.docId })
              .then(function(data) {
                data.turnitin = { submissions: [] };
                scope.files = _.without(scope.files, transformedFile);
                updateSelected(data);
              })
              .catch(function(err) {
                console.log(err);
                scope.files = _.without(scope.files, transformedFile);
                Notify.error(err.message || 'File upload failed. Please retry');
              })
              .finally(function() {
                input[0].value = '';
              });
          } else {
            var reader = new FileReader();
            var readerFn = file.type.startsWith('image/') ? 'readAsDataURL' : 'readAsText';
            reader.onload = function(e) {
              transformedFile.content = e.target.result;
              if (typeof onFileLoad === 'function') {
                onFileLoad(transformedFile);
              }

              updateSelected(transformedFile);
            };
            reader[readerFn](file);
          }

          return [].concat(uploads, transformedFile);
        }, []);
      }

      scope.isValid = function(file) {
        var errors = [];

        // Check file size
        if (file.size > parseInt(scope.maxFilesize)) {
          errors.push('The file is too large!');
        }

        // Check the file type
        var acceptedFileTypes = scope.accept.split('*')[0];
        if (file.type !== acceptedFileTypes && !file.type.startsWith(acceptedFileTypes)) {
          errors.push('The file has the wrong format!');
        }

        file.validationErrors = errors;

        return errors.length === 0;
      };

      // Set the multiple attribute if needed
      if (scope.multiple) {
        input.attr('multiple', 'multiple');
      }

      // Watch for file changes and update
      input.on('change', function() {
        var selectedFiles = Array.from(input.get(0).files);
        var onFileLoad;

        if (showPreview) {
          onFileLoad = function(file) {
            if (file.type.startsWith('image/')) {
              thumb(file);
            }
          };
        }

        scope.files = prepForUpload(selectedFiles, onFileLoad);
      });

      scope.onUpdate = function(result, model) {
        model.turnitin = result;
      };

      scope.removeFile = function(file) {
        FileUpload.deleteFile(file, { oid: scope.docId })
        .then(function() {
          var idx = scope.selected.indexOf(file);
          scope.selected.splice(idx, 1);
          // scope.selected = _.without(scope.selected, file);
        })
        .catch(function(err) {
          console.log(err);
          Notify.error('Could not remove file. Please try again.');
        });
      };

      scope.$watch('selected', function(value) {
        if (!_.isUndefined(value) && scope.direct !== 'true') {
          // If the user removed the file that has been uploaded
          var selectedRemoved = typeof value.content !== 'undefined' && value.content === null;
          // If the user saved the form and therefore uploaded the file
          var selectedUploaded = typeof value.content === 'undefined';
          // We don't want to still show the files if they've been uploaded
          if (selectedRemoved || selectedUploaded) {
            scope.files = [];
          }
        }
      });

      scope.uploadAllowed = function() {
        if (!scope.fileLimit) {
          return true;
        }

        if (!scope.selected) {
          return true;
        }

        return scope.selected.length < scope.fileLimit;
      };
    }

    return {
      require: ['^form'],
      restrict: 'E',
      replace: true,
      scope: {
        selected: '=',
        docId: '=',
        label: '@',
        uploadId: '@',
        accept: '@',
        maxFilesize: '@?',
        multiple: '=?',
        required: '=?',
        showPreview: '=?',
        direct: '@',
        allowTurnitin: '=',
        turnitinMaxAttempts: '=',
        turnitinPlagiarismTitle: '=',
        fileLimit: '=',
        title: '='
      },
      templateUrl: 'app/blocks/widgets/forms/fileupload.html',
      link: link
    };
  }

  FileUploadDirective.$inject = [
    'FileUploadService',
    'NotifyService'
  ];

  angular.module('widgets.forms')
    .directive('kzFileUpload', FileUploadDirective);
})();
