(function() {
  'use strict';

  function DocumentsController(
    $q,
    $scope,
    $rootScope,
    $stateParams,
    $uibModal,
    Profile,
    Documents,
    DocumentFactory,
    DocumentSearch,
    Events,
    Event,
    EventTypes,
    Auth,
    List,
    Utils,
    Notify,
    Network,
    AUTHORIZED_FILES_EXTENSIONS,
    ICON_FILES_EXTENSIONS_MAP
  ) {
    var ctrl = this;

    // Setup bulk move
    ctrl.prefs = Profile.getPreferences();
    ctrl.model = _.assignIn({
      selected: []
    }, ctrl.prefs.documentBulk || {});
    ctrl.prefs.documentBulk = ctrl.model;

    $scope.$watch(function() {
      return ctrl.model;
    }, function() {
      $rootScope.$broadcast('KZPreferencesChange');
    }, true);

    // folderId passed in stateParams
    var stateFolderId = $stateParams.folderId;

    // real folderId set eg. by clicking at breadcrumbs in doc browser
    ctrl.folderId = stateFolderId;
    ctrl.searchModel = {
      filteredBy: {}
    };

    /* Handle Filters */
    var extensions = [];
    extensions.push({ _id: 'folder', name: 'folder' });
    _.forEach(AUTHORIZED_FILES_EXTENSIONS, function(ext) {
      extensions.push({ _id: ext, name: ext });
    });


    // List
    ctrl.getFileIcon = function(documentType) {
      return ICON_FILES_EXTENSIONS_MAP[documentType];
    };

    function setSpaceRate() {
      Documents.getRateSpace(Auth.currentUser())
        .then(function(data) {
          ctrl.totalSpaceTaken = (data.library_space / 1048576).toFixed(2);

          // should be generic with settings.
          ctrl.totalSpaceAllowed = Math.round(data.total_space_allowed / 1048576);
          ctrl.rateSpaceTaken = ((ctrl.totalSpaceTaken / ctrl.totalSpaceAllowed) * 100).toFixed(1);

          var style = 'success';
          if (ctrl.rateSpaceTaken >= 75) {
            style = 'warning';
          }

          ctrl.style = style;
        }).catch(function(e) {
          console.log(e);
        });
    }

    function loadCurrentPath() {
      ctrl.currentPath = [];
      if (ctrl.folderId !== undefined) {
        Documents.find(ctrl.folderId)
          .then(function(document) {
            ctrl.currentPath = document.path || [];
            Utils.setPageTitle('Documents: ' + document.name);
          });
      } else {
        Utils.setPageTitle('Documents');
      }
    }

    function loadDocLinkedEvents(doc) {
      return Documents.linkedEvents(doc.id)
        .then(function(events) {
          doc._loaded = true;
          doc.linkedEvents = events;
        });
    }

    function loadDocTags(doc) {
      // display uploaded_document event tags
      var events = doc.linkedEvents;
      events.forEach(function(eventId) {
        Events.find(eventId)
          .then(function(event) {
            EventTypes.isUploadedEventType(event.eventType)
              .then(function(isUploadedEventType) {
                if (isUploadedEventType) {
                  var e = new Event();
                  e.init(event);
                  doc.event = e;
                }
              });
          });
      });
    }

    function makeDocs(item) {
      return new DocumentFactory(item.doc);
    }

    function loadList(folderId) {
      ctrl.shownDescriptions = {};
      folderId = folderId || $stateParams.folderId;
      ctrl.folderId = folderId;
      return $q.all([DocumentSearch.search, Documents.findByFolderId(folderId)])
        .then(function(result) {
          var search = result[0];
          var items = _.map(result[1], makeDocs);
          ctrl.list = new List({
            search: search,
            model: ctrl.searchModel,
            idField: 'doc._id'
          });
          ctrl.list.loadList = loadList;
          return ctrl.list.doLoadItems(items);
        })
        .then(function() {
          loadCurrentPath();
        });
    }
    setSpaceRate();

    ctrl.loadList = loadList;

    // initialize
    loadList();

    function _move(item, folderId) {
      return Documents.find(item)
        .then(function(doc) {
          var docum = new DocumentFactory(doc);
          docum.init();
          return docum.moveTo(folderId);
        });
    }

    function openPopup() {
      $uibModal.open({
        animation: true,
        size: 'lg',
        templateUrl: 'app/components/documents/document.browser.popup.html',
        controller: 'DocumentBrowserPopup',
        controllerAs: 'browserCtrl',
        resolve: {
          popupOptions: {
            title: 'Select a folder to move items to',
            actionButtons: [
              {
                label: 'Move into this folder',
                icon: 'icon-move',
                onClick: function(popupCtrl) {
                  var folderId = popupCtrl.folderId;

                  var prom = {};
                  if (_.isUndefined(folderId)) {
                    // this means the home folder (root) has been selected
                    prom = $q.when();
                  } else {
                    prom = Documents.find(folderId)
                      .then(function(folder) {
                        var nonMoveableItems = _.filter(ctrl.model.selected, function(documentId) {
                          return _.indexOf(folder.path, documentId) > -1;
                        });
                        if (nonMoveableItems.length) {
                          return $q.reject(
                            { message: 'You cannot move a folder into itself or its sub folder' }
                          );
                        }
                      });
                  }

                  return prom
                    .then(function() {
                      return $q.all(_.map(ctrl.model.selected, function(item) {
                        return _move(item, folderId);
                      }));
                    })
                    .then(function() {
                      Notify.success('The documents have been moved');
                      _.remove(ctrl.model.selected, function() {
                        return true;
                      });

                      loadList();
                      popupCtrl.close();
                    })
                    .catch(function(error) {
                      // $log.warn(error && error.message);
                      if (
                        error.message === 'You cannot move a folder into itself or its sub folder'
                      ) {
                        popupCtrl.error = 'You cannot move a folder into itself or its sub folder';
                      } else {
                        Notify.error('An error appeared while moving');
                      }
                    });
                },
                klass: 'btn-success'
              }
            ]
          }
        }
      });
    }

    function openRenameDialog(item) {
      return $uibModal.open({
        animation: true,
        size: 'lg',
        templateUrl: 'app/components/documents/document.rename.html',
        controller: 'DocumentRenameController',
        controllerAs: 'renameCtrl',
        resolve: {
          resolvedDoc: function() {
            return item;
          },
          options: {
            title: 'Create a folder'
          }
        }
      });
    }

    function loadButtons() {
      ctrl.actions = [
        {
          label: 'Move to ...',
          icon: 'icon-edit',
          onClick: openPopup,
          klass: 'btn-success',
          showCondition: function() {
            return ctrl.model.selected.length > 0;
          }
        },
        {
          label: 'New folder',
          icon: 'icon-edit',
          onClick: function() {
            ctrl.addFolder();
          },
          klass: 'btn-success'
        },
        {
          label: 'Upload document',
          icon: 'icon-edit',
          onClick: function() {
            if (Network.isOffline()) {
              Notify.error('This is not available when working offline');
              return;
            }

            ctrl.updateDropzoneVisibility();
          },
          klass: 'btn-success'
        }
      ];
    }

    $scope.selected = ctrl.prefs.documentBulk.selected;
    $scope.$watchCollection('selected', function() {
      loadButtons();
      $scope.$broadcast('kzReloadActions');
    });

    ctrl.options = {
      actionButtons: function(item) {
        return [
          {
            label: ctrl.model.selected.indexOf(item.doc._id) > -1 ? 'Unselect' : 'Select',
            icon: ctrl.model.selected.indexOf(item.doc._id) > -1 ? 'icon-minus' : 'icon-plus',
            klass: ctrl.model.selected.indexOf(item.doc._id) > -1 ? 'text-danger' : 'text-success',
            onClick: function() {
              var index = ctrl.model.selected.indexOf(item.doc._id);
              if (index > -1) {
                ctrl.model.selected.splice(index, 1);
                this.label = 'Select';
                this.icon = 'icon-plus';
                this.klass = 'text-success';
              } else {
                ctrl.model.selected.push(item.doc._id);
                this.label = 'Unselect';
                this.icon = 'icon-minus';
                this.klass = 'text-danger';
              }
            }
          }
        ];
      }
    };

    ctrl.paginate = function() {
      ctrl.list.doPaginate();
    };

    // Actions
    ctrl.showEditIcon = function(id) {
      ctrl.inputTextShown = ctrl.inputTextShown ? ctrl.inputTextShown : {};
      if (!ctrl.inputTextShown[id]) {
        ctrl.editIconShown = ctrl.editIconShown ? ctrl.editIconShown : {};
        ctrl.editIconShown[id] = _.has(ctrl.editIconShown, id) ? !ctrl.editIconShown[id] : true;
      }
    };

    ctrl.showTextInput = function(id) {
      ctrl.inputTextShown = ctrl.inputTextShown ? ctrl.inputTextShown : {};
      if (!ctrl.inputTextShown[id]) {
        ctrl.inputTextShown[id] = true;
        ctrl.editIconShown = false;
      }
    };

    ctrl.saveTitle = function(id, newTitle, isShared) {
      var dbType = (isShared) ? 'shared' : 'private';
      Documents.find(id, dbType)
        .then(function(doc) {
          doc.name = newTitle;
          Documents.save(doc, dbType)
            .then(function(data) {
              if (data.ok) {
                console.log('doc saved');
                ctrl.inputTextShown[id] = false;
              }
            });
        });
    };

    ctrl.showDescription = function(doc) {
      ctrl.shownDescriptions = ctrl.shownDescriptions ? ctrl.shownDescriptions : {};

      ctrl.shownDescriptions[doc.id] = _.has(ctrl.shownDescriptions, doc.id)
        ? !ctrl.shownDescriptions[doc.id]
        : true;

      if (ctrl.shownDescriptions[doc.id]) {
        // handle preloaded?
        loadDocLinkedEvents(doc)
          .then(function() {
            loadDocTags(doc);
          });
      }
    };

    ctrl.shownCheckboxes = false;
    ctrl.showCheckboxes = function() {
      ctrl.shownCheckboxes = !ctrl.shownCheckboxes;
    };

    loadCurrentPath();

    ctrl.addFolder = function() {
      var now = Utils.now();
      var id = Utils.guid();
      var path = ctrl.currentPath.slice();
      path.push(id);

      var doc = {
        _id: id,
        date: {
          added: now,
          modified: now
        },
        type: 'document',
        documentType: 'folder',
        organisation: Auth.currentOrganisation(),
        user: Auth.currentUser(),
        name: 'Untitled folder',
        isShared: true,
        visibility: 'public',
        path: path
      };

      var folder = new DocumentFactory(doc);
      var popup = openRenameDialog(folder);
      popup.result.finally(function() {
        ctrl.loadList();
      });
    };

    // Upload documents
    ctrl.dropzoneShown = false;
    ctrl.updateDropzoneVisibility = function() {
      ctrl.dropzoneShown = !ctrl.dropzoneShown;
    };
  }

  DocumentsController.$inject = [
    '$q',
    '$scope',
    '$rootScope',
    '$stateParams',
    '$uibModal',
    'ProfileService',
    'DocumentsService',
    'DocumentFactory',
    'DocumentSearch',
    'EventsService',
    'EventFactory',
    'EventTypesService',
    'AuthService',
    'ListFactory',
    'UtilsService',
    'NotifyService',
    'NetworkService',
    'AUTHORIZED_FILES_EXTENSIONS',
    'ICON_FILES_EXTENSIONS_MAP'
  ];

  angular.module('component.documents')
    .controller('DocumentsController', DocumentsController);
})();
