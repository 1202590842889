import 'bootstrap';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';

import 'angular';

import './core';
import './blocks';
import './layout';
import './components';

export { default } from './main';
