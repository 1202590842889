(function() {
  'use strict';

  function DocumentFactory(
    $q, $log, DocumentsStub, Documents,
    Event, EventTypes, Utils, Auth
  ) {
    var Document = function(doc, options) {
      this.doc = doc || {};
      this.options = options || {};
      this.loaded = false;
    };

    Document.prototype.load = function(docId) {
      var _this = this;
      return DocumentsStub.find(docId)
        .then(function(data) {
          return _this.init(data);
        });
    };

    Document.prototype.init = function(doc) {
      this.doc = doc || this.doc;
      this.loaded = true;
      return this;
    };

    Document.prototype.canView = function() {
      if (this.doc.user === Auth.currentUser()) {
        return true;
      }

      // In case it was a stub
      return !!this.doc.canView;
    };

    Document.prototype.save = function() {
      var _this = this;
      return Documents.save(this.doc)
        .then(function(doc) {
          _this.doc = doc;
        });
    };

    Document.prototype.remove = function() {
      var _this = this;
      var promise;
      if (this.isFolder()) {
        promise = this.isEmptyFolder()
          .then(function(isEmpty) {
            if (!isEmpty) {
              Utils.swal({
                title: 'The folder can\'t be removed.',
                text: 'Folder is not empty',
                type: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK'
              });
              return $q.reject({ status: 510, message: 'Folder is not empty' });
            }
          });
      } else {
        var title = 'This document contains links to events on your timeline. ' +
          'Deleting this document will result in it no longer being visible ' +
          'from these events. Do you wish to continue?';

        promise = Utils.confirm({
          title: title,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'OK'
        });
      }

      return promise
        .then(function() {
          return Documents.removeById(_this.doc._id);
        })
        .catch(function(error) {
          // Ignore validation errors as we've shown the alert
          if (error && error.status === 510) {
            return $q.reject(error);
          }

          console.log('error:', error);
          Utils.showError(error);
          return $q.reject(error);
        });
    };

    Document.prototype.isFolder = function() {
      return this.doc.documentType === 'folder';
    };

    Document.prototype.isEmptyFolder = function() {
      if (!this.isFolder()) {
        return $q.when(false);
      }

      return Documents.findByPath(this.doc._id)
        .then(function(items) {
          return items.length === 0;
        });
    };

    Document.prototype.loadExtra = function() {
      var _this = this;
      if (this.options.source === 'ES') {
        return Documents.userDocumentLinks(this.doc)
          .then(function(data) {
            _this.event = data.event;
            _this.linkedEvents = _.map(data.events, function(doc) {
              var event = new Event(doc);
              event.init();
              return event;
            });
            _this.tags = data.tags;
            _this.extraLoaded = true;
          });
      }

      return this.loadDocLinkedEvents()
        .then(function() {
          return _this.loadUploadedEvent();
        })
        .catch(function(error) {
          $log.warn('Could not load extra for', _this.doc._id, error);
        });
    };

    Document.prototype.loadDocLinkedEvents = function() {
      var _this = this;
      return Documents.linkedEvents(this.doc._id)
        .then(function(events) {
          events = _.uniq(events);
          var promises = _.map(events, function(eventId) {
            var event = new Event();
            return event.load(eventId)
              .catch(function(error) {
                $log.warn('Could not load event', eventId, error);
              });
          });
          return $q.all(promises);
        })
        .then(function(result) {
          _this.linkedEvents = result;
        });
    };

    Document.prototype.loadUploadedEvent = function() {
      var _this = this;
      // display uploaded_document event tags
      return EventTypes.findSystemDocument()
        .then(function(ids) {
          _this.event = _.find(_this.linkedEvents, function(event) {
            return ids.indexOf(event.doc.eventType) !== -1;
          });
          _this.tags = [];
          if (_this.event !== undefined) {
            _this.tags = _this.event.categories;
          }
        });
    };

    Document.prototype.isShared = function() {
      return this.doc.visibility !== 'private';
    };

    Document.prototype.makePrivate = function() {
      if (this.doc.visibility === 'private') {
        return $q.when();
      }

      this.doc.visibility = 'private';
      this.doc.isShared = false;
      var actionName = this.isFolder() ? 'make_hidden' : 'make_private';
      Utils.recordLog(this.doc, actionName, Auth.currentUser());
      return Documents.save(this.doc);
    };

    Document.prototype.makePublic = function() {
      if (this.doc.visibility === 'public') {
        return $q.when();
      }

      this.doc.visibility = 'public';
      this.doc.isShared = true;
      var actionName = this.isFolder() ? 'make_shown' : 'make_public';
      Utils.recordLog(this.doc, actionName, Auth.currentUser());
      return Documents.save(this.doc);
    };

    Document.prototype.moveTo = function(newFolderId) {
      var _this = this;
      // Moving to root
      var promise;
      if (newFolderId === undefined) {
        promise = $q.when([]);
      } else {
        promise = Documents.find(newFolderId)
          .then(function(newFolder) {
            if (newFolder.documentType !== 'folder') {
              return $q.reject({ status: 500, message: 'Destination is not a folder' });
            }
            return newFolder.path;
          });
      }

      return $q.all([promise, Documents.findSubTree(_this.doc._id)])
        .then(function(result) {
          var newPath = result[0];
          var items = result[1];

          var promises = [];
          _.forEach(items, function(item) {
            var pos = item.doc.path.indexOf(_this.doc._id);
            var slicedPath = item.doc.path.slice(pos);
            item.doc.path = newPath.concat(slicedPath);
            promises.push(Documents.save(item.doc));
          });

          return $q.all(promises);
        });
    };

    // any better idea?
    Document.prototype.getBorderClass = function() {
      if (_.isUndefined(this.doc)) {
        return '';
      }

      if (this.doc.visibility === 'public') {
        return 'progress-border-complete';
      }

      return 'progress-border-danger';
    };


    return Document;
  }

  DocumentFactory.$inject = [
    '$q',
    '$log',
    'DocumentsStubService',
    'DocumentsService',
    'EventFactory',
    'EventTypesService',
    'UtilsService',
    'AuthService'
  ];

  angular.module('component.documents')
    .factory('DocumentFactory', DocumentFactory);
})();
