(function() {
  'use strict';

  function EventSectionFactory(
    $q,
    $log,
    $state,
    $rootScope,
    Event,
    Events,
    EventSections,
    EventTypes,
    EventExtras,
    Form,
    UsersStub,
    Blueprints,
    BlueprintUtils,
    Auth,
    Utils,
    Security,
    Queue,
    $uibModal,
    Notify,
    Database,
    AutoSave,
    AsyncTasks,
    Turnitin,
    EVENT_STATES
  ) {
    var EventSection = function(doc, options) {
      this.doc = doc || {};
      this.loadMeta();
      this.options = options || {};
      this.isLocal = this.options.isLocal;
      this.loaded = false;
      if (this.options.extras) {
        this._extra = this.options.extras;
      }
      if (this.options.eventType) {
        this.eventType = this.options.eventType;
        // this.eventTypeObj = new EventTypeLight({ doc: doc });
        this.eventTypeVersion = this.eventType.version;
      }
    };

    EventSection.prototype.reset = function(doc) {
      if (!_.isUndefined(doc)) {
        this.doc = doc;
      }

      this.clearData();
      this.loaded = false;
    };

    EventSection.prototype.init = function(doc, options) {
      options = options || {};
      var _this = this;
      this.doc = doc || this.doc;
      return this.loadEventType()
        .then(function() {
          _this.actionButtons = _this.loadActionButtons();
          _this.secondaryActionButtons = _this.loadSecondaryButtons();
          _this.loadSections();
          _this.loadContainsGoal();
          _this.updateViewLink();
          _this.loadMeta();
          return _this.collectBlueprints(options.reload)
            .then(function() {
              return _this.collectDocuments();
            });
        })
        .then(function() {
          _this.loaded = true;
          return _this;
        })
        .catch(function(err) {
          $log.warn('Could not init event section', err);
          return $q.reject(err);
        });
    };

    EventSection.prototype.reload = function(doc) {
      var _this = this;
      $log.warn('Reloading event section');
      if (doc !== undefined && doc._id !== this.doc._id) {
        throw { status: 500, message: 'Document ID does not match the current object' };
      }
      this.reset();

      var promise = $q.when(doc);
      if (doc === undefined) {
        promise = this.fetchDoc();
      }

      return promise.then(function(newDoc) {
        return _this.init(newDoc);
      });
    };

    EventSection.prototype.fetchDoc = function() {
      return EventSections.find(this.doc._id, { cached: false });
    };

    EventSection.prototype.loadOriginalEvent = function() {
      var doc = this.doc.originalEvent || angular.copy(this.doc.meta);
      this.originalEvent = new Event(doc, { noExtra: true });
      return this.originalEvent.init();
    };

    EventSection.prototype.loadAdditionalActions = function(originalEvent) {
      if (_.isUndefined(this.doc.additionalActions)) {
        return originalEvent;
      }

      var toBeEdited = {},
          toBeDeleted = [];
      _.forEach(this.doc.additionalActions, function(action) {
        if (_.isUndefined(toBeEdited[action.section_id])) {
          toBeEdited[action.section_id] = {};
        }

        if (action.action_name === 'remove') {
          toBeDeleted.push(action.event_section_id);
        } else {
          toBeEdited[action.section_id][action.event_section_id] = action.fields;
        }
      });

      _.forEach(originalEvent.doc.sections, function(sectionValue, sectionId) {
        if (_.has(toBeEdited, sectionId)) {
          _.forEach(sectionValue.items, function(item, idx) {
            if (_.has(toBeEdited[sectionId], item.eventSectionId)) {
              sectionValue.items[idx].fields = toBeEdited[sectionId][item.eventSectionId];
            }
          });
        }

        // remove responses if needed
        sectionValue.items = _.filter(sectionValue.items, function(item) {
          return _.indexOf(toBeDeleted, item.eventSectionId) === -1;
        });
      });

      return originalEvent;
    };

    EventSection.prototype.addAdditionalAction = function(additionalAction) {
      if (_.isUndefined(this.doc.additionalActions)) {
        this.doc.additionalActions = [];
      }

      // check first that the action is not for a section that is already waiting to be changed.
      var exists = _.find(this.doc.additionalActions, function(a) {
        return a.event_section_id === additionalAction.event_section_id;
      });

      if (_.isUndefined(exists)) {
        this.doc.additionalActions.push(additionalAction);
      } else {
        var _this = this;
        _.forEach(this.doc.additionalActions, function(action, idx) {
          if (action.event_section_id === additionalAction.event_section_id) {
            _this.doc.additionalActions[idx] = additionalAction;
            return;
          }
        });
      }

      Notify.success('Your action will be performed once you submit your section.');
    };

    EventSection.prototype.getViewLink = function(keepUser) {
      var sref;
      var srefOpts;

      if (this.isMine()) {
        sref = 'epf.events.section-view';
        srefOpts = { id: this.doc._id, user: keepUser ? this.doc.eventOwner : undefined };
      } else {
        sref = 'epf.users.sections-view';
        srefOpts = { user: this.doc.user, id: this.doc._id };
      }

      return $state.href(sref, srefOpts);
    };

    EventSection.prototype.updateViewLink = function() {
      this.href = this.getViewLink();
    };

    EventSection.prototype.isNew = function() {
      return _.isUndefined(this.doc._rev);
    };

    EventSection.prototype.isQuickEvent = function() {
      var currentIndex = this.getCurrentIndex();
      if (!_.isUndefined(currentIndex) && currentIndex !== -1) {
        return false;
      }

      var meta = this.doc.meta || {};
      return meta.quickDraft;
    };

    /**
     * Return true if the section is owned by event owner
     * @return {Boolean} [description]
     */
    EventSection.prototype.isEventOwners = function() {
      return this.doc.eventOwner === this.doc.user;
    };

    /**
     * Return true if the section is trully mine (not the original event)
     * @return {Boolean} [description]
     */
    EventSection.prototype.isMine = function() {
      return Auth.currentUser() === this.doc.user;
    };

    /**
     * Return true if the section belongs to a mine event
     * @return {Boolean} [description]
     */
    EventSection.prototype.isMineEvent = function() {
      return Auth.currentUser() === this.doc.eventOwner;
    };

    EventSection.prototype.isPrivate = function() {
      return this.doc.visibility === 'private';
    };


    EventSection.prototype.setInitial = function(defaults) {
      var _this = this;
      defaults = defaults || {};
      if (!this.isNew()) {
        $log.warn('Trying to set initial for an existing event section');
        return $q.when();
      }

      _.assignIn(this.doc, EventSections.getInitial(), defaults);

      _.forEach(['eventType', 'linkedTargets'], function(attr) {
        if (_.isUndefined(defaults[attr])) {
          return;
        }

        _this.doc.meta[attr] = defaults[attr];
      });

      var promise = $q.when();
      if (this.doc.eventType) {
        promise = this.changeEventType();
      }

      return promise
        .then(function() {
          _this.loaded = true;
        });
    };

    EventSection.prototype.setDefaults = function() {
      // This is to be removed as it's not used for anything
      return $q.when();
    };

    EventSection.prototype.clearData = function() {
      this.eventType = {};
      this.currentDefSection = undefined;
      this.nextDefSection = undefined;
      this.meta = undefined;
      this.actionButtons = undefined;
      this.secondaryActionButtons = undefined;
      this.currentDefSection = undefined;
      this.nextDefSection = undefined;
      this.href = undefined;
    };

    EventSection.prototype.changeEventType = function() {
      var _this = this;
      this.doc.data = { fields: {} };
      this.doc.nextSection = {};
      this.doc.section = undefined;
      this.clearData();
      this.doc.eventType = this.doc.meta.eventType;

      return this.loadEventType()
        .then(function(eventType) {
          // Set the first section as we have to be creating a new one
          _this.doc.section = eventType.sections[0]._id;
          _this.doc.meta.visibility = eventType.defaultVisibility;
          _this.loadSections();
          _this.loadContainsGoal();
        });
    };

    EventSection.prototype.loadEventType = function() {
      var _this = this;
      if (this.eventType && this.eventType._id) {
        return $q.when();
      }

      if (this.doc.eventType) {
        return EventTypes.find(
          _this.doc.eventType,
          { cache: this.options.noCache ? 'revalidate' : 'cached' }
        )
          // .catch(function(error) {
          //   // Retry without cache
          //   if (error && error.status === 404) {
          //     return EventTypes.getGroupByItemId(_this.doc.eventType, { cached: false });
          //   }
          //   return $q.reject(error);
          // })
          .then(function(doc) {
            _this.eventType = doc;
            // _this.eventTypeObj = new EventTypeLight({ doc: doc });
            _this.eventTypeVersion = _this.eventType.version;
            return _this.eventType;
          })
          .catch(function(error) {
            $log.warn('Could not load event type for: ' + _this.doc._id);
            return $q.reject(error);
          });
      }

      return $q.when({});
    };

    EventSection.prototype.loadSections = function() {
      if (_.isEmpty(this.doc.eventType)) {
        return;
      }

      this.currentDefSection = this.getDefSection();
      var nextSectionId = this.getNextSection();
      if (nextSectionId) {
        this.nextDefSection = this.getDefSection(nextSectionId);
      }
    };

    EventSection.prototype.loadContainsGoal = function() {
      this.containsGoal = false;
      if (this.currentDefSection) {
        var found = _.find(this.currentDefSection.fields, function(field) {
          return field.type === 'goal';
        });

        if (found) {
          this.containsGoal = true;
          this.containsAutoCloseGoal = found.canAutoClose === true;
        }
      }

      return $q.when(this.containsGoal);
    };

    EventSection.prototype.loadMeta = function() {
      this.meta = this.doc.meta || this.doc.originalEvent;
    };

    EventSection.prototype.getCurrentIndex = function() {
      return this.getIndex(this.doc.section);
    };

    EventSection.prototype.getIndex = function(section) {
      if (this.doc.quickDraft || (this.doc.meta && this.doc.meta.quickDraft)) {
        return -1;
      }

      if (!this.eventType) {
        $log.warn('Trying to get current index of non-loaded section: ' + this.doc._id);
        return -1;
      }
      var sections = this.eventType.sections;
      return _.findIndex(sections, { _id: section });
    };

    EventSection.prototype.getNumberOfSections = function() {
      if (this.doc.quickDraft || (this.doc.meta && this.doc.meta.quickDraft)) {
        return -1;
      }

      if (!this.eventType) {
        $log.warn('Trying to get current index of non-loaded section: ' + this.doc._id);
        return -1;
      }
      return (this.eventType.sections || []).length;
    };

    EventSection.prototype.getNextSection = function() {
      if (!this.eventType) {
        return;
      }

      var _this = this;
      var sections = _.map(this.eventType.sections, '_id');
      var current = this.getCurrentIndex();
      if (sections.length <= current + 1) {
        return;
      }

      var allData = this.extractEventData();

      return _.find(sections, function(section, idx) {
        if (idx <= current) {
          return false;
        }

        return _this.isValidSection(section, allData);
      });
    };

    EventSection.prototype.extractEventData = function() {
      var _this = this;
      var originalEvent = this.doc.originalEvent || {};
      var origSections = originalEvent.sections || {};

      if (this.doc.skipConditionData) {
        origSections = this.doc.skipConditionData || {};
      }

      var sections = _.map(this.eventType.sections, '_id');
      var currIdx = this.getCurrentIndex();
      var data = {};
      _.forEach(sections, function(sectionId, idx) {
        if (idx < currIdx) {
          var sec = origSections[sectionId] || {};
          data[sectionId] = sec;
        } else if (idx === currIdx) {
          data[sectionId] = { items: [_this.doc.data || {}] };
        } else if (idx === currIdx + 1) {
          data[sectionId] = { items: [_this.doc.nextSection || {}] };
        }
      });
      return data;
    };

    EventSection.prototype.findNextSection = function(sectionId) {
      if (!this.eventType) {
        return;
      }

      var _this = this;
      var sections = _.map(this.eventType.sections, '_id');
      var current = this.getIndex(sectionId);
      if (sections.length <= current + 1) {
        return;
      }

      var allData = this.extractEventData();

      return _.find(sections, function(section, idx) {
        if (idx <= current) {
          return false;
        }

        return _this.isValidSection(section, allData);
      });
    };

    var _evalSkipCondition = function(data, exp) {
      if (exp.type === 'sectionField') {
        var sec = data[exp.section];
        if (sec === undefined) {
          return false;
        }
        var result = _.some(sec.items || [], function(item) {
          if (item === undefined) {
            return false;
          }
          var fields = item.fields || {};
          var val = fields[exp.field];
          if (Utils.isBlank(val)) {
            val = [];
          } else if (!_.isArray(val)) {
            val = [val];
          }

          var expVal = exp.values;
          if (Utils.isBlank(expVal)) {
            expVal = [];
          } else if (!_.isArray(expVal)) {
            expVal = [expVal];
          }

          return _.intersection(val, expVal).length > 0;
        });
        return result;
      }

      return false;
    };


    EventSection.prototype.isValidSection = function(sectionId, data) {
      var def = this.getDefSection(sectionId);
      var skipCondition = def.skipCondition;

      if (_.isEmpty(skipCondition)) {
        return true;
      }

      return !Utils.evalComplex(skipCondition, _evalSkipCondition.bind(this, data));
    };

    EventSection.prototype.getDefSection = function(sid) {
      if (sid === undefined) {
        sid = this.doc.section;
      }

      return _.find(this.eventType.sections, { _id: sid });
    };

    EventSection.prototype.save = function(doc) {
      doc = doc || this.doc;
      return EventSections.save(doc);
    };

    EventSection.prototype.saveApi = function(options) {
      var data = {
        data: this.doc.data.fields,
        _rev: this.doc._rev
      };

      return this.doAction(
        'section_edit',
        { section_id: this.doc._id, data: data },
        { skip: true },
        { skip: true },
        options
      );
    };

    EventSection.prototype.publish = function(options) {
      $rootScope.$broadcast('ResetChangesTimeout');
      return this.doAction(
        'section_publish',
        { section_id: this.doc._id, link_existing_events: true },
        { skip: true },
        { skip: true },
        options
      );
    };

    EventSection.prototype.reject = function(options) {
      var _this = this;
      options = options || {};
      return this.openRejectionFormPopup(options)
      .then(function() {
        $rootScope.$broadcast('ItemReloadRequested', {
          type: 'event',
          id: _this.doc._id,
          data: {
            event: _this.doc.event
          }
        });
        $state.go('epf.events.index');
      });
    };

    EventSection.prototype.remove = function() {
      $rootScope.$broadcast('ResetChangesTimeout');
      var _this = this;
      return Utils.confirm({
        title: 'Are you sure you want to remove this event?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      })
        .then(function() {
          return AutoSave.safeRemoveAll(_this.doc._id);
        })
        .then(function() {
          return EventSections.remove(_this.doc._id);
        })
        .then(function() {
          if (_this.isMineEvent()) {
            // Remove retracted if exists
            return Events.find(_this.doc.event)
              .then(function(eventDoc) {
                var ev = new Event(eventDoc);
                ev.init()
                  .then(function() {
                    if (
                      ev.doc.state === EVENT_STATES.RETRACTED.id &&
                      ev.completedSections.length === 0
                    ) {
                      return Events.remove(_this.doc.event);
                    }
                  });
              })
              .catch(function(error) {
                console.log('no retracted event found: ' + error);
              });
          }
          return $q.when();
        })
        .then(function() {
          $rootScope.$broadcast('ItemReloadRequested', {
            type: 'event',
            id: _this.doc._id,
            data: {
              event: _this.doc.event
            }
          });
          $state.go('epf.events.index');
        })
        .then(function() {
          Notify.success('The section has been removed');
        })
        .catch(function(error) {
          if (error && error.status === 510) {
            // Ignore if user cancelled
            return $q.reject(error);
          }

          console.log(error);
          Notify.error('Could not remove section');
        });
    };

    EventSection.prototype.doAction = function(action, actionData, swalData, successData, options) {
      // todo: We should probably add an optional step that will ask extra data from the
      //  user via a generic popup modal including a form that will be sent to the
      //  action. e.g.: rejection message, new goalset due date to apply, etc...
      options = _.assignIn({}, options);
      var _this = this;
      var def = $q.defer();
      var swal = _.assignIn(
        {},
        {
          title: 'Are you sure you want to continue?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'OK'
        },
        swalData);

      var success = _.assignIn(
        {},
        {
          title: 'Confirmation',
          text: 'Action has been successfully applied'
        },
        successData
      );

      var process = function() {
        EventSections.doAction(action, actionData, _this.doc._id, options)
          .then(function(result) {
            if (!success.skip) {
              Notify.success(success.text, success.title);
            }
            $rootScope.$broadcast('ResetChangesTimeout');
            $rootScope.$broadcast('ItemReloadRequested', {
              type: 'eventSection',
              id: _this.doc._id,
              data: {
                event: _this.doc.event
              }
            });
            AsyncTasks.startUpdator();
            def.resolve(result);
            // return _this.reload();
          })
          .catch(function(error) {
            Utils.showError(error);
            def.reject(error);
          });
      };

      if (swal.skip) {
        process();
      } else {
        Utils.swal(
          swal,
          function(isConfirm) {
            if (!isConfirm) {
              def.reject();
              return;
            }

            process();
          });
      }
      return def.promise;
    };

    EventSection.prototype.getResponsibles = function(filledBy, search) {
      var options = { state: 'active', roles: filledBy, rolesAppliesTo: this.doc.eventOwner };
      if (search) {
        options.autocomplete_fullname = search;
      }

      return UsersStub.findAll(options)
        .then(function(data) {
          return data.filter(function(user) {
            if (filledBy.indexOf('system:timeline-owner') !== -1) {
              return true;
            }

            return (user.username !== Auth.currentUser());
          }).map(function(user) {
            return {
              _id: user.username,
              key: user.username,
              email: user.email,
              fullname: _.trim(
                (user.firstName || '') + ' ' +
                (user.lastName || '')),
              name: _.trim(
                (user.firstName || '') + ' ' +
                (user.lastName || '') + ' <' +
                (user.email || '') + '>'
              ) || user.username
            };
          });
        });
    };

    EventSection.prototype.loadSecondaryButtons = function() {
      return [];
    };

    EventSection.prototype.loadFeaturedButtons = function(user) {
      var _this = this;
      return [
        {
          label: 'Fill in',
          icon: 'icon-edit',
          href: (function() {
            return $state.href(
              'epf.events.section-fillin',
              { id: _this.doc._id, user: user }
            );
          })(),
          klass: 'text-primary',
          btnClass: 'btn-primary',
          showCondition: _this.checkPermission.bind(_this, 'canFillIn')
        }
      ];
    };

    EventSection.prototype.loadActionButtons = function(user) {
      var _this = this;
      return [
        {
          label: 'Fill in',
          icon: 'icon-edit',
          type: 'button',
          color: 'primary',
          href: (function() {
            return $state.href(
              'epf.events.section-fillin',
              { id: _this.doc._id, user: user }
            );
          })(),
          showCondition: _this.checkPermission.bind(_this, 'canFillIn')
        },
        {
          label: 'Reject',
          icon: 'icon-cancel',
          color: 'warning',
          type: 'link',
          position: 1,
          onClick: _this.reject.bind(_this),
          showCondition: _this.checkPermission.bind(_this, 'canReject')
        },
        {
          label: 'Delete',
          icon: 'icon-trash',
          position: 1,
          color: 'danger',
          type: 'link',
          onClick: _this.remove.bind(_this),
          showCondition: _this.checkPermission.bind(_this, 'canDelete')
        }
      ];
    };

    /** Permissions */
    EventSection.prototype.hasPerm = function(permission, options) {
      options = options || {};

      var auth;
      var own = options.alwaysOwn || this.doc.user === this.doc.eventOwner;

      if (own) {
        auth = Security.hasPermission(permission + '.own');
      } else {
        auth = Security.hasPermissionFor(permission, this.doc.eventOwner);
      }

      var promise;
      if (options.noPending) {
        promise = this.noPending();
      } else {
        promise = $q.when();
      }

      return promise.then(function() {
        return auth;
      });
    };

    EventSection.prototype.checkPermission = function(perm) {
      if (perm === undefined) {
        return $q.when(true);
      }

      if (this[perm] !== undefined) {
        return this[perm]();
      } else if (perm === 'canCreate') {
        return this.canCreate();
      }

      return $q.reject({ status: 403 });
    };

    EventSection.prototype.canCreate = function() {
      if (!this.isNew()) {
        return $q.reject({ status: 403 });
      }

      var _this = this;
      return this.hasPerm('events.create')
        .then(function() {
          return EventTypes.findAvailableFor(_this.doc.eventOwner);
        })
        .then(function(availableEventTypes) {
          var eventTypeIds = _.map(availableEventTypes, function(eventType) {
            return eventType._id;
          });
          if (_.indexOf(eventTypeIds, _this.doc.eventType) === -1) {
            return $q.reject({ status: 403 });
          }
        });
    };

    EventSection.prototype.canFillIn = function() {
      if (this.doc.state !== 'draft') {
        return $q.reject({ status: 403 });
      }

      // Only user can fill in a response
      if (!this.isMine()) {
        return $q.reject({ status: 403 });
      }

      return this.noPending();
    };

    EventSection.prototype.canDelete = function() {
      // We can always delete obsolete sections
      if (this.doc.state === 'obsolete') {
        return $q.when();
      }

      if (this.doc.state === 'merged') {
        return $q.reject({ status: 403, message: 'Merged sections cannot be removed' });
      }

      // User can delete own first drafts (newly created section)
      if (
        (this.isMine() && this.doc.state === 'draft') &&
        ((this.doc.meta && this.doc.meta.quickDraft) || this.getCurrentIndex() === 0)
      ) {
        return this.hasPerm('events.delete', { noPending: true, alwaysOwn: true });
      }

      // Otherwise only the ones with delete completed may remove
      return $q.reject({ status: 403, message: 'Non-first section can be only rejected' });
    };

    EventSection.prototype.canReject = function() {
      // We can reject only drafts
      if (this.doc.state !== 'draft') {
        return $q.reject({ status: 403 });
      }

      // First section cannot be rejected
      if (this.getCurrentIndex() <= 0) {
        return $q.reject({ status: 403 });
      }

      // Rejecting is not possible by other people
      if (!this.isMine()) {
        return $q.reject({ status: 403 });
      }

      // Event owner cannot reject their own event
      if (this.isEventOwners()) {
        return $q.reject({ status: 403 });
      }
      return this.noPending();
    };

    /** Stuff common to events */
    EventSection.prototype.loadQueue = function(options) {
      options = options || {};
      var _this = this;
      if (!options.force && this._queue !== undefined) {
        return $q.when(this._queue);
      }

      this._queue = Queue.findByRelated(this.doc._id)
        .then(function(data) {
          if (data.length > 0) {
            _this._queue = data[0].doc;
            return data[0].doc;
          }
        })
        .catch(function(error) {
          console.log(error);
        });

      return this._queue;
    };

    EventSection.prototype.isPending = function() {
      return this.loadQueue()
        .then(function(queue) {
          return queue !== undefined && ['success', 'failure'].indexOf(queue.state) === -1;
        });
    };

    EventSection.prototype.noPending = function() {
      return this.isPending()
        .then(function(value) {
          if (value) {
            return $q.reject({ status: 403 });
          }
        });
    };

    EventSection.prototype.openEventPreview = function(options) {
      var _this = this;

      return $uibModal.open({
        animation: true,
        size: 'lg',
        templateUrl: 'app/components/events/eventsection.preview.html',
        controller: 'EventSectionPreviewController',
        controllerAs: 'eventPreviewCtrl',
        resolve: {
          eventSection: function() {
            return _this;
          },
          options: function() {
            return options;
          }
        }
      });
    };

    EventSection.prototype.openTagsPopup = function(eventExtras) {
      var _this = this;
      var def = $q.defer();
      var popup = $uibModal.open({
        animation: true,
        templateUrl: 'app/components/events/directives/tagspopup.html',
        size: 'dialog',
        controller: ['FormsService', '$uibModalInstance',
          function(Form, $uibModalInstance) {
            var popctrl = this;
            this.form = new Form();
            BlueprintUtils.getBlueprintsField(_this.doc.eventOwner)
              .then(function(data) {
                if (data === undefined) {
                  popctrl.nodata = true;
                  return;
                }
                popctrl.form.addField(data);
              });
            this.eventExtras = eventExtras;
            this.user = _this.doc.eventOwner;

            this.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };

            this.success = function() {
              $uibModalInstance.close();
            };
          }
        ],
        controllerAs: 'ctrl'
      });

      popup.result.finally(function() {
        def.resolve();
      });
      return def.promise;
    };

    EventSection.prototype.openRejectionFormPopup = function(doActionOptions) {
      var _this = this;

      var def = $q.defer();

      $uibModal.open({
        animation: true,
        templateUrl: 'app/components/events/partials/reject-section.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'eventSectionDoc',
          'form',
          'doActionOptions',
          function($scope, $uibModalInstance, eventSectionDoc, form, doActionOptions) {
            $scope.model = {};
            $scope.form = form;

            $scope.dismiss = function() {
              def.reject();
              $uibModalInstance.dismiss('cancel');
            };

            $scope.rejectSection = function(isValid) {
              if (!isValid) {
                return;
              }
              $uibModalInstance.dismiss('cancel');

              return _this.doAction(
                'section_reject',
                {
                  section_id: eventSectionDoc._id,
                  rejection_message: $scope.model.rejectionMessage
                },
                { skip: true },
                { text: 'The invitation has been rejected' },
                doActionOptions
              ).then(function() {
                def.resolve();
              });
            };
          }
        ],
        size: 'md',
        resolve: {
          eventSectionDoc: function() {
            return _this.doc;
          },
          form: function() {
            return new Form([
              {
                id: 'rejectionMessage',
                type: 'text',
                label: 'Message',
                required: true
              }
            ]);
          },
          doActionOptions: function() {
            return doActionOptions;
          }
        }
      });

      return def.promise;
    };

    EventSection.prototype.getBorderClass = function() {
      if (_.isUndefined(this.doc)) {
        return '';
      }

      if (this.doc.state === 'merged') {
        return 'log-event';
      }

      return 'progress-border-' + EVENT_STATES[this.doc.state.toUpperCase()].borderStyle;
    };

    EventSection.prototype.getExtra = function() {
      var _this = this;
      if (this.options.noExtra) {
        return $q.when([]);
      }

      if (!_.isUndefined(this._extra)) {
        return $q.when(this._extra);
      }

      if (this.doc._rev === undefined) {
        return $q.when([]);
      }

      return EventSections.getExtra(this)
        .then(function(data) {
          _this._extra = data;
          return data;
        });
    };

    EventSection.prototype.getInitialExtra = function() {
      var extra = _.assignIn(EventExtras.getInitial(),
        {
          event: this.doc.event,
          user: this.doc.eventOwner
        });
      return extra;
    };

    EventSection.prototype.addExtra = function(extra, kzOpts) {
      if (this.doc.state === 'merged') {
        return $q.reject({
          status: 500,
          message: 'Commenting on merged section is not allowed yet'
        });
      }
      var _this = this;
      if (extra._id === undefined) {
        _.assignIn(extra, EventExtras.getInitial());
      }

      extra = _.assignIn(extra, {
        event: this.doc.event,
        user: this.doc.eventOwner
      });

      extra.date.modified = Utils.now();

      return EventSections.addExtra(this, extra, kzOpts)
        .then(function(data) {
          _this._extra = undefined;
          return $q.all([
            _this.collectBlueprints(true),
            _this.collectDocuments(true),
            data
          ]);
        })
        .then(function(_res) {
          $rootScope.$broadcast('KZEventInvalidated', { id: _this.doc._id });
          return extra;
        });
    };

    EventSection.prototype.deleteExtra = function(extra) {
      var _this = this;
      return EventSections.deleteExtra(this, extra)
        .then(function() {
          return $q.all([
            _this.collectBlueprints(),
            _this.collectDocuments()
          ]);
        })
        .then(function(res) {
          $rootScope.$broadcast('KZEventInvalidated', { id: _this.doc._id });
          return res;
        });
    };

    EventSection.prototype.prepareForFulltext = function() {
      var _this = this;
      return this.init()
        .then(function() {
          var result = {
            id: _this.doc._id,
            title: _this.eventType !== undefined ? _this.eventType.name : ''
          };
          return result;
        });
    };

    EventSection.prototype.getValueId = function(defField) {
      var valueId;
      if (defField.type === 'role') {
        valueId = defField.role;
      } else if (defField.type === 'relation') {
        valueId = defField.relation;
      } else if (defField.type === 'blueprint') {
        valueId = defField.blueprint;
      } else {
        valueId = defField._id;
      }

      return valueId;
    };


    /**
     * Collect all blueprint categories
     *
     * Each event is tagged by blueprint categories, these may come
     * from:
     *  - an event type tagging
     *  - a blueprint field
     *  - a event extras tagging
     *
     * @return {Promise} Returns a promise
     */
    EventSection.prototype.collectBlueprints = function(reload) {
      // console.log(Date(), 'Starting event blueprints loading');
      // Do not collect for merged event section as we are not interested in the
      // comments. Later we might have special commenting on merged sections
      // unrelated to event.
      if (this.doc.state === 'merged') {
        return $q.when([]);
      }

      var _this = this;
      if (!reload && !_.isEmpty(_this.categories)) {
        return $q.when(_this.categories);
      }

      _this.categories = [];

      if (this.eventType && this.eventType.tags) {
        this.eventType.tags.forEach(function(tag) {
          if (_this.categories.indexOf(tag) === -1) {
            _this.categories.push(tag);
          }
        });
      }

      var extraCategories = this.getExtra({ cached: !reload })
        .then(function(extras) {
          extras.filter(function(extra) {
            return (extra.tags && extra.tags.length > 0) ||
                   (extra.blueprints && extra.blueprints.length > 0);
          })
          .forEach(function(extra) {
            if (extra.tags) {
              extra.tags.forEach(function(tag) {
                if (_this.categories.indexOf(tag.tag) === -1) {
                  _this.categories.push(tag.tag);
                }
              });
            }

            if (extra.blueprints) {
              extra.blueprints.forEach(function(item) {
                if (_this.categories.indexOf(item) === -1) {
                  _this.categories.push(item);
                }
              });
            }
          });
        });

      var fieldCategories = Blueprints.findAll()
        .then(function(rows) {
          var ids = rows.map(function(row) {
            return row.id;
          });

          _this.fields().forEach(function(fld) {
            if (ids.indexOf(fld.key) > -1) {
              if (_.isArray(fld.value)) {
                fld.value.forEach(function(item) {
                  if (_this.categories.indexOf(item) === -1) {
                    _this.categories.push(item);
                  }
                });
              } else if (_this.categories.indexOf(fld.value) === -1) {
                _this.categories.push(fld.value);
              }
            }
          });
        });

      return $q.all([
        extraCategories,
        fieldCategories
      ]);
    };

    EventSection.prototype.collectDocuments = function(reload) {
      // console.log(Date(), 'Starting event documents loading');
      // Do not collect for merged event section as we are not interested in the
      // comments. Later we might have special commenting on merged sections
      // unrelated to event.
      if (this.doc.state === 'merged') {
        return $q.when([]);
      }

      var _this = this;
      if (!reload && !_.isEmpty(_this.documents)) {
        return $q.when(_this.documents);
      }

      _this.documents = [];

      var extraDocuments = this.getExtra({ cached: !reload })
        .then(function(extras) {
          extras.filter(function(extra) {
            return extra.documents && extra.documents.length > 0;
          })
          .forEach(function(extra) {
            extra.documents.forEach(function(doc) {
              if (_this.documents.indexOf(doc.value[0]) === -1) {
                _this.documents.push(doc.value[0]);
              }
            });
          });
        });

      var fieldDocuments = $q.when()
      .then(function() {
        _this.fields().forEach(function(fld) {
          if (fld.value && fld.value.type === 'document') {
            fld.value.value.forEach(function(item) {
              if (_this.documents.indexOf(item) === -1) {
                _this.documents.push(item);
              }
            });
          }
        });
      });

      var sectionDocuments = $q.when()
      .then(function() {
        if (!_this.doc.data.documentIds) {
          return;
        }

        _this.doc.data.documentIds.forEach(function(docs) {
          docs.value.forEach(function(value) {
            _this.documents.push(value);
          });
        });
      });

      return $q.all([
        extraDocuments,
        fieldDocuments,
        sectionDocuments
      ]);
    };

    EventSection.prototype.fields = function() {
      var fields = [];
      if (!this.doc.data) {
        return [];
      }

      _.forOwn(this.doc.data.fields, function(value, key) {
        fields.push({ key: key, value: value });
      });

      return fields;
    };

    EventSection.prototype.getSource = function() {
      return {};
    };

    EventSection.prototype.getDateFor = function(action) {
      if (action === 'createdDate') {
        return this.doc.createdDate;
      }

      var dates = this.doc.dates || [];
      var filtered;
      if (action === 'any') {
        filtered = dates;
      } else {
        filtered = _.filter(dates, function(item) {
          return item.action === action;
        });
      }

      if (filtered.length === 0) {
        return '';
      }

      return _.last(filtered).date;
    };

    EventSection.prototype.getFilledBy = function() {
      var sectionType = this.doc.section_type || 'user';
      var filledBy;
      if (sectionType === 'user') {
        filledBy = this.doc.user;
      } else if (sectionType === 'request') {
        filledBy = '__notregistered__';
      } else if (sectionType === 'not_registered') {
        filledBy = '__notregistered__';
      } else {
        filledBy = '__anonymous__';
      }

      return filledBy;
    };

    EventSection.prototype.parseInvitation = function(invitation) {
      var responsibles = invitation.responsible || [];
      var invites = invitation.invites;
      var res = [];
      _.forEach(responsibles, function(item) {
        res.push({ type: 'user', username: item });
      });
      _.forEach(invites, function(item) {
        res.push({ type: 'request', username: item });
      });
      return res;
    };

    EventSection.prototype.getInvitedUsers = function() {
      var invitation = this.doc.invitation || {};
      return this.parseInvitation(invitation);
    };

    EventSection.prototype.getNextInvitedUsers = function() {
      var invitation = this.doc.nextInvitation || {};
      return this.parseInvitation(invitation);
    };

    EventSection.prototype.getFieldTitle = function(field) {
      if (field.name) {
        return $q.when(field.name);
      }

      if (['report', 'blueprint', 'relation'].indexOf(field.type) !== -1) {
        var id = field[field.type];
        // This bit is ugly, we should use database here, but I couldn't
        // figure out how else to do it - maybe a general store?
        var db = Database.get('master');

        return db.get(id)
          .then(function(obj) {
            return obj.title || obj.name;
          })
          .catch(function() {
            return field.type;
          });
      }

      return $q.when(field._id);
    };

    EventSection.prototype.clearUnknownFields = function() {
      var _this = this;
      var def = this.getDefSection();
      var fieldIds = _.map(def.fields, this.getValueId);

      var unknownFields = [];
      _.forOwn(this.doc.data.fields, function(_val, key) {
        if (key.startsWith('__')) {
          return;
        }
        if (fieldIds.indexOf(key) === -1) {
          unknownFields.push(key);
        }
      });

      if (unknownFields.length > 0) {
        $log.warn('Clearing unknown fields', unknownFields);
        _.forEach(unknownFields, function(fld) {
          delete _this.doc.data.fields[fld];
        });
      }
    };

    EventSection.prototype.validateForPublish = function() {
      var _this = this;
      if (this.doc.eventType !== this.eventType._id) {
        return $q.reject({ status: 412, message: 'Event type does not match' });
      }

      // Validate header
      if (!_.isEmpty(this.doc.meta) && this.doc.meta.startDate > this.doc.meta.endDate) {
        return $q.reject(
          { status: 412, message: 'The Date occurred on cannot be after the end date!' }
        );
      }

      var def = this.getDefSection();
      var fieldMapping = {};
      _.forEach(def.fields, function(fld) {
        fieldMapping[_this.getValueId(fld)] = fld._id;
      });

      var fields = this.doc.data.fields;

      var getFieldDef = function(fieldId) {
        return _.find(def.fields, { _id: fieldMapping[fieldId] || fieldId });
      };

      var isRequired = function(defField, options) {
        options = options || {};

        if (!defField.isRequired && !options.alwaysRequired) {
          return false;
        }

        var restricted = Utils.clearRestricted(defField.restricted);
        if (_.isEmpty(restricted) || !restricted.by) {
          return true;
        }

        var resFieldDef = getFieldDef(restricted.by);
        if (resFieldDef === undefined) {
          return false;
        }

        var byValue = fields[_this.getValueId(resFieldDef)];

        if (restricted.to !== undefined) {
          if (!Utils.bool(byValue)) {
            return false;
          }

          if (_.isArray(byValue)) {
            return _.intersection(byValue, restricted.to).length > 0;
          }

          return restricted.to.indexOf(byValue) !== -1;
        }

        if (restricted.by) {
          return Utils.bool(byValue);
        }
      };

      var promises = [];

      def.fields.forEach(function(item) {
        var value = fields[_this.getValueId(item)];
        if (!isRequired(item, { alwaysRequired: item.type === 'report' })) {
          // No action here;
        } else if (item.type === 'report' && (value || {}).generatedAt === undefined) {
          // promises.push(_this.getFieldTitle(item));
        } else if (
          (_.isArray(value) || _.isObject(value) || _.isString(value)) && _.isEmpty(value)
        ) {
          promises.push(_this.getFieldTitle(item));
        } else if (value === undefined) {
          promises.push(_this.getFieldTitle(item));
        }
      });

      var fieldsPromise;
      if (!_.isEmpty(promises)) {
        fieldsPromise = $q.all(promises)
          .then(function(errors) {
            return $q.reject({
              status: 412,
              message: 'Please fill in the required fields',
              errors: errors
            });
          });
      } else {
        fieldsPromise = $q.when();
      }

      var turnitinPromise = Turnitin.isEnabled()
        .then(function(res) {
          if (!res) {
            return [];
          }
          var tp = [];
          var allowedStates = ['complete', 'report_pending', 'report_not_requested'];
          def.fields.forEach(function(item) {
            var valueId = _this.getValueId(item);
            var value = fields[valueId];
            if (Utils.isBlank(value)) {
              return;
            }

            if (item.enableAntiPlagiarismRequired) {
              if (!_.isArray(fields[valueId])) {
                var hash = Turnitin.getHash(value);
                var turnitId = `__turnitin__${valueId}`;
                var turnitinValue = fields[turnitId];
                var submission = _.last(turnitinValue.submissions || []);
                if (!submission || allowedStates.indexOf(submission.state.state) === -1) {
                  tp.push(_this.getFieldTitle(item).then(function(title) {
                    return title;
                  }));
                } else if (hash !== submission.uploaded_hash) {
                  tp.push(_this.getFieldTitle(item).then(function(title) {
                    return title;
                  }));
                }
              } else {
                // If the value is array it should be a file but it has to have a
                // turnitin per item
                var turnitInFiles = fields[valueId] || [];
                _.forEach(turnitInFiles, function(file) {
                  if (file.turnitin === undefined) {
                    return;
                  }
                  var submission = _.last(file.turnitin.submissions);
                  if (!submission || allowedStates.indexOf(submission.state.state) === -1) {
                    tp.push($q.when(file.filename));
                  }
                });
              }
            }
          });
          if (_.isEmpty(tp)) {
            return $q.when([]);
          }
          return $q.all(tp);
        })
        .then(function(errors) {
          if (!_.isEmpty(errors)) {
            return $q.reject({
              status: 412,
              message: 'Please check Anti-plagiarism',
              errors: errors
            });
          }
        });

      return $q.all([fieldsPromise, turnitinPromise]);
    };

    EventSection.prototype.getProgress = function() {
      if (!this.eventType) {
        return [];
      }

      var states = [];
      var _this = this;
      var origSections = (this.meta || {}).sections || {};
      _.forEach(this.eventType.sections || [], function(section) {
        var state;
        var substate;
        if (section._id === _this.doc.section) {
          if (_this.doc.state === 'merged') {
            state = 'complete';
            substate = 'merged';
          } else if (_this.doc.state === 'pending') {
            state = 'approval';
            substate = 'approval';
          } else {
            state = 'pending';
            substate = 'sectiondraft';
          }
        } else {
          var origSection = origSections[section._id];
          if (_this.doc.state === 'merged') {
            state = 'other';
            substate = 'other';
          } else {
            state = origSection === undefined ? 'missing' : origSection.state;
            substate = state;
          }
        }
        states.push({ state: state, substate: substate });
      });
      return states;
    };
    return EventSection;
  }

  EventSectionFactory.$inject = [
    '$q',
    '$log',
    '$state',
    '$rootScope',
    'EventFactory',
    'EventsService',
    'EventSectionsService',
    'EventTypesService',
    'EventExtrasService',
    'FormsService',
    'UsersStubService',
    'BlueprintsService',
    'BlueprintUtils',
    'AuthService',
    'UtilsService',
    'SecurityService',
    'QueueService',
    '$uibModal',
    'NotifyService',
    'DatabaseService',
    'AutoSaveService',
    'AsyncTasksService',
    'TurnitinService',
    'EVENT_STATES'
  ];

  angular.module('component.events')
    .factory('EventSectionFactory', EventSectionFactory);
})();
