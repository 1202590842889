import './goals.module.js';
import './directives';
import './cards';
import './partials';
import './widgets';
import './goal.preview.controller.js';
import './goals.service.js';
import './goal.factory.js';
import './goals.constants.js';
import './goal.security.js';
import './target.light.factory.js';
import './goal.light.factory.js';
import './manage-categories.controller.js';
import './targets.link.controller.js';
import './targets.constants.js';
import './target.factory.js';
import './targets.service.js';
import './goals.route.js';
import './goals.cards.js';
import './goals-definition.controller.js';
import './goals.store.js';
import './goal.controller.js';
import './goals.search.js';
import './goals.controller.js';
