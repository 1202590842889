(function() {
  'use strict';

  function Cards(Sidebar) {
    var service = {};

    service.register = function() {
      var cards = [
        {
          id: 'documents-bulk',
          title: 'Selected documents to move',
          directive: 'sidebar-document-bulk',
          options: {
            routes: [
              'epf.documents.index',
              'epf.documents.folder'
            ]
          }
        }
      ];

      Sidebar.registerCards(cards);
    };

    return service;
  }

  Cards.$inject = ['SidebarService'];

  angular.module('component.documents')
    .service('DocumentsCards', Cards);
})();
