(function() {
  'use strict';

  function SlotController($scope, $state, Security) {
    var ctrl = this;

    ctrl.shownAnswer = false;
    ctrl.showAnswer = function() {
      ctrl.shownAnswer = !ctrl.shownAnswer;
    };

    // this may be also used for the details view, move it if necessary
    ctrl.actionButtons = [
      {
        label: 'Show answer',
        ariaLabel: 'Show answer for ' + $scope.item.doc.question,
        icon: 'icon-edit',
        onClick: ctrl.showAnswer,
        klass: 'text-info'
      },
      {
        label: 'Edit',
        ariaLabel: 'Edit ' + $scope.item.doc.question,
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.faqs.edit', { id: $scope.item.doc._id }); })(),
        showCondition: Security.hasPermission.bind(this, 'faqs.edit'),
        klass: 'text-info'
      }
    ];
  }

  SlotController.$inject = ['$scope', '$state', 'SecurityService'];

  function SlotDirective() {
    return {
      scope: {
        item: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/faqs/faq.slot.html',
      controller: SlotController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('faqSlot', SlotDirective)
    .controller('faqSlotController', SlotController);
})();
