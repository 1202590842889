(function() {
  'use strict';

  function EventFactory(
    $q,
    $log,
    $state,
    $rootScope,
    Events,
    EventType,
    EventTypes,
    EventExtras,
    Documents,
    User,
    UsersStub,
    Blueprints,
    Goals,
    Auth,
    Utils,
    Security,
    Queue,
    $uibModal,
    Notify,
    Network,
    AsyncTasks,
    EVENT_STATES,
    EVENT_ALLOWED_STATES
  ) {
    var Event = function(doc, options) {
      this.key = '';
      this.doc = doc;
      this.tmpDoc = {}; // use to auto-save before draft or publish
      this.oldtmpDoc = {}; // use to compare if there were changed between two auto-save
      this.eventType = {};
      this.orig_doc = {};
      this.categories = [];
      this.blueprintsInFields = {};
      this.clear_data();
      this.loaded = false;
      this.options = options || {};
      if (_.has(this.options, 'shared')) {
        this.isShared = this.options.shared;
      } else {
        this.isShared = true;
      }

      if (_.has(this.options, 'isLocal')) {
        this.isLocal = this.options.isLocal;
      } else {
        this.isLocal = true;
      }
      if (this.options.extras) {
        this._extra = this.options.extras;
      }
      if (this.options.eventType) {
        this.eventType = this.options.eventType;
        // this.eventTypeObj = new EventTypeLight({ doc: this.options.eventType });
        this.eventTypeVersion = this.eventType.version;
      }
      this.loadMeta();
    };

    Event.prototype.updateViewLink = function() {
      if (this.isLocal) {
        this.sref = 'epf.events.view';
        this.srefOpts = { id: this.doc._id };
      } else {
        this.sref = 'epf.users.events-view';
        this.srefOpts = { user: this.doc.user, id: this.doc._id };
      }

      this.href = $state.href(this.sref, this.srefOpts);
    };

    Event.prototype.clear_data = function() {
      this.currentSection = {};
      this.nextSection = {};
      this.pendingSections = [];
      this.completedSections = [];
      this.allSections = [];
      this.categories = [];
      this.meta = undefined;
      this._extra = undefined;
      this._queue = undefined;
    };

    Event.prototype.reset = function(doc) {
      if (!_.isUndefined(doc)) {
        this.doc = doc;
      }

      this.clear_data();
      this._queue = undefined;
      this.loaded = false;
    };

    Event.prototype.reload = function(doc, options) {
      options = options || {};

      if (doc !== undefined) {
        if (doc._id !== this.doc._id) {
          throw { status: 500, message: 'Document ID does not match the current object' };
        }
        if (!options.noReset) {
          this.reset();
        }
        return this.init(doc);
      }

      var docId = this.doc._id;
      var user = (Auth.currentUser() === this.doc.user) ? undefined : this.doc.user;
      if (!options.noReset) {
        this.reset();
      }
      return this.load(docId, user, undefined, undefined, { reload: true });
    };

    Event.prototype.load = function(docId, user, token, _needToLoadTmp, options) {
      options = options || {};
      if (this.loaded && !options.reload) {
        return $q.when(this);
      }

      // console.log(Date(), 'Starting event factory loading');
      var _this = this;
      var promise;
      if (user === undefined) {
        promise = Events.findFor(docId, Auth.currentUser());
        this.isLocal = true;
      } else {
        promise = Events.findFor(docId, user);
        this.isLocal = false;
      }

      return promise
      .catch(function(error) {
        if (token && error.status === 404) {
          return Events.findByToken(token);
        }

        return $q.reject(error);
      }).then(function(data) {
        // console.log(Date(), 'Downloaded event');
        return _this.init(data, options);
      });
    };

    // function getSectionStates(doc) {
    //   var sectionsStates = {};
    //   _.forEach(doc.sections, function(section, sectionId) {
    //     sectionsStates[sectionId] = section.state;
    //   });

    //   return sectionsStates;
    // }

    Event.prototype.init = function(doc, options) {
      options = options || {};

      // console.log(Date(), 'Starting event factory init');
      var _this = this;

      if (_this.loaded && !options.reload) {
        return $q.when(_this);
      }

      if (doc && !_.isUndefined(doc.isShared)) {
        _this.isShared = doc.isShared;

        // Check whether there is a wrapper around it
        doc = doc.doc || doc;
      }

      // this.clear_data();
      this.doc = doc || this.doc;
      this.loadMeta();
      this.key = this.doc._id;
      this.doc.state = this.doc.state || 'draft';

      if (_.isUndefined(this.doc)) {
        return $q.reject({
          status: 400,
          message: 'Cannot initialize event object - doc is undefined'
        });
      }

      _this.updateViewLink();

      // Store the original data so we can validate against it
      // I'm aware that this can be hacked but hell anything in the frontend can be
      this.orig_doc = angular.copy(doc);
      return this.loadEventType()
        .then(function(_eventType) {
          // console.log(Date(), 'Starting event loading');
          _this.loadSections();
          _this.loadMeta();
          _this.actionButtons = _this.loadActionButtons();
          _this.secondaryActionButtons = _this.loadSecondaryButtons();
          if (_this.options.noExtra) {
            return;
          }

          return _this.collectBlueprints(options.reload)
            .then(function() {
              return _this.collectDocuments();
            });
        })
        .then(function() {
          _this.loaded = true;
          return _this;
        })
        .catch(function(err) {
          $log.warn('Could not initialize event');
          return $q.reject(err);
        });
    };

    // Event cannot be in draft
    Event.prototype.isDraft = function() {
      return false;
    };

    Event.prototype.isPrivate = function() {
      if (_.isUndefined(this.doc)) {
        return false;
      }

      return this.doc.visibility === 'private';
    };

    Event.prototype.isMine = function() {
      return Auth.currentUser() === this.doc.user;
    };

    Event.prototype.isComplete = function() {
      return this.doc.state === 'complete';
    };

    Event.prototype.isPending = function() {
      return this.loadQueue()
        .then(function(queue) {
          return queue !== undefined && ['success', 'failure'].indexOf(queue.state) === -1;
        });
    };

    Event.prototype.noPending = function() {
      return this.isPending()
        .then(function(value) {
          if (value) {
            return $q.reject({ status: 403 });
          }
        });
    };

    Event.prototype.waitingForAnonymous = function() {
      return this.currentSection
        && this.currentSection.def
        && this.currentSection.def.multiSource
        && _.indexOf(this.currentSection.def.filledBy, 'system:anonymous-external') !== -1;
    };

    Event.prototype.containsGoalInOneOfTheSection = function() {
      return !_.isUndefined(_.find(this.eventType.sections, function(section) {
        return !_.isUndefined(
            _.find(section.fields, function(field) {
              return field.type === 'goal';
            })
        );
      })
      );
    };

    Event.prototype.containsGoalInCurrentSection = function() {
      this.containsGoal = false;
      if (this.currentSection.def) {
        this.containsGoal = this.containsGoalForSection(this.currentSection.def);
      }

      return $q.when(this.containsGoal);
    };

    Event.prototype.containsGoalForSection = function(sectionDef) {
      var found = _.find(sectionDef.fields, function(field) {
        return field.type === 'goal';
      });

      return !_.isUndefined(found);
    };

    Event.prototype.checkLocal = function() {
      if (this.isLocal !== undefined) {
        return $q.when(this.isLocal);
      }

      var _this = this;

      return Events.find(this.doc._id)
        .then(function() {
          return true;
        })
        .catch(function() {
          return false;
        })
        .then(function(isLocal) {
          _this.isLocal = isLocal;
          return isLocal;
        });
    };

    Event.prototype.haveEnoughResponses = function() {
      if (this.currentSection.section === undefined) {
        return false;
      }

      var responses = this.currentSection.section.items.length;
      var required = this.currentSection.def.multiSourceMin || 0;
      return responses >= required;
    };

    /**
     * Collect all blueprint categories
     *
     * Each event is tagged by blueprint categories, these may come
     * from:
     *  - an event type tagging
     *  - a blueprint field
     *  - a event extras tagging
     *
     * @return {Promise} Returns a promise
     */
    Event.prototype.collectBlueprints = function(reload) {
      // console.log(Date(), 'Starting event blueprints loading');
      var _this = this;
      if (!reload && !_.isEmpty(_this.categories)) {
        return $q.when(_this.categories);
      }

      if (!reload && !_.isEmpty(_this.doc.flat_blueprints)) {
        _this.categories = _this.doc.flat_blueprints;
        return $q.when(_this.categories);
      }

      if (!reload && _this.doc.evaluated_blueprints !== undefined) {
        _this.categories = _.filter(_.map(_this.doc.evaluated_blueprints, function(blp) {
          if (blp.type === 'discrete') {
            return blp.value;
          }
        }), Boolean);

        return $q.when(_this.categories);
      }

      if (!Network.isOffline()) {
        return Events.getBlueprints(_this.doc._id)
          .then(function(blueprints) {
            _this.categories = _.filter(_.map(blueprints, function(blp) {
              if (blp.type === 'discrete') {
                return blp.value;
              }
            }), Boolean);
            return _this.categories;
          })
          .catch(function(err) { console.error(err); });
      }

      _this.categories = [];

      if (this.eventType.tags) {
        this.eventType.tags.forEach(function(tag) {
          if (_this.categories.indexOf(tag) === -1) {
            _this.categories.push(tag);
          }
        });
      }

      var extraCategories = this.getExtra({ cached: !reload })
        .then(function(extras) {
          extras.filter(function(extra) {
            return (extra.tags && extra.tags.length > 0) ||
                   (extra.blueprints && extra.blueprints.length > 0);
          })
          .forEach(function(extra) {
            if (extra.tags) {
              extra.tags.forEach(function(tag) {
                if (_this.categories.indexOf(tag.tag) === -1) {
                  _this.categories.push(tag.tag);
                }
              });
            }

            if (extra.blueprints) {
              extra.blueprints.forEach(function(item) {
                if (_this.categories.indexOf(item) === -1) {
                  _this.categories.push(item);
                }
              });
            }
          });
        });

      var fieldCategories = Blueprints.findAll()
        .then(function(rows) {
          var discreteBlueprints = [];
          var ids = rows.map(function(row) {
            if (_.indexOf(['discrete', 'discrete_multiple', 'tree'], row.doc.blueprintType) > -1) {
              discreteBlueprints.push(row.doc._id);
            }
            return row.doc._id;
          });

          _this.fields().forEach(function(fld) {
            if (ids.indexOf(fld.key) > -1) {
              if (_.indexOf(discreteBlueprints, fld.key) > -1) {
                if (_.isArray(fld.value)) {
                  fld.value.forEach(function(item) {
                    if (_this.categories.indexOf(item) === -1) {
                      _this.categories.push(item);
                    }
                  });
                } else if (_this.categories.indexOf(fld.value) === -1) {
                  _this.categories.push(fld.value);
                }
              } else {
                _this.blueprintsInFields[fld.key] = fld.value;
              }
            }
          });
        });

      return $q.all([
        extraCategories,
        fieldCategories
      ]);
    };

    Event.prototype.collectDocuments = function(reload) {
      // console.log(Date(), 'Starting event documents loading');
      var _this = this;
      if (!reload && !_.isEmpty(_this.documents)) {
        return $q.when(_this.documents);
      }

      if (!reload && !_.isEmpty(_this.doc.flat_documents)) {
        _this.documents = _this.doc.flat_documents;
        return $q.when(_this.documents);
      }

      _this.documents = [];

      var extraDocuments = this.getExtra({ cached: !reload })
        .then(function(extras) {
          extras.filter(function(extra) {
            return extra.documents && extra.documents.length > 0;
          })
          .forEach(function(extra) {
            extra.documents.forEach(function(doc) {
              if (_this.documents.indexOf(doc.value[0]) === -1) {
                _this.documents.push(doc.value[0]);
              }
            });
          });
        });

      var fieldDocuments = $q.when()
      .then(function() {
        _this.fields().forEach(function(fld) {
          if (fld.value && fld.value.type === 'document') {
            fld.value.value.forEach(function(item) {
              if (_this.documents.indexOf(item) === -1) {
                _this.documents.push(item);
              }
            });
          }
        });
      });

      var sectionDocuments = $q.when()
      .then(function() {
        _this.allSections.forEach(function(sectionGroup) {
          if (!sectionGroup.section) {
            return;
          }

          sectionGroup.section.items.forEach(function(item) {
            if (!item.documentIds) {
              return;
            }

            var docIds = item.documentIds;
            if (!_.isArray(docIds)) {
              docIds = [docIds];
            }

            docIds.forEach(function(docs) {
              docs.value.forEach(function(value) {
                _this.documents.push(value);
              });
            });
          });
        });
      });

      return $q.all([
        extraDocuments,
        fieldDocuments,
        sectionDocuments
      ]);
    };

    Event.prototype.fields = function() {
      var fields = [];
      this.allSections.forEach(function(sectionGroup) {
        if (!sectionGroup.section) {
          return;
        }

        sectionGroup.section.items.forEach(function(item) {
          if (!item.fields) {
            return;
          }

          _.forOwn(item.fields, function(value, key) {
            fields.push({ key: key, value: value });
          });
        });
      });

      return fields;
    };

    Event.prototype.fieldsWithDef = function() {
      var fields = [];
      this.allSections.forEach(function(sectionGroup) {
        if (!sectionGroup.section) {
          return;
        }

        sectionGroup.def.fields.forEach(function(defItem) {
          sectionGroup.section.items.forEach(function(item) {
            if (!item.fields) {
              return;
            }

            fields.push({ key: defItem._id, value: item.fields[defItem._id], def: defItem });
          });
        });
      });

      return fields;
    };

    Event.prototype.stateChanged = function(status) {
      if ('status' in this.orig_doc) {
        return (status !== this.orig_doc.status);
      }

      return true;
    };

    Event.prototype.loadSecondaryButtons = function() {
      return [];
    };

    Event.prototype.loadActionButtons = function() {
      var _this = this;
      return [
        {
          label: 'Remind',
          icon: 'icon-bell',
          onClick: _this.rerequest.bind(_this),
          color: 'primary',
          type: 'link',
          showCondition: _this.checkPermission.bind(_this, 'canRerequest')
        },
        {
          label: 'Re-open',
          icon: 'icon-undo',
          onClick: _this.reOpen.bind(_this),
          color: 'primary',
          type: 'link',
          showCondition: _this.checkPermission.bind(_this, 'canReOpen')
        },
        {
          label: 'Retract',
          icon: 'icon-undo',
          onClick: _this.retract.bind(_this),
          color: 'warning',
          type: 'link',
          showCondition: _this.checkPermission.bind(_this, 'canRetract')
        },
        {
          label: 'Share',
          icon: 'icon-lock-open',
          onClick: _this.makePublic.bind(_this),
          color: 'primary',
          type: 'link',
          showCondition: _this.checkPermission.bind(_this, 'canMakePublic')
        },
        {
          label: 'Make private',
          icon: 'icon-lock',
          onClick: _this.makePrivate.bind(_this),
          color: 'primary',
          type: 'link',
          showCondition: _this.checkPermission.bind(_this, 'canMakePrivate')
        },
        {
          label: 'Delete',
          icon: 'icon-trash',
          onClick: _this.remove.bind(_this),
          color: 'danger',
          type: 'link',
          showCondition: _this.checkPermission.bind(_this, 'canDelete')
        }
      ];
    };

    Event.prototype.hasPerm = function(action, options) {
      options = options || {};

      var promise;
      if (options.noPending) {
        promise = this.noPending();
      } else {
        promise = $q.when();
      }

      var auth;
      var permission;

      // If handlOwn is false, make sure to assign a promise to auth
      var handleOwn = true;

      if (action === 'new') {
        permission = 'events.create';
      } else if (action === 'edit') {
        permission = 'events.edit';
      } else if (action === 'editCompleted') {
        permission = 'events.editCompleted';
      } else if (action === 'deleteMultiSourceResponse') {
        permission = 'events.deleteMultiSourceResponse';
      } else if (action === 'reopen') {
        if (Auth.currentUser() === this.doc.user) {
          permission = 'events.reopen';
        } else {
          permission = 'events.forceClose';
        }
      } else if (action === 'reopengoal') {
        if (Auth.currentUser() === this.doc.user) {
          permission = 'events.reopengoal';
        } else {
          permission = 'events.forceCloseGoal';
        }
      } else if (action === 'delete') {
        permission = 'events.delete';
        handleOwn = false;

        // return here so it is always own
        auth = Security.hasPermission(permission + '.own');
      } else if (action === 'deleteCompleted') {
        permission = 'events.deleteCompleted';
      } else if (action === 'forceClose') {
        permission = 'events.forceClose';
      } else if (action === 'forceCloseGoal') {
        permission = 'events.forceCloseGoal';
      } else if (action === 'view') {
        permission = 'events.view';
      } else if (action === 'goals.manage') {
        permission = 'goals.manage';
      } else if (action === 'viewAnon') {
        permission = 'events.viewAnon';
      }

      if (!permission) {
        return $q.reject({ message: ' no permission found for that action: ' + action });
      }

      if (handleOwn) {
        if (Auth.currentUser() === this.doc.user) {
          auth = Security.hasPermission(permission + '.own');
        } else {
          auth = Security.hasPermissionFor(permission, this.doc.user);
        }
      }

      return promise.then(function() {
        return auth;
      });
    };

    Event.prototype.openEventPreview = function(options) {
      var _this = this;

      $uibModal.open({
        animation: true,
        size: 'lg',
        templateUrl: 'app/components/events/preview.html',
        controller: 'EventPreviewController',
        controllerAs: 'eventPreviewCtrl',
        resolve: {
          event: function() {
            return _this;
          },
          options: function() {
            return options;
          }
        }
      });
    };

    Event.prototype.getEditableSections = function() {
      return _.chain(this.eventType.sections)
        .filter(function(item) {
          return item.editable;
        })
        .map(function(item) {
          return item._id;
        })
        .value();
    };

    function canEdit(options) {
      options = options || {};

      if (this.eventType.systemType === 'system') {
        return $q.reject({ status: 403 });
      }

      // Edit can be only in completed state
      if (options.edit && this.doc.state !== EVENT_STATES.COMPLETE.id) {
        return $q.reject({ status: 403 });
      }

      // Fill in can be only in draft state (surely not completed one)
      if (!options.edit && this.doc.state === EVENT_STATES.COMPLETE.id) {
        return $q.reject({ status: 403 });
      }

      if (options.own) {
        if (!this.isMine() && !this.isDraft()) {
          return $q.reject({ status: 403 });
        }
      } else if (this.isMine() || this.isDraft()) {
        return $q.reject({ status: 403 });
      }

      var _this = this;

      if (_this.doc.state === EVENT_STATES.COMPLETE.id) {
        var editable = _this.getEditableSections();

        var currUser = Auth.currentUser();
        var editableSections = _.filter(_this.allSections, function(item) {
          if (editable.indexOf(item._id) === -1) {
            return false;
          }

          var allowed = false;
          item.section.items.forEach(function(sec) {
            if (sec.filledBy === currUser) {
              allowed = true;
            }
          });

          return allowed;
        });

        if (editableSections.length === 0) {
          return _this.hasPerm('editCompleted');
        }

        return _this.hasPerm('edit', { noPending: true });
      }
      return _this.isDraft() &&
             EVENT_ALLOWED_STATES.edit.indexOf(_this.doc.state) !== -1 ?
        _this.hasPerm('edit', { noPending: true }) : $q.reject({ status: 403 });
    }

    function isSectionAllowedToPerformActions(currentSection, def, perm) {
      if (_.isUndefined(currentSection.def)) {
        return false;
      }

      // check if dependent sections perm allow the user to edit the section
      var dependentPerms = {};
      _.forEach(currentSection.def.dependentSectionsPerms || [], function(sectionPerm) {
        if (_.isUndefined(dependentPerms[sectionPerm.section])) {
          dependentPerms[sectionPerm.section] = [];
        }

        dependentPerms[sectionPerm.section].push(sectionPerm.permission);
      });

      return _.indexOf(dependentPerms[def._id], perm) > -1;
    }

    Event.prototype.canEditSection = function(item, def) {
      if (this.eventType.systemType === 'system') {
        return $q.reject({ status: 403 });
      }

      var prom = $q.when();
      if (!_.isUndefined(item.enc)) {
        prom = this.hasPerm('viewAnon');
      }

      var _this = this;
      return prom
        .then(function() {
          if (isSectionAllowedToPerformActions(_this.currentSection, def, 'canEdit')) {
            // if item.enc and not anon perm return reject
            return $q.when();
          }

          // otherwise only complete sections can be edited
          if (_this.doc.state !== 'complete') {
            return $q.reject();
          }

          // If user does not have a global permission, lets look at editables
          var editableRoles = def.editableRoles || [];
          if (def.editable) {
            editableRoles.push('system:author');
          }

          if (editableRoles.indexOf('system:author') !== -1) {
            if (item.filledBy === Auth.currentUser()) {
              return $q.when();
            }
          }

          var hasEditRole = $q.when(false);
          if (!_.isEmpty(editableRoles)) {
            hasEditRole = Security.hasOwnRoleFor(editableRoles, _this.doc.user)
              .then(function(result) { return result; })
              .catch(function() { return false; });
          }
          var hasEditCompletedPerm = _this.hasPerm('editCompleted')
            .then(function(result) { return result; })
            .catch(function() { return false; });

          return $q.all([hasEditRole, hasEditCompletedPerm])
            .then(function(result) {
              return _.some(result);
            });
        });
    };

    Event.prototype.canDeleteMultiSourceResponse = function(item, def) {
      if (this.eventType.systemType === 'system') {
        return $q.reject({ status: 403 });
      }

      if (!def.multiSource) {
        return $q.reject({ status: 403 });
      }

      var prom = $q.when();
      if (item.filledBy.startsWith('enc')) {
        prom = this.hasPerm('viewAnon');
      }

      var _this = this;
      return prom
        .then(function() {
          if (isSectionAllowedToPerformActions(_this.currentSection, def, 'canRemoveMS')) {
            return $q.when();
          }

          return _this.hasPerm('deleteMultiSourceResponse');
        });
    };

    Event.prototype.canEditBasicForm = function() {
      if (this.eventType.systemType === 'system') {
        return $q.reject({ status: 403 });
      }

      if (this.doc.state !== 'complete') {
        return $q.reject();
      }

      // The basic form can be editable if first section is editable
      if (this.allSections && this.allSections.length > 0) {
        var firstSection = this.allSections[0];
        if (firstSection.section.items && firstSection.section.items.length > 0) {
          var firstResponse = firstSection.section.items[0];
          return this.canEditSection(firstResponse, firstSection.def);
        }
      }

      // Otherwise fallback to default edit completed
      return this.hasPerm('editCompleted');
    };

    function canDelete() {
      // Any obsolete event can be deleted
      if (this.doc.state === 'obsolete') {
        return $q.when();
      }

      // If it is not allowed you have to be an admin
      if (EVENT_ALLOWED_STATES.delete.indexOf(this.doc.state) === -1) {
        return this.hasPerm('deleteCompleted', { noPending: true });
      }

      // If there are completed sections you have to be an admin
      if (this.completedSections.length > 0) {
        return this.hasPerm('deleteCompleted', { noPending: true });
      }

      // If it got that far you are free to delete
      return this.hasPerm('delete', { noPending: true });
    }

    function canClose() {
      if (EVENT_ALLOWED_STATES.close.indexOf(this.doc.state) === -1) {
        return $q.reject({ status: 403 });
      }

      if (this.isMine()) {
        if (!this.haveEnoughResponses()) {
          return this.hasPerm('forceClose', { noPending: true });
        }

        return $q.when(true);
      }

      return this.hasPerm('forceClose', { noPending: true });
    }

    function canCloseGoal() {
      if (this.doc.state !== EVENT_STATES.INPROGRESS.id) {
        return $q.reject({ status: 403 });
      }

      if (!this.isMine()) {
        return this.hasPerm('forceCloseGoal', { noPending: true });
      }

      return $q.when(true);
    }

    function canAddGoals() {
      if (this.doc.state !== EVENT_STATES.INPROGRESS.id) {
        return $q.reject({ status: 403 });
      }

      if (_.isUndefined(this.eventType)) {
        return $q.reject({ status: 403 });
      }

      var _this = this;
      return this.hasPerm('goals.manage')
        .catch(function() {
          return EventType.hasGoalCustomsSettingsRoles(
            'canAddCustomsInProgress',
            _this.eventType.sections,
            _this.currentSection._id,
            _this.doc.user
          );
        })
        .then(function(has) {
          if (!has) {
            if (!_this.isMine()) {
              return _this.hasPerm('goals.manage');
            }
            return $q.reject({ status: 403 });
          }
        });
    }

    function canChangeVisibility(newState) {
      if (!this.eventType.changableVisibility) {
        return $q.reject({ status: 403 });
      }

      if (EVENT_ALLOWED_STATES.changeVisibility.indexOf(this.doc.state) === -1) {
        return $q.reject({ status: 403 });
      }

      if (!this.isMine()) {
        return $q.reject({ status: 403 });
      }

      var visibility = this.doc.visibility || 'public';
      if (visibility === newState) {
        return $q.reject({ status: 403 });
      }

      return this.noPending();
    }

    Event.prototype.canChangeDraftVisibility = function() {
      if (!this.eventType.changableVisibility) {
        return false;
      }

      if (this.completedSections.length === 0) {
        return true;
      }

      return this.doc.state === EVENT_STATES.DRAFT.id && this.isMine();
    };

    function canReopen() {
      if (this.doc.state !== 'complete') {
        return $q.reject({ status: 403 });
      }

      var lastSec = _.last(_.filter(this.completedSections, function(sec) {
        return sec.state === 'complete';
      }));

      if (lastSec === undefined) {
        return $q.reject({ status: 403 });
      }

      var containsGaol = _.find(lastSec.def.fields, function(field) {
        return field.type === 'goal';
      });

      var perm;
      if (lastSec.def.multiSource) {
        perm = 'reopen';
      } else if (!_.isUndefined(containsGaol)) {
        perm = 'reopengoal';
      } else {
        return $q.reject({ status: 403 });
      }

      return this.hasPerm(perm);
    }

    Event.prototype.checkPermission = function(perm) {
      var _this = this;
      if (perm === undefined) {
        return $q.when(true);
      }

      if (perm === 'canView') {
        return !_this.isMine() &&
               !_this.isDraft() ? _this.hasPerm('view') : $q.reject({ status: 403 });
      } else if (perm === 'canViewOwn') {
        return _this.isMine() ||
               _this.isDraft() ? _this.hasPerm('view') : $q.reject({ status: 403 });
      } else if (perm === 'canViewAny') {
        return _this.hasPerm('view');
      } else if (perm === 'canEditAny') {
        return _this.isMine() ?
          canEdit.apply(_this, [{ own: true, edit: true }]) :
          canEdit.apply(_this, [{ own: false, edit: true }]);
      } else if (perm === 'canEditOwn') {
        return canEdit.apply(_this, [{ own: true, edit: true }]);
      } else if (perm === 'canEdit') {
        return canEdit.apply(_this, [{ own: false, edit: true }]);
      } else if (perm === 'canFillInOwn') {
        return canEdit.apply(_this, [{ own: true, edit: false }]);
      } else if (perm === 'canFillIn') {
        return canEdit.apply(_this, [{ own: false, edit: false }]);
      } else if (perm === 'canRePublish') {
        return EVENT_ALLOWED_STATES.rePublish.indexOf(_this.doc.state) !== -1 ?
          _this.noPending() : $q.reject({ status: 403 });
      } else if (perm === 'canRerequest') {
        return _this.canRemind();
      } else if (perm === 'canReOpen') {
        return canReopen.apply(this);
      } else if (perm === 'canAccept') {
        // return EVENT_ALLOWED_STATES.accept.indexOf(_this.doc.state) !== -1  &&
        // _this.isDraft() ? _this.hasPerm('edit') : $q.reject({status: 403});
        // Accepting is now disabled
        return $q.reject({ status: 403 });
      } else if (perm === 'canClose') {
        return canClose.apply(_this);
      } else if (perm === 'canCloseGoal') {
        return canCloseGoal.apply(_this);
      } else if (perm === 'canInvite') {
        return EVENT_ALLOWED_STATES.invite.indexOf(_this.doc.state) !== -1 &&
               _this.currentSection.def.multiSource &&
               _this.isMine() &&
               !_this.isDraft() ? _this.noPending() : $q.reject({ status: 403 });
      } else if (perm === 'canReject') {
        return EVENT_ALLOWED_STATES.reject.indexOf(_this.doc.state) !== -1 &&
               _this.isDraft() &&
               !_this.isMine() &&
               _this.completedSections.length > 0 ? _this.noPending() : $q.reject({ status: 403 });
      } else if (perm === 'canRetract') {
        return _this.canRetract();
      } else if (perm === 'canDelete') {
        return canDelete.apply(_this);
      } else if (perm === 'canCreate') {
        return _this.isNew() ? _this.hasPerm('new') : $q.reject({ status: 403 });
      } else if (perm === 'canAddGoals') {
        return canAddGoals.apply(_this);
      } else if (perm === 'canMakePrivate') {
        return canChangeVisibility.apply(_this, ['private']);
      } else if (perm === 'canMakePublic') {
        return canChangeVisibility.apply(_this, ['public']);
      }

      return $q.reject({ status: 403 });
    };

    Event.prototype.checkState = function(states) {
      if (states.indexOf($state.current.name) < 0) {
        return $q.reject({ status: 403 });
      }

      return $q.when();
    };

    /** Action functions */
    Event.prototype.remove = function() {
      var _this = this;
      var def = $q.defer();
      Utils.swal({
        title: 'Are you sure you want to remove this event?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          var promise;
          var isLocal;
          promise = _this.checkLocal()
            .then(function(isl) {
              isLocal = isl;
            })
            .then(function() {
              if (
                isLocal &&
                ((EVENT_ALLOWED_STATES.delete.indexOf(_this.doc.state) > -1 &&
                _this.completedSections.length === 0) || _this.doc.state === 'obsolete')
              ) {
                promise = Events.remove(_this.doc._id)
                  .catch(function(err) {
                    // Ignore if event does not exist
                    if (err && err.status === 404) {
                      return;
                    }

                    return $q.reject(err);
                  });
              } else {
                promise = Events.removeCompleted(_this.doc);
              }

              return promise;
            });

          promise
            .then(function() {
              $rootScope.$broadcast('ItemReloadRequested', {
                type: 'event',
                id: _this.doc._id,
                data: {
                  event: _this.doc._id
                }
              });
              $rootScope.$broadcast('KZItemRemoved', { item: _this });
              def.resolve();
            })
            .catch(function(error) {
              var title = 'Unexpected error';
              if (error && !_.isEmpty(error.data)) {
                title = error.data.message || error;
              }

              if (error && !_.isEmpty(error.reason)) {
                title = error.reason || error;
              }

              Utils.swal({
                title: title,
                type: 'error'
              });
              def.reject();
            });
        } else {
          def.reject({ status: 510, message: 'User cancelled' });
        }
      });

      return def.promise;
    };

    Event.prototype.rerequest = function() {
      return this.doAction(
        'section_remind',
        { event_id: this.doc._id },
        { title: 'Are you sure you want to send a reminder?' },
        { text: 'Reminder sent' }
      );
    };

    Event.prototype.reOpen = function() {
      var _this = this;
      return this.doAction(
        'section_reopen',
        { event_id: this.doc._id },
        { title: 'Are you sure you want to re-open this event?' }
      ).then(function() {
        Goals.invalidateCachedEvent(_this.doc._id);
      });
    };

    Event.prototype.saveResponse = function(sectionId, response) {
      var actionName = 'response_edit',
          data = {
            event_id: this.doc._id,
            event_rev: this.doc._rev,
            section_id: sectionId,
            response: response,
            idx: _.findIndex(
              this.doc.sections[sectionId].items,
              { publishedAt: response.publishedAt }
            )
          };

      return this.doAction(
        actionName,
        data,
        { skip: true },
        {},
        { noReset: true, forceApi: true }
      );
    };

    Event.prototype.removeResponse = function(sectionId, response) {
      var args = {
        event_id: this.doc._id,
        section_id: sectionId
      };

      if (!_.isUndefined(response.eventSectionId)) {
        args.eventSectionId = response.eventSectionId;
      } else {
        args.response = response;
      }

      return this.doAction(
        'ms_response_delete',
        args,
        { skip: true },
        { text: 'Response successfully removed from this event' },
        { noReset: true, forceApi: true }
      );
    };

    Event.prototype.saveHeader = function(_header) {
      return this.doAction(
        'event_edit',
        {
          event_id: this.doc._id,
          event_rev: this.doc._rev,
          event_doc: this.doc
        },
        { skip: true },
        {},
        { noReset: true, forceApi: true }
      );
    };

    Event.prototype.rePublish = function() {
      var _this = this;
      Utils.swal({
        title: 'Are you sure you want to re-send publish?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (!isConfirm) {
          return;
        }

        Events.publish(_this)
          .then(function() {
            _this.reload();
          })
          .catch(Utils.showError);
      });
    };

    Event.prototype.reject = function() {
      var _this = this;
      Utils.swal({
        title: 'Are you sure you want to reject this event?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (!isConfirm) {
          return;
        }

        Events.reject(_this)
          .then(function() {
            _this.reload();
          })
          .catch(Utils.showError);
      });
    };

    Event.prototype.retract = function() {
      return this.doAction(
        'section_retract',
        { event_id: this.doc._id },
        {
          title: 'Are you sure you want to retract this event?',
          text: 'By retracting this event the invited user\'s invitation will be cancelled.'
        }
      );
    };

    Event.prototype.retractResponse = function(eventSectionId) {
      return this.doAction(
        'response_retract',
        { event_section_id: eventSectionId },
        {
          title: 'Are you sure you want to retract this response?',
          text: 'By retracting this response the invited user\'s invitation will be cancelled.'
        }
      );
    };

    Event.prototype.close = function(invites) {
      var def = $q.defer();
      var _this = this;
      this.getPendingSectionsCounts(this.currentSection._id)
        .then(function(response) {
          var title;
          var type;
          if (response !== undefined && response.pending > 0) {
            title = 'There are still responses waiting for approval. ' +
                    'By closing this section the responses will not be included in the results. ' +
                    'Do you wish to continue?';
            type = 'warning';
          } else {
            title = 'By closing this section you will not be able to receive any ' +
                    'further responses. Do you wish to continue?';
            type = 'info';
          }

          Utils.swal({
            title: title,
            type: type,
            showCancelButton: true,
            confirmButtonText: 'OK'
          },
          function(isConfirm) {
            if (!isConfirm) {
              def.reject();
              return;
            }

            return Events.close(_this, invites)
              .then(function() {
                def.resolve();
              })
              .catch(Utils.showError);
          });
        });

      return def.promise;
    };

    Event.prototype.closeGoal = function(invites) {
      var _this = this;
      var prom = $q.when('By finishing your goals you will not be able to change them anymore. ' +
                         'Do you wish to continue?');
      if (!this.isMine()) {
        prom = User.getFullName(this.doc.user)
          .then(function(fullName) {
            return 'You are now closing this goal set for ' + fullName +
                   '. They will no longer be able to work on them.';
          });
      }

      return prom
        .then(function(title) {
          var def = $q.defer();

          Utils.swal({
            title: title,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'OK'
          },
          function(isConfirm) {
            if (!isConfirm) {
              return;
            }

            Events.closeGoal(_this, invites)
              .then(function() {
                def.resolve();
              })
              .catch(Utils.showError);
          });

          return def.promise;
        })
        .then(function() {
          Notify.success('The goal set has been closed successfully!');
        });
    };

    Event.prototype.makePrivate = function() {
      return this.doAction(
        'event_change_visibility',
        { event_id: this.doc._id, visibility: 'private' },
        { title: 'Are you sure you want to make this event private?' }
      );
    };

    Event.prototype.makePublic = function() {
      return this.doAction(
        'event_change_visibility',
        { event_id: this.doc._id, visibility: 'public' },
        { title: 'Are you sure you want to make this event visible to other users?' }
      );
    };

    Event.prototype.makeObsolete = function() {
      var _this = this;
      var def = $q.defer();
      Utils.swal({
        title: 'Are you sure you want to mark this event as obsolete?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (!isConfirm) {
          return;
        }

        Events.makeObsolete(_this)
          .then(function() {
            console.log('done');
            def.resolve();
          })
          .catch(function(err) {
            Utils.showError(err);
            def.reject(err);
          });
      });

      return def.promise;
    };

    Event.prototype.invite = function(invites) {
      return this.doAction(
        'section_invite',
        { event_id: this.doc._id, section_id: this.currentSection._id, invitation: invites },
        { skip: true },
        { skip: true }
      )
      .then(function(data) {
        var style = '',
            msg = '';
        if (_.isEmpty(data.pending) && _.isEmpty(data.merged) && _.isEmpty(data.unknown)) {
          style = 'success';
          msg = 'Invitations successfully updated!'; // waiting for confirmation
        } else {
          style = 'warning';
          // waiting for confirmation
          msg = 'Some invitations could not be sent because these users have already responded!';
        }
        Notify[style](msg, 'Invite or remind', 10 * 1000);
      });
    };

    Event.prototype.loadEventType = function() {
      // console.log(Date(), 'Starting event type loading');
      var _this = this;
      if (this.eventType && this.eventType._id) {
        return $q.when(this.eventType);
      }

      if (this.doc.eventType) {
        // debugger
        return EventTypes.find(_this.doc.eventType,
          { cache: this.options.noCache ? 'revalidate' : 'cached' }
        )
        // return EventTypes.getGroupByItemId(_this.doc.eventType,
        //                        { cache: this.options.noCache ? 'revalidate' : 'cached' })
          .then(function(doc) {
            _this.eventType = doc;
            // _this.eventTypeObj = new EventTypeLight({ doc: doc });
            _this.eventTypeVersion = _this.eventType.version;
            return doc;
          });
      }

      return $q.when({});
    };

    Event.prototype.loadSections = function() {
      var _this = this;

      if (!(_this.eventType)) {
        $log.warn('No event type. Can\'t load sections');
        return;
      }

      if (_this.eventType.sections === undefined) {
        return;
      }

      // If there are allSections, we are already loaded
      if (_this.allSections.length) {
        return $q.when();
      }

      _this.eventType.sections.forEach(function(sectionDef) {
        var section = null;
        if (_this.doc.sections) {
          section = _this.doc.sections[sectionDef._id];

          _this.allSections.push({
            state: section ? section.state || 'missing' : 'missing',
            section: section,
            def: sectionDef,
            _id: sectionDef._id
          });
        }
      });

      _this.completedSections = _this.allSections.filter(function(value) {
        return ['complete', 'skipped'].indexOf(value.state) !== -1;
      });

      var potentiallyPendingSections = _this.allSections.filter(function(value) {
        return ['complete', 'skipped'].indexOf(value.state) === -1;
      });

      _this.pendingSections = _.filter(potentiallyPendingSections, function(section) {
        return _this.isValidSection(section);
      });

      // If state is inProgress the last completed is current and first draft is next
      if (_this.doc.state === EVENT_STATES.INPROGRESS.id) {
        _.assign(_this.currentSection, _.last(_this.completedSections));
        if (_this.pendingSections.length > 0) {
          _.assign(_this.nextSection, _this.pendingSections[0]);
        }

      // Otherwise the first draft is current and second draft is next
      } else if (_this.pendingSections.length > 0) {
        _.assign(_this.currentSection, _.head(_this.pendingSections));
        if (_this.pendingSections.length > 1) {
          _.assign(_this.nextSection, _this.pendingSections[1]);
        }
      }
    };

    var _evalSkipCondition = function(exp) {
      if (exp.type === 'sectionField') {
        var sections = this.doc.sections || {};

        if (this.doc.skipConditionData) {
          sections = this.doc.skipConditionData;
        }

        var sec = sections[exp.section] || {};
        return _.some(sec.items || [], function(item) {
          var fields = item.fields || {};
          var val = fields[exp.field];
          if (Utils.isBlank(val)) {
            val = [];
          } else if (!_.isArray(val)) {
            val = [val];
          }

          var expVal = exp.values;
          if (Utils.isBlank(expVal)) {
            expVal = [];
          } else if (!_.isArray(expVal)) {
            expVal = [expVal];
          }

          return _.intersection(val, expVal).length > 0;
        });
      }

      return false;
    };

    Event.prototype.isValidSection = function(section) {
      var skipCondition = section.def.skipCondition;

      if (_.isEmpty(skipCondition)) {
        return true;
      }

      return !Utils.evalComplex(skipCondition, _evalSkipCondition.bind(this));
    };

    Event.prototype.loadMeta = function() {
      this.meta = this.doc;
    };

    Event.prototype.concatSection = function(sectionId) {
      if (this._concated && this._concated[sectionId] !== undefined) {
        return this._concated[sectionId];
      }

      var result = angular.copy(this.doc.sections[sectionId]);
      var concat = {};
      result.items.forEach(function(item) {
        _.forOwn(item.fields, function(value, key) {
          concat[key] = concat[key] || [];
          concat[key].push(value);
        });
      });

      _.forOwn(concat, function(value, key) {
        concat[key] = _.shuffle(value);
      });

      if (_.isEmpty(concat)) {
        result.items = [];
        return result;
      }

      result.items = [{ fields: concat }];
      this._concated = this._contacted || {};
      this._concated[sectionId] = result;
      return result;
    };

    Event.prototype.getFieldValue = function(fieldId, sectionId) {
      var section = this.concatSection(sectionId);
      if (section.items.length === 0) {
        return [];
      }

      return section.items[0].fields[fieldId] || [];
    };

    Event.prototype.loadAllSections = function() {
      var _this = this;
      this.allSections.forEach(function(section) {
        _this.setupSection.call(_this, section);
      });
    };

    Event.prototype.getValueId = function(defField) {
      var valueId;
      if (defField.type === 'role') {
        valueId = defField.role;
      } else if (defField.type === 'relation') {
        valueId = defField.relation;
      } else if (defField.type === 'blueprint') {
        valueId = defField.blueprint;
      } else {
        valueId = defField._id;
      }

      return valueId;
    };

    Event.prototype.validateCurrentSection = function() {
      return this.validateSection(this.currentSection);
    };

    Event.prototype.validateSection = function(sectionPair) {
      var _this = this;
      if (this.doc.eventType !== this.eventType._id) {
        return $q.reject({ status: 412, message: 'Event type does not match' });
      }

      var def = sectionPair.def;
      var fields = sectionPair.section.items[0].fields;

      var errors = {};

      var getFieldDef = function(fieldId) {
        return _.find(def.fields, { _id: fieldId });
      };

      var isRequired = function(defField, options) {
        options = options || {};

        if (!defField.isRequired && !options.alwaysRequired) {
          return false;
        }

        var restricted = defField.restricted || {};
        if (_.isEmpty(restricted)) {
          return true;
        }

        var resFieldDef = getFieldDef(restricted.by);
        if (resFieldDef === undefined) {
          return false;
        }

        var byValue = fields[_this.getValueId(resFieldDef)];

        if (restricted.by && restricted.to && restricted.to !== 'any') {
          if (_.isEmpty(byValue)) {
            return false;
          }

          if (_.isArray(byValue)) {
            return byValue.indexOf(restricted.to) !== -1;
          }

          return byValue === restricted.to;
        }

        if (restricted.by) {
          return !_.isEmpty(byValue);
        }
      };

      def.fields.forEach(function(item) {
        if (!isRequired(item, { alwaysRequired: item.type === 'report' })) {
          return;
        }

        var value = fields[_this.getValueId(item)];
        if (item.type === 'report' && _.isEmpty((value || {}).reportResult)) {
          errors[_this.getValueId(item)] = { name: 'report' };
        } else if (
          (_.isArray(value) || _.isObject(value) || _.isString(value)) && _.isEmpty(value)
        ) {
          errors[_this.getValueId(item)] = item;
        } else if (value === undefined) {
          errors[_this.getValueId(item)] = item;
        }
      });

      if (!_.isEmpty(errors)) {
        return $q.reject({
          status: 412,
          message: 'Please fill in the required fields',
          errors: errors
        });
      }

      return $q.when();
    };

    function getSectionDefaults() {
      return {
        items: [{
          state: 'draft',
          filledBy: Auth.currentUser(),
          fields: {}
        }],
        state: 'draft'
      };
    }

    Event.prototype.setupSection = function(sectionPair) {
      // If section is null we need to assign a new doc to the event
      if (sectionPair.section === null) {
        var section = getSectionDefaults();
        this.doc.sections = this.doc.sections || {};
        this.doc.sections[sectionPair.def._id] = section;
        sectionPair.section = section;
      }
    };


    Event.prototype.addExtra = function(extra) {
      var _this = this;
      extra = _.assignIn({}, EventExtras.getInitial(), {
        event: this.doc._id,
        user: this.doc.user
      }, extra);

      extra.date.modified = Utils.now();

      return Events.addExtra(this, extra)
        .then(function(data) {
          _this.extra = undefined;
          return $q.all([
            _this.collectBlueprints(true),
            _this.collectDocuments(true),
            data
          ]);
        })
        .then(function() {
          $rootScope.$broadcast('KZEventInvalidated', { id: _this.doc._id });
          AsyncTasks.startUpdator();
          return extra;
        });
    };

    Event.prototype.deleteExtra = function(extra) {
      var _this = this;
      return Events.deleteExtra(this, extra)
        .then(function() {
          return $q.all([
            _this.collectBlueprints(),
            _this.collectDocuments()
          ]);
        })
        .then(function(res) {
          AsyncTasks.startUpdator();
          $rootScope.$broadcast('KZEventInvalidated', { id: _this.doc._id });
          return res;
        });
    };

    Event.prototype.getExtra = function(options) {
      var _this = this;
      if (!_.isUndefined(this._extra)) {
        return $q.when(this._extra);
      }

      if (!_.isUndefined(this.doc.extras)) {
        _this._extra = this.doc.extras;
        return $q.when(this._extra);
      }

      return Events.getExtra(this, options)
        .then(function(data) {
          _this._extra = data;
          return data;
        });
    };

    // any better idea?
    Event.prototype.getBorderClass = function() {
      if (_.isUndefined(this.doc)) {
        return '';
      }

      if (this.eventType.systemId === 'log') {
        return 'log-event';
      }

      return 'progress-border-' + EVENT_STATES[this.doc.state.toUpperCase()].borderStyle;
    };


    Event.prototype.prepareForFulltext = function() {
      var _this = this;
      var result = {
        id: this.doc._id
      };

      var collectBlueprint = function() {
        return $q.all(_.map(_this.categories, function(category) {
          return Blueprints.findByCategoryId(category);
        }));
      };

      var collectDocument = function() {
        return $q.all(_.map(_this.documents, function(docId) {
          return Documents.find(docId);
        }));
      };

      var findLabel = function(value, field) {
        var _find = function(value, obj) {
          if (obj._id === value) {
            return obj.name;
          }

          if (obj.categories) {
            var label;
            for (var idx = 0; idx < obj.categories.length; idx++) {
              label = _find(value, obj.categories[idx]);
              if (label !== undefined) {
                return label;
              }
            }
          }

          return;
        };

        return _find(value, field.def);
      };

      var extractFromField = function(field) {
        var res;
        if (_.isEmpty(field.value)) {
          return;
        }

        switch (field.def.type) {
          case 'customField':

            switch (field.def.blueprintType) {
              case 'string':
              case 'text':
                res = field.value;
                break;
              case 'discrete':
              case 'discrete_multiple':
              case 'tree':
                if (_.isArray(field.value)) {
                  res = field.value.map(function(item) {
                    return findLabel(item, field);
                  })
                    .filter(function(item) {
                      return !_.isEmpty(item);
                    })
                    .join(', ');
                } else {
                  res = findLabel(field.value, field);
                }
                break;

              default:
                // $log.warn('Unknown custom fiel', field.def.blueprintType);
            }
            break;

          default:
            // $log.warn('Unknown custom fiel', field.def.type);
        }

        return { label: field.def.name, value: res };
      };

      return _this.init()
        .then(function() {
          var collect = [];
          collect.push(
            _this.doc.user !== Auth.currentUser() ? UsersStub.find(_this.doc.user) : $q.when()
          );
          collect.push(_this.getExtra());
          collect.push(collectBlueprint());
          collect.push(collectDocument());
          return $q.all(collect);
        })
        .then(function(data) {
          var user = data[0];
          var extra = data[1];
          var blueprints = data[2];
          var documents = data[3];

          var progress = _this.doc.progress && (_this.doc.progress.comment || '');
          result.title = _this.doc._id + ' ' + _this.doc.title + ' ' + _this.eventType.name;
          result.description = _this.doc.description + ' ' + progress;
          result.user = user && (user.firstName || '') + ' ' +
                        (user.lastName || '') + (user.email || '');

          result.field = [];
          var fields = _this.fieldsWithDef();
          var fieldData;
          _.forEach(fields, function(field) {
            fieldData = extractFromField(field);
            if (fieldData !== undefined) {
              result.field.push(fieldData);
            }
          });

          result.extra = _.map(extra, 'comment');
          result.blueprints = _.map(blueprints, 'value');
          result.documents = _.map(documents, function(item) {
            return item.name + ' ' + item.documentType;
          });

          return result;
        });
    };

    Event.prototype.getSource = function() {
      if (this.source) {
        return this.source;
      }

      var result = {};
      if (this.doc.blueprints !== undefined) {
        result.blueprints = _.map(this.doc.blueprints, function(item) {
          return item.text_value;
        });
      }

      if (this.doc.documents !== undefined) {
        result.documents = _.map(this.doc.documents, function(item) {
          return item.text_value;
        });
      }

      if (this.doc.flat_fields !== undefined) {
        result.field = _.map(this.doc.flat_fields, function(item) {
          return { label: item.label, value: item.text_value };
        });
      }

      result.extra = this.doc.extra;

      return result;
    };

    Event.prototype.loadQueue = function(options) {
      options = options || {};
      var _this = this;
      if (!options.force && this._queue !== undefined) {
        return $q.when(this._queue);
      }

      this._queue = Queue.findByRelated(this.doc._id)
        .then(function(data) {
          if (data.length > 0) {
            _this._queue = data[0].doc;
            return data[0].doc;
          }
        })
        .catch(function(error) {
          console.log(error);
        });

      return this._queue;
    };

    Event.prototype.getPendingSections = function(sectionId) {
      return Events.getPendingSections(this.doc._id, sectionId);
    };

    Event.prototype.getPendingSectionsCounts = function(sectionId) {
      return Events.getPendingSections(this.doc._id, sectionId, { onlyCounts: true });
    };

    Event.prototype.getSectionsMetaData = function(sectionId) {
      return Events.getSectionsMetaData(this.doc._id, sectionId);
    };

    Event.prototype.getSectionTokens = function(sectionId) {
      return Events.getSectionTokens(this.doc._id, sectionId);
    };

    Event.prototype.getDateFor = function(action) {
      if (action === 'startDate' || action === 'endDate') {
        return this.doc[action];
      }

      if (action === 'createdDate') {
        return this.doc.date.added;
      }

      var dates = this.doc.dates || [];
      var filtered;
      if (action === 'any') {
        filtered = dates;
      } else {
        filtered = _.filter(dates, function(item) {
          return item.action === action;
        });
      }

      if (filtered.length === 0) {
        return '';
      }

      return _.last(filtered).date;
    };

    Event.prototype.getLinkedGoals = function(sectionId, fieldId) {
      var linkedGoals = [];
      _.forEach(this.doc.sections[sectionId].items, function(item) {
        if (_.has(item.fields, fieldId)) {
          linkedGoals = item.fields[fieldId].existingGoalsLinked || [];
          return;
        }
      });

      return linkedGoals;
    };

    // this should be more general!
    Event.prototype.doAction = function(action, actionData, swalData, successData, options) {
      options = options || {};
      var _this = this;
      var def = $q.defer();
      var swal = _.assignIn(
        {},
        {
          title: 'Are you sure you want to continue?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'OK'
        },
        swalData);

      var success = _.assignIn(
        {},
        {
          title: 'Confirmation',
          text: 'Action has been successfully applied'
        },
        successData
      );

      var process = function() {
        Events.doAction(action, actionData, _this.doc._id, options)
          .then(function(data) {
            if (!success.skip) {
              Notify.success(success.text, success.title);
            }
            return $q.all([$q.when(data), _this.reload(undefined, options)]);
          })
          .then(function(result) {
            $rootScope.$broadcast('ResetChangesTimeout');
            $rootScope.$broadcast('ItemReloadRequested', {
              type: 'event',
              id: _this.doc._id,
              data: {
                event: _this.doc._id
              }
            });
            AsyncTasks.startUpdator();
            def.resolve(result[0]);
          })
          .catch(function(error) {
            Utils.showError(error);
            return def.reject();
          });
      };

      if (swal.skip) {
        process();
      } else {
        Utils.swal(
          swal,
          function(isConfirm) {
            if (!isConfirm) {
              def.reject();
              return;
            }

            process();
          });
      }
      return def.promise;
    };
    Event.prototype.getFilledBy = function(response) {
      // TODO - it could be encrypted
      return response && response.filledBy;
    };

    /** Permissions */
    Event.prototype.canRemind = function() {
      var _this = this;
      if (EVENT_ALLOWED_STATES.reRequest.indexOf(_this.doc.state) === -1) {
        return $q.reject({ status: 403 });
      }

      if (!_this.isMine()) {
        return $q.reject({ status: 403 });
      }

      // If there is no action about pending section we do not need to query
      // server
      var actions = _.map(_this.doc.dates || [], 'action');
      if (actions.indexOf('pending_section_added') === -1) {
        return _this.noPending();
      }

      return _this.noPending()
        .then(function() {
          return _this.getPendingSectionsCounts(_this.currentSection._id);
        })
        .then(function(res) {
          console.log('Found pending', _this.doc._id, res.pending);
          if (res && res.pending) {
            return $q.reject({ status: 403 });
          }
        });
    };

    Event.prototype.canRetract = function() {
      if (EVENT_ALLOWED_STATES.retract.indexOf(this.doc.state) === -1) {
        return $q.reject({ status: 403 });
      }

      if (!this.isMine()) {
        return $q.reject({ status: 403 });
      }

      var last = _.last(_.filter(this.completedSections, { state: 'complete' }));
      if (last === undefined) {
        return $q.reject({ status: 403 });
      }

      if (!last.def.multiSource && !this.containsGoalForSection(last.def)) {
        // If last section can be retracted only if it is owners
        var filledBy = this.getFilledBy(last.section.items[0]);
        if (filledBy !== this.doc.user) {
          return $q.reject({ status: 403 });
        }
      }

      // // check that the actor is the event owner
      if (Auth.currentUser() !== this.doc.user) {
        return $q.reject({ status: 403 });
      }

      return $q.when();
    };

    Event.prototype.getResponsibles = function(filledBy, search) {
      var options = { state: 'active', roles: filledBy, rolesAppliesTo: this.doc.user };
      if (search) {
        options.autocomplete_fullname = search;
      }

      return UsersStub.findAll(options)
        .then(function(data) {
          return data.filter(function(user) {
            if (filledBy.indexOf('system:timeline-owner') !== -1) {
              return true;
            }

            return (user.username !== Auth.currentUser());
          }).map(function(user) {
            return {
              _id: user.username,
              key: user.username,
              email: user.email,
              fullname: _.trim(
                (user.firstName || '') + ' ' +
                (user.lastName || '')),
              name: _.trim(
                (user.firstName || '') + ' ' +
                (user.lastName || '') + ' <' +
                (user.email || '') + '>'
              ) || user.username
            };
          });
        });
    };

    Event.prototype.getProgress = function() {
      var states = [];
      var _this = this;
      var currentSection = this.currentSection;
      _.forEach(this.allSections, function(section) {
        var state;
        var substate;
        if (section.state === 'complete') {
          if (section._id === currentSection._id && _this.doc.state === 'inProgress') {
            state = 'pending';
            substate = 'inprogress';
          } else {
            state = 'complete';
            substate = 'complete';
          }
        } else if (section.state === 'skipped') {
          state = 'skipped';
          substate = 'skipped';
        } else if (section._id === currentSection._id) {
          if (section.def.multiSource) {
            state = 'pending';
            substate = 'awaiting';
          } else {
            state = 'draft';
            substate = 'draft';
          }
        } else {
          state = 'missing';
          substate = 'missing';
        }
        states.push({ state: state, substate: substate });
      });
      return states;
    };

    return Event;
  }

  EventFactory.$inject = [
    '$q',
    '$log',
    '$state',
    '$rootScope',
    'EventsService',
    'EventTypeFactory',
    'EventTypesService',
    'EventExtrasService',
    'DocumentsService',
    'UserFactory',
    'UsersStubService',
    'BlueprintsService',
    'GoalsService',
    'AuthService',
    'UtilsService',
    'SecurityService',
    'QueueService',
    '$uibModal',
    'NotifyService',
    'NetworkService',
    'AsyncTasksService',
    'EVENT_STATES',
    'EVENT_ALLOWED_STATES'
  ];

  angular.module('component.events')
    .factory('EventFactory', EventFactory);
})();
