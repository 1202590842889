(function() {
  'use strict';

  function FAQsController($state, Profile, FAQs, FAQSearch, List, Security, Utils) {
    var ctrl = this;
    ctrl.options = {};

    Utils.setPageTitle('Frequently Asked Questions');

    ctrl.searchModel = Profile.getListPreferences('faqs').current;

    ctrl.list = new List({ search: FAQSearch.search, model: ctrl.searchModel, idField: 'doc._id' });

    FAQs.findAll({ cached: false })
      .then(function(data) {
        ctrl.list.doLoadItems(data);
      });

    ctrl.actions = [
      {
        label: 'New FAQ',
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.faqs.new'); })(),
        showCondition: Security.hasPermission.bind(this, 'faqs.edit'),
        klass: 'btn-success'
      }
    ];
  }

  FAQsController.$inject = [
    '$state',
    'ProfileService',
    'FAQsService',
    'FAQSearch',
    'ListFactory',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.faqs')
    .controller('FAQsController', FAQsController);
})();
