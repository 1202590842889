(function() {
  'use strict';

  function Slot($q, $log, $rootScope, $stateParams, Notify, Profile, Documents, DocumentFactory) {
    return {
      restrict: 'A',
      templateUrl: 'app/components/documents/cards/documentbulk.card.html',
      link: function(scope) {
        var prefs = Profile.getPreferences();
        if (prefs.documentBulk) {
          scope.model = prefs.documentBulk.selected;
        }

        function _move(item) {
          var folderId = $stateParams.folderId;
          return Documents.find(item)
            .then(function(doc) {
              var docum = new DocumentFactory(doc);
              docum.init();
              return docum.moveTo(folderId);
            });
        }

        scope.move = function(item) {
          return _move(item)
            .then(function() {
              Notify.success('The document has been moved');
              // Reload the list
              scope.remove(item);
              $rootScope.$broadcast('KZApplySearch', { fullReload: true });
            })
            .catch(function(error) {
              $log.warn(error && error.message);
              Notify.error('An error appeared while moving');
            });
        };

        scope.moveAll = function() {
          return $q.all(_.map(scope.model, _move))
            .then(function() {
              Notify.success('The document has been moved');
              scope.removeAll();
              $rootScope.$broadcast('KZApplySearch', { fullReload: true });
            })
            .catch(function(error) {
              $log.warn(error && error.message);
              Notify.error('An error appeared while moving');
            });
        };

        scope.remove = function(item) {
          _.remove(scope.model, function(val) {
            return val === item;
          });
          $rootScope.$broadcast('KZPreferencesChange');
        };

        scope.removeAll = function() {
          _.remove(scope.model, function() {
            return true;
          });
          $rootScope.$broadcast('KZPreferencesChange');
        };
      }
    };
  }

  Slot.$inject = [
    '$q',
    '$log',
    '$rootScope',
    '$stateParams',
    'NotifyService',
    'ProfileService',
    'DocumentsService',
    'DocumentFactory'
  ];

  angular.module('component.documents')
    .directive('sidebarDocumentBulk', Slot);
})();
