(function() {
  'use strict';

  function RemoteProfileBarController($scope, $window, $state, $transitions, Organisations, Users) {
    var ctrl = this;

    ctrl.remoteNavbarCollapsed = $window.innerWidth <= 992;

    ctrl.toggleRemoteNavbar = function() {
      ctrl.remoteNavbarCollapsed = !ctrl.remoteNavbarCollapsed;
    };

    var MENU = [
      {
        title: 'Summary',
        state: 'dashboard.summary',
        stateOptions: {
          user: Users.remoteUser
        }
      },
      {
        title: 'Timeline',
        state: 'epf.users.events',
        stateOptions: {
          user: Users.remoteUser
        },
        allowedState: 'active'
      },
      {
        title: 'Goals',
        state: 'epf.users.goals',
        stateOptions: {
          user: Users.remoteUser
        },
        allowedState: 'active'
      },
      {
        title: 'Documents',
        state: 'epf.users.documents',
        stateOptions: {
          user: Users.remoteUser
        },
        allowedState: 'active'
      }
    ];

    var getLinks = function() {
      if (_.isEmpty(ctrl.remoteProfile)) {
        ctrl.menu = [];
        return;
      }

      ctrl.menu = angular.copy(MENU).filter(function(item) {
        return item.allowedState === undefined || ctrl.remoteProfile.state === item.allowedState;
      });

      _.forEach(ctrl.menu, function(item) {
        item.stateOptions.user = ctrl.remoteProfile.username;
      });

      return Organisations.getListOptions()
        .then(function(options) {
          var timeline = _.find(ctrl.menu, { title: 'Timeline' });
          var goals = _.find(ctrl.menu, { title: 'Goals' });

          if (!_.isUndefined(timeline)) {
            if ((options['epf.events.index'] || {}).sections === undefined) {
              _.assignIn(timeline, {
                state: 'epf.users.events',
                stateOptions: {
                  user: ctrl.remoteProfile.username
                }
              });

              delete timeline.links;
              return;
            }

            timeline.links = _.chain(options['epf.events.index'].sections)
              .map(function(filter) {
                if (filter.allowedRoles && filter.allowedRoles.length > 0) {
                  if (_.intersection(ctrl.remoteProfile.roles, filter.allowedRoles).length === 0) {
                    return undefined;
                  }
                }
                return {
                  title: filter.name,
                  state: 'epf.users.events',
                  stateOptions: {
                    user: ctrl.remoteProfile.username,
                    filterId: filter.id
                  }
                };
              })
              .filter(function(item) {
                return item !== undefined;
              })
              .value();

            delete timeline.state;
            delete timeline.stateOptions;
          }

          if (!_.isUndefined(goals)) {
            if ((options['epf.goals.index'] || {}).sections === undefined) {
              _.assignIn(goals, {
                state: 'epf.users.goals',
                stateOptions: {
                  user: ctrl.remoteProfile.username
                }
              });

              delete goals.links;
              return;
            }

            goals.links = _.chain(options['epf.goals.index'].sections)
              .map(function(filter) {
                if (filter.allowedRoles && filter.allowedRoles.length > 0) {
                  if (_.intersection(ctrl.remoteProfile.roles, filter.allowedRoles).length === 0) {
                    return undefined;
                  }
                }
                return {
                  title: filter.name,
                  state: 'epf.users.goals',
                  stateOptions: {
                    user: ctrl.remoteProfile.username,
                    filterId: filter.id
                  }
                };
              })
              .filter(function(item) {
                return item !== undefined;
              })
              .value();


            delete goals.state;
            delete goals.stateOptions;
          }
        });
    };

    function updateUser(value) {
      ctrl.remoteUser = value;
      if (!value) {
        delete ctrl.remoteProfile;
        return;
      }

      Users.find(value, { forceRemote: true })
        .then(function(doc) {
          // This  is a hack, that if you query remote user via API
          // it always sets the cached profile on it so that you don't have to query/compute it
          // because it has to be done via api as you don't have direct access to their timeline
          if (_.isUndefined(doc._tmp_cache)) {
            return;
          }

          // Set the remote profile data
          ctrl.remoteProfile = _.assign(
            {},
            doc,
            {
              roles: _.filter(doc.roles || [], function(role) {
                return !_.startsWith(role, 'system:');
              })
            },
            {
              relations: doc._tmp_cache.profile.relations,
              dependent: doc._tmp_cache.profile.user_dependent
            }
          );

          getLinks();
        });
    }

    $scope.$on('KZRemoteUserChanged', function() {
      updateUser(Users.remoteUser);
    });

    $scope.$watch(function() {
      return Users.remoteUser;
    }, function(value) {
      updateUser(value);
    });

    var setProfileClass = function() {
      $scope.profileViewActive = $state.includes('dashboard.profile.view');
    };

    setProfileClass();
    // $scope.$on('$stateChangeSuccess', function() {
    $transitions.onSuccess({}, function() {
      setProfileClass();
    });
  }

  RemoteProfileBarController.$inject = [
    '$scope',
    '$window',
    '$state',
    '$transitions',
    'OrganisationsService',
    'UsersService'
  ];

  function RemoteProfileBar(Users) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'app/layout/header/remote-profile-bar.html',
      controller: RemoteProfileBarController,
      controllerAs: 'remoteProfileBarCtrl',
      link: function($scope) {
        var bodyEl = angular.element(document.getElementsByTagName('body')[0]);
        var remoteProfileClass = 'remote-profile';

        $scope.$watch(function() {
          return Users.remoteUser;
        }, function(value) {
          if (_.isEmpty(value)) {
            bodyEl.removeClass(remoteProfileClass);
          } else {
            bodyEl.addClass(remoteProfileClass);
          }
        });
      }
    };
  }

  RemoteProfileBar.$inject = ['UsersService'];

  angular.module('kzHeaderCard')
    .directive('kzRemoteProfileBar', RemoteProfileBar);
})();
