(function() {
  'use strict';

  function AttachedDocumentsController($scope, $timeout, $window, Documents, Utils) {
    var ctrl = this;

    ctrl.updateVisibility = function(id, value) {
      if (value) {
        Documents.moveToSharedDB(id);
      } else {
        Documents.moveToPrivateDB(id);
      }
    };

    ctrl.download = function(doc) {
      var toutPromise = $timeout(function() {
        $scope.$broadcast('KZDocumentDownloadStarted', {
          id: doc._id,
          message: 'Please wait...'
        });
      }, 500);

      Documents.getDownloadToken(doc)
        .then(function(data) {
          $timeout.cancel(toutPromise);
          $window.location = data.url;
          $scope.$broadcast('KZDocumentDownloadFinished', { id: doc._id, message: '' });
        })
        .catch(function(data) {
          $timeout.cancel(toutPromise);
          $scope.$broadcast('KZDocumentDownloadFinished', { id: doc._id, message: '' });
          var message = 'Unable to retrieve document token.';
          if (data && data.message) {
            message = data.message;
          }

          Utils.showError({ message: message });
        });
    };
  }

  function AttachedDocumentsDirective() {
    return {
      restrict: 'E',
      scope: {
        documents: '='
      },
      templateUrl: 'app/components/documents/directives/kz-attached-documents.html',
      controller: AttachedDocumentsController,
      controllerAs: 'documentsCtrl'
    };
  }

  AttachedDocumentsController.$inject = [
    '$scope',
    '$timeout',
    '$window',
    'DocumentsService',
    'UtilsService'
  ];

  angular.module('component.documents')
    .directive('kzAttachedDocuments', AttachedDocumentsDirective);
})();
