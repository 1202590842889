(function() {
  'use strict';

  function EventSectionFormDirective(Form, EventUtils, EventSections, Network, Notify) {
    return {
      scope: {
        model: '=',
        docId: '=',
        additionalActions: '=?',
        def: '=',
        eventType: '=',
        readOnly: '=',
        forUser: '=',
        form: '=?',
        allowActions: '=?',
        parent: '=?',
        options: '=?'
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/response-form.html',
      link: function(scope) {
        // Make sure you see the buttons only if pending
        if (scope.parent !== undefined && scope.parent.doc.state !== 'pending') {
          scope.allowActions = false;
        }

        scope.responseForm = new Form();
        scope.helpers = {
          readOnly: scope.allowActions ? true : scope.readOnly
        };

        if (!scope.model.fields && scope.model.enc) {
          var options = { sectionId: scope.def._id };
          EventSections.decrypt(scope.parent.doc._id, scope.model.enc, options)
            .then(function(data) {
              scope.localFields = JSON.parse(data.data);
            })
            .catch(function(err) {
              console.log(err);
              scope.localFields = {};
            });
        } else {
          scope.localFields = scope.model.fields;
        }

        var buildForm = function() {
          if (_.isUndefined(scope.def)) {
            return;
          }

          scope.responseForm = new Form();
          var kzOpts = {
            forUser: scope.forUser,
            showAllBlueprints: (scope.options || {}).showAllBlueprints || false,
            eventTypeId: scope.eventType,
            docId: scope.docId
          };

          scope.def.fields.forEach(function(field) {
            var fld = EventUtils.createFormField(field, {}, kzOpts);
            scope.responseForm.addField(fld);
          });
        };

        var original;
        var edit = function() {
          if (!scope.allowActions) {
            return;
          }

          original = angular.copy(scope.model);
          scope.helpers.readOnly = false;
          scope.$broadcast('kzReloadActions');
          scope.$emit('kzEnterEditMode');
        };

        var save = function() {
          return scope.parent.saveApi({ queue: false })
            .then(function(doc) {
              scope.parent.doc._rev = doc._rev;
              scope.helpers.readOnly = true;
              scope.$broadcast('kzReloadActions');
              scope.$emit('kzLeaveEditMode');
            });
        };

        var cancel = function() {
          scope.model.fields = original.fields;
          scope.helpers.readOnly = true;
          scope.$broadcast('kzReloadActions');
          scope.$emit('kzLeaveEditMode');
        };

        var loadActionButtons = function() {
          return [
            {
              label: 'Edit',
              icon: 'icon-edit',
              onClick: edit,
              klass: 'text-primary',
              btnClass: 'btn-primary',
              showCondition: function() {
                return scope.helpers.readOnly;
              }
            },
            {
              label: 'Save',
              icon: 'icon-save',
              onClick: save,
              klass: 'text-success',
              btnClass: 'btn-success',
              showCondition: function() {
                return !scope.helpers.readOnly;
              }
            },
            {
              label: 'Cancel',
              icon: 'icon-cancel',
              onClick: cancel,
              klass: 'text-danger',
              btnClass: 'btn-danger',
              showCondition: function() {
                return !scope.helpers.readOnly;
              }
            }
          ];
        };

        scope.updateDocumentBrowserVisibility = function() {
          if (Network.isOffline()) {
            Notify.error('This is not available when working offline');
            return;
          }
          scope.showDocBrowser = !scope.showDocBrowser;
        };

        if (scope.allowActions) {
          scope.actionButtons = loadActionButtons();
        }

        scope.documentRemoved = function(model, key, docId) {
          // document has been removed from section attachments
          var newList = [];

          model[key].forEach(function(row) {
            var index = row.value.indexOf(docId);

            if (index > -1) {
              row.value.splice(index, 1);
            }

            if (row.value.length !== 0) {
              newList.push(row);
            }
          });

          model[key] = newList;
        };

        buildForm();
        scope.$watch('def', function() {
          buildForm();
        });

        scope.clearActions = function() {
          scope.additionalActions = [];
          scope.$emit('CancelPostponedActions');
        };
      }
    };
  }

  EventSectionFormDirective.$inject = [
    'FormsService',
    'EventUtils',
    'EventSectionsService',
    'NetworkService',
    'NotifyService'
  ];

  angular.module('events.directives')
    .directive('sectionResponseForm', EventSectionFormDirective);
})();
