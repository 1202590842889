(function() {
  'use strict';

  function SlotController($scope, $stateParams, ICON_FILES_EXTENSIONS_MAP, LocalizationService) {
    var ctrl = this;
    if (!$scope.item.loaded) {
      $scope.item.init();
    }

    ctrl.username = $stateParams.user;
    ctrl.fileIcon = ICON_FILES_EXTENSIONS_MAP[$scope.item.doc.documentType];

    ctrl.actionButtons = [];

    ctrl.openFolder = function(folderId) {
      $scope.$emit('FolderChanged', { folderId: folderId });
    };
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
  }

  SlotController.$inject = [
    '$scope',
    '$stateParams',
    'ICON_FILES_EXTENSIONS_MAP',
    'LocalizationService'
  ];

  function SlotDirective() {
    return {
      scope: {
        item: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/documents/document.simple.slot.html',
      controller: SlotController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.documents')
    .directive('documentSimpleSlot', SlotDirective)
    .controller('DocumentSimpleSlotController', SlotController);
})();
