(function() {
  'use strict';

  function TemplatesUtils($q, Utils, Security) {
    var service = {};

    /**
     * Modify the template to contain the audit log dates.
     * @param  {Object}  doc   The template being modified.
     * @param  {Boolean} isNew Whether the document already exists or not.
     * @param  {String}  actor Who is making the change.
     * @return {Promise}       A manually created promise so it can be chained.
     */
    service.saveAuditLog = function(doc, isNew, actor) {
      Utils.recordLog(doc, isNew ? 'created' : 'modified', actor);
      return $q.when(doc);
    };

    /**
     * Build the search object required by facetly.
     * @param  {string} title The title of the title facet.
     * @return {Object}       The search object.
     */
    service.getSearch = function(title) {
      return {
        facetly: {
          facets: [
            {
              id: 'title',
              type: 'text',
              label: title + ' title'
            }
          ],
          options: {
            defaultFacet: 'title',
            placeholder: 'Start typing to search...',
            listMaxItems: 10
          }
        },
        filters: [
          {
            id: 'title',
            matchFunc: function(doc, _key, value) {
              if (!value) { return true; }

              var val = value.toLowerCase(),
                  title = doc.doc.title.toLowerCase();
              return title.indexOf(val) > -1;
            }
          }
        ],
        orders: {
          isDefault: function(item) {
            return item.doc.isDefault;
          }
        },
        orderGroups: {
          isDefault: {
            title: 'Is default',
            orders: ['isDefault']
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'isDefault'
      };
    };

    /**
     * Based on the remove permission, figure out if the default dashboard
     * can be removed.   This will only return true for system:admin.
     * @param {string} [permission] What permission does the user need in order to remove it?
     * @return {Boolean} True if the default dashboard can be removed, false otherwise.
     */
    service.canRemoveDefaultTemplate = function(permission) {
      return Security.hasPermission(permission);
    };

    /**
     * Get the border class for a given dashboard template.
     * The class can be "info" just for the default dashboard, otherwise it's "complete".
     * @param  {Object} template The template for which to get the border class.
     * @return {String}          The border class.
     */
    service.getBorderClass = function(template) {
      var className = 'progress-border-',
          type = template.isDefault ? 'info' : 'complete';

      return className + type;
    };

    /**
     * Return whether we have a default template or not.
     * @param  {Array}  items The list of templates.
     * @return {Boolean}      True if default template is missing.
     */
    service.noDefaultTemplate = function(items) {
      return _.chain(items)
              .find(function(item) {
                return item.doc.isDefault;
              })
              .isUndefined()
              .value();
    };

    /**
     * Return the last used template ID, if there is one.
     * @param  {Object} prefs The user preferences that contain the lastUsedId.
     * @param  {string} key   The key for the preferences. Can be either 'dashboard' or 'profile'.
     * @return {mixed}        The ID when there is a lastUsedID, or undefined.
     */
    service.getLastUsedTemplateId = function(prefs, key) {
      return _.isUndefined(prefs[key]) ? undefined : prefs[key].lastUsed;
    };

    /**
     * Return the visible templates for based on the roles passed in, ordered with
     * the lastUsedID first, when there is a lastUsedId.
     * @param  {array} templates  The templates to filter and sort on.
     * @param  {array} roles      The list of roles for which to return the templates.
     * @param  {mixed} lastUsedId The ID (string) or undefined of the last used template.
     * @return {array}            The list of templates that match the role.
     */
    service.getTemplatesForRoles = function(templates, roles, lastUsedId) {
      var templates_ = _.chain(templates)
        .filter(function(template) {
          return _.intersection(roles, template.visibleForRoles).length;
        })
        .sortBy(function(template) {
          // if there is a last used template, let's put that first
          // as the controller will always load, by default,
          // the first template in the list
          var order = (!_.isUndefined(lastUsedId) && template._id === lastUsedId) ? 0 : 1;
          return order;
        })
        .value();
      return templates_;
    };

    /**
     * Return the templates without the deprecated widgets.
     * @param {array}   docs The templates documents.
     * @return {array}       The template documents without the deprecated widgets.
     */
    service.removeDeprecatedWidgets = function(docs) {
      var deprecatedWidgets = ['kaizenhq'];

      return docs.map(function(doc) {
        doc.template.rows.forEach(function(row) {
          row.columns.forEach(function(column) {
            column.widgets = column.widgets.filter(function(widget) {
              return deprecatedWidgets.indexOf(widget.type) === -1;
            });
          });
        });
        return doc;
      });
    };

    /**
     * Return the templates for a given user, based on a list of roles and preferences.
     * @param  {array} roles            The list of roles for which to return templates.
     * @param  {Object} prefs           The user preferecens that contain the lastUsedId.
     * @param  {array} templates        The list of templates to filter and sort.
     * @param  {string} type            The type for the preferences - 'dashboard' or 'profile'.
     * @param  {Object} defaultTemplate The default template, when there is no other template
     *                                  to show, nor an organisation default template.
     * @return {array}                  The list of matched templates.
     */
    service.getTemplatesForUser = function(roles, prefs, templates, type, defaultTemplate) {
      // We need to get the last used template, if any
      var lastUsedId = service.getLastUsedTemplateId(prefs, type);

      // We need to get the created default dashboard template, if any
      var orgDefaultTemplate = _.find(templates, 'isDefault');

      // We only want the templates that the current user can see based on his roles
      var visibleTemplatesForRoles = service.getTemplatesForRoles(
        templates,
        roles,
        lastUsedId
      );

      // If we have no templates, try to find the default one
      if (visibleTemplatesForRoles.length === 0) {
        visibleTemplatesForRoles = orgDefaultTemplate ?
          [orgDefaultTemplate] :
          [{ template: defaultTemplate, ignoreSaveLastUsed: true }];
      }

      // We need to make sure the templates don't contain deprecated widgets
      var cleanTemplates = service.removeDeprecatedWidgets(visibleTemplatesForRoles);
      return cleanTemplates;
    };

    return service;
  }

  TemplatesUtils.$inject = [
    '$q',
    'UtilsService',
    'SecurityService'
  ];

  angular.module('component.dashboardTemplates')
    .service('TemplatesUtilsService', TemplatesUtils);
})();
