(function() {
  'use strict';

  function DocumentsBulkConfirmationController($q, $state, $stateParams, Documents,
                                                Utils, ICON_FILES_EXTENSIONS_MAP) {
    var ctrl = this;

    Utils.setPageTitle('Confirm bulk move documents');

    ctrl.getFileIcon = function(documentType) {
      return ICON_FILES_EXTENSIONS_MAP[documentType];
    };

    ctrl.action = $stateParams.action;
    ctrl.source = [];

    Documents.getSource()
      .then(function(source) {
        ctrl.source = source;
        if (ctrl.source.length === 0) {
          $state.go('epf.documents.index');
        }

        if ($stateParams.action === 'move') {
          ctrl.destination = Documents.getDestination();
          if (ctrl.destination.length === 0) {
            $state.go('epf.documents.bulk', { action: $stateParams.action });
          }
        }
      });

    function doAction(options) {
      return Documents.bulkAction(options)
        .then(function() {
          $state.go('epf.documents.index');
        }).catch(Utils.showError);
    }

    ctrl.confirm = function() {
      var title = null;
      if (ctrl.action === 'delete') {
        var isOneDocLinkedToAnEvent = false;
        ctrl.source.forEach(
          function(element) {
            if (element.eventId) {
              isOneDocLinkedToAnEvent = true;
            }
          }
        );

        if (isOneDocLinkedToAnEvent) {
          title = 'One or more of the selected documents contain links to ' +
          'events on your timeline. Deleting these documents will result in ' +
          'them no longer being visible from the events. Do you wish to ' +
          'continue?';
        }
      }

      if (!title) {
        title = 'Are you sure you want to ' + ctrl.action + ' those documents?';
      }

      Utils.swal({
        title: title,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
        function(isConfirm) {
          if (isConfirm) {
            var options = {
              sourceDocs: ctrl.source,
              action: $stateParams.action
            };

            if ($stateParams.action === 'move') {
              options.destinationDoc = ctrl.destination;
              doAction(options);
            } else if ($stateParams.action === 'delete') {
              // check if all folder selected are empty
              $q.all(
                // Indeed this is weird. Should it rather be forEach?
                // It looks like there is no need to create a new array...
                ctrl.source.map(function(srcDoc) {
                  if (srcDoc.documentType === 'folder') {
                    return Documents.findByPath(srcDoc._id)
                    .then(function(items) {
                      if (items.length) {
                        var message = {
                          message: 'Some of the folders selected are not ' +
                          'empty. Please delete sub items before to retry'
                        };
                        return $q.reject(message);
                      }
                    });
                  }

                  return $q.when();
                })
              ).then(function() {
                return doAction(options);
              }).catch(Utils.showError)
                .then(function() {
                  $state.go('epf.documents.index');
                });
            }
          }
        });
    };
  }

  DocumentsBulkConfirmationController.$inject = [
    '$q',
    '$state',
    '$stateParams',
    'DocumentsService',
    'UtilsService',
    'ICON_FILES_EXTENSIONS_MAP'
  ];

  angular.module('component.documents')
    .controller('DocumentsBulkConfirmationController', DocumentsBulkConfirmationController);
})();
