import * as Oidc from 'oidc-client';

(function() {
  'use strict';

  function OidcService($q, $window, Utils, SweetAlert, Network) {
    var myOidc = Oidc;
    var service = {};

    window.addEventListener('storage', function(event) {
      console.log('LS:', event.key);
      if (event.key.indexOf('oidc.user:') === 0) {
        console.log('OIDC getting user');
        service.user()
          .then(function(user) {
            if (!user || user.expired) {
              service.lockScreen();
            }
          });
      }
    });

    window.addEventListener('visibilitychange', function() {
      console.log('Visibility', window.document.visibilityState);
      if (window.document.visibilityState === 'visible') {
        service.user()
          .then(function(user) {
            if (user) {
              if (user.expired) {
                service.loginSilent();
              }
            } else {
              service.lockScreen();
            }
          });
      }
    });

    service.getManager = function(options) {
      // Checks my manager is up to date and return
      options = options || {};
      if (options && options.force) {
        service.manager = null;
      }

      if (!_.isEqual(options, service.lastOptions)) {
        service.manager = null;
      }

      if (!service.manager) {
        service.lastOptions = angular.copy(options);
        service.manager = service.initManager(options);
      }

      return service.manager;
    };

    service.initManager = function(options) {
      options = options || {};
      var scope = 'openid';
      var extraScope = options.scope !== undefined ?
        options.scope :
        $window.localStorage.getItem('eas:lastScope') || '';
      if (extraScope) {
        scope += ' ' + extraScope;
        $window.localStorage.setItem('eas:lastScope', extraScope);
      } else {
        $window.localStorage.removeItem('eas:lastScope');
      }
      console.log('OIDC Claims: Setting up scope', scope);

      var serverSettings = window.kzConfig ? window.kzConfig.oidc : {};

      // If ever we want to use local storage
      var defaultSettings = {
        automaticSilentRenew: true,
        revokeAccessTokenOnSignout: true,
        accessTokenExpiringNotificationTime: 60,
        scope: scope,
        loadUserInfo: true
        // userStore: new myOidc.WebStorageStateStore({ store: window.localStorage })
      };

      var settings = Object.assign({}, serverSettings, defaultSettings);
      if (options.organisation) {
        settings.extraQueryParams = { kz_org: options.organisation };
      }
      var manager = new myOidc.UserManager(settings);
      manager.events.addUserLoaded(function(user) {
        service.silentLock = false;
        service.setAccessToken(user);
        if (service.lockScreenOn) {
          service.lockScreenOn = false;
          SweetAlert.close();
        }
      });
      manager.events.addUserSessionChanged(function() {
        console.log('OIDC session changed');
        service.setAccessToken();
      });
      manager.events.addUserUnloaded(function() {
        console.log('OIDC user unloaded');
        service.setAccessToken();
        service.lockScreen();
      });
      manager.events.addAccessTokenExpiring(function() {
        console.log('OIDC token expiring');
      });
      manager.events.addAccessTokenExpired(function() {
        console.log('OIDC token expired');
        service.lockScreen();
      });
      manager.events.addSilentRenewError(function(err) {
        console.log('OIDC token renew error', err);
        manager.stopSilentRenew();
        setTimeout(function() {
          console.log('OIDC: Retrying silent renew');
          manager.startSilentRenew();
        }, 5000);
      });
      manager.clearStaleState();
      return manager;
    };

    service.lockScreen = function() {
      if (Network.isOffline()) {
        return;
      }
      if (service.silentLock) {
        return;
      }
      if (service.lockScreenOn) {
        return;
      }

      service.lockScreenOn = true;
      Utils.swal({
        title: 'You have been inactive for a while now',
        text: '',
        type: 'warning',
        showCancelButton: false,
        allowEscapeKey: false,
        confirmButtonText: 'Continue',
        closeOnConfirm: false
      }, function() {
        service.lockScreenOn = true;
        service.loginPopup()
          .then(function(user) {
            service.lockScreenOn = true;
            console.log('Login popup');
            console.log(user);
          });
      });
    };

    service.login = function(options) {
      options = options || {};
      if (!options.organisation) {
        options.organisation = service.organisation;
      }

      if (options && options.lastUrl) {
        $window.localStorage.setItem('lastUrl', '/#' + options.lastUrl);
      }
      var manager = service.getManager(options);
      return manager.signinRedirect();
    };

    service.loginPopup = function(options) {
      options = options || {};
      if (!options.organisation) {
        options.organisation = service.organisation;
      }

      if (options && options.lastUrl) {
        $window.localStorage.setItem('lastUrl', '/#' + options.lastUrl);
      }
      var manager = service.getManager(options);
      return manager.signinPopup();
    };

    service.loginSilent = function(options) {
      options = options || {};
      if (!options.organisation) {
        options.organisation = service.organisation;
      }

      var manager = service.getManager(options);
      return manager.signinSilent();
    };

    service.logout = function() {
      service.silentLock = true;
      var manager = service.getManager();
      $window.localStorage.removeItem('eas:lastScope');
      return manager.signoutRedirect();
    };

    service.loginAs = function(username, options) {
      service.silentLock = true;
      options = options || {};
      options.scope = username !== undefined ? 'profile lase lase:' + username : '';
      options.lastUrl = '/';
      var manager = service.getManager();
      return manager.removeUser()
        .then(function() {
          return service.login(options);
        });
    };

    service.logoutAs = function() {
      return service.loginAs(undefined);
    };

    service.user = function() {
      var manager = service.getManager();
      return manager.getUser()
        .then(function(user) {
          if (!user) {
            try {
              var userData = $window.localStorage.getItem('currentOidcUser');
              if (userData) {
                console.log('OIDC: Loaded user from local storage');
                user = myOidc.User.fromStorageString(userData);
              }
            } catch (err) {
              console.log('Failed loading user from localstorage');
            }
          }
          return user;
        });
    };

    service.isLoggedIn = function() {
      console.log('OIDC: Getting logged in user');
      return service.getLoggedInUser();
    };

    service.getLoggedInUser = function() {
      return service.user()
        .then(function(user) {
          if (!user) {
            return $q.reject({ status: 401 });
          }

          if (user.expired) {
            console.log('User expired');
          }

          console.log(Network.isOffline());

          if (!Network.isOffline() && user.expired) {
            console.log('OIDC: Rejecting user');
            return $q.reject({ status: 401 });
          }

          service.setUserData(user);
          // var username = user.profile.lase_id || user.profile.sub;
          var username = service.laseUser || service.username;
          return {
            username: username,
            login: username,
            login_type: 'oidc'
          };
        });
    };

    service.setUserData = function(user) {
      if (!user) {
        service.bearer = null;
        service.usernameHeader = null;
        service.original = null;
        service.laseUser = null;
        return;
      }

      if (!Network.isOffline() && user.expired) {
        console.log('OIDC: Cannot set expired user');
        return;
      }
      if (service.username && user.profile.sub !== service.username) {
        console.log('Different user');
        service.bearer = null;
        service.usernameHeader = null;
        service.original = null;
        service.laseUser = null;
        service.lockScreen();
        location.reload();
        // var manager = service.getManager();
        // manager.removeUser();
        // service.logout();
        return;
      }

      var laseMatch = user.scope.match(/lase:([a-zA-Z0-9\-_]+)/);
      var laseUser;
      if (laseMatch) {
        laseUser = laseMatch[1];
      }

      var claims = user.profile;
      var usernameHeader = user.profile.sub;
      if (laseUser) {
        usernameHeader = laseUser + ':lase:' + usernameHeader;
        service.original = {
          username: claims.sub,
          firstName: claims.firstName,
          lastName: claims.lastName,
          email: claims.email,
          fullName: ((claims.firstName || '') +
            ' ' + (claims.lastName || '')).trim() || claims.email
        };
      }

      service.bearer = 'Bearer ' + user.access_token;
      service.usernameHeader = usernameHeader;
      service.username = user.profile.sub;
      service.laseUser = laseUser;
      console.log('OIDC: Setting up username', service.username);
    };

    service.setAccessToken = function(user) {
      console.log('OIDC: Setting access token');
      if (user && !user.expired) {
        service.setUserData(user);
        return $q.when();
      }

      return service.user()
        .then(function(user) {
          service.setUserData(user);
        });
    };

    service.setOrganisation = function(org) {
      console.log('API:', org);
      service.organisation = org;
    };

    return service;
  }

  OidcService.$inject = ['$q', '$window', 'UtilsService', 'SweetAlert', 'NetworkService'];

  angular.module('blocks.auth')
    .service('OidcService', OidcService);
})();
