import { $q } from 'angular-ui-router';
import * as Hashes from 'jshashes';

(function() {
  'use strict';

  function TurnitinService(Features, EasApi) {
    var service = {};

    service.isEnabled = function() {
      if (service.enabled !== undefined) {
        return $q.when(service.enabled);
      }

      return Features.isOn('turnitin', {})
        .then(function(res) {
          service.enabled = res;
          return res;
        });
    };

    service.submit = function(payload) {
      var url = 'turnitin/submissions';
      // var payload = {
      //   data: data,
      //   metadata: metadata || {}
      // };
      return EasApi.post(url, payload, undefined, { version: '' });
    };

    service.forceUpdate = function(submissionId) {
      var url = `turnitin/submissions/${submissionId}/force_update`;

      return EasApi.post(url, {}, undefined, { version: '' });
    };

    service.getViewerUrl = function(submissionId, eula) {
      var url = `turnitin/submissions/${submissionId}/viewer_url`;
      var params = {};
      if (eula) {
        params.eula = eula;
      }

      return EasApi.post(url, params, undefined, { version: '' });
    };

    service.get = function(submissionId) {
      var url = `turnitin/submissions/${submissionId}`;

      return EasApi.get(url, {}, undefined, { version: '' });
    };

    service.getHash = function(data) {
      if (!data) {
        return '';
      }

      if (data.key) {
        return new Hashes.MD5().hex(data.key);
      }

      return new Hashes.MD5().hex(data);
    };

    service.getSettings = function() {
      return EasApi.get('turnitin/settings', undefined, undefined, { version: '' });
    };

    service.postSettings = function(payload) {
      return EasApi.post('turnitin/settings', payload, undefined, { version: '' });
    };

    service.registerWebhook = function(payload) {
      return EasApi.post('turnitin/webhooks/register', payload, undefined, { version: '' });
    };

    service.getWebhookUrl = function() {
      return EasApi.absUrl('turnitin/webhooks', { version: '' });
    };

    return service;
  }

  TurnitinService.$inject = ['FeaturesService', 'EasApiService'];

  angular.module('blocks.utils')
    .factory('TurnitinService', TurnitinService);
})();
