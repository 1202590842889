import './drafts.module.js';
import './cards';
import './widgets';
import './auto-save.directive.js';
import './revisions.directive.js';
import './revisions-toggle.directive.js';
import './auto-save.exit.js';
import './drafts.service.js';
import './auto-save.service.js';
import './draft-slot.directive.js';
import './last-saved.directive.js';
import './drafts-list.directive.js';
import './auto-save.constants.js';
