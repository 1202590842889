(function() {
  'use strict';

  function Cards(Sidebar) {
    var service = {};

    service.register = function() {
      var cards = [
        {
          id: 'events-bulk',
          title: 'Selected tags for bulk tagging',
          directive: 'sidebar-bulk',
          options: {
            routes: [
              'epf.events.applytags',
              'epf.events.selecttags'
            ]
          }
        },
        {
          id: 'events-search',
          title: 'Advanced search',
          directive: 'sidebar-advanced-search',
          diroptions: {
            source: 'events',
            getSearch: ['EventSearch', function(EventSearch) {
              return EventSearch.getSearch('dbsearch');
            }]
          },
          options: {
            routes: [
              'epf.events.index',
              'epf.users.events',
              'epf.allevents.index',
              'epf.blueprints.index',
              'epf.blueprintMaps.index',
              'epf.announcements.index',
              'epf.event-types.index',
              'epf.users.index',
              'epf.user-fields.index',
              'epf.goals.index',
              'epf.importer.index',
              'epf.users.goals',
              'epf.reportTemplates.index',
              'epf.roles.index',
              'epf.email-templates.index',
              'epf.email-templates.email-logs',
              'epf.email-templates.email-logs-booking',
              'epf.email-templates.email-type-log',
              'epf.email-templates.email-template-log'
            ]
          }
        },
        {
          id: 'event-fillin',
          title: 'Event summary',
          directive: 'sidebar-event-fill-in',
          options: {
            routes: [
              'epf.events.edit',
              // 'epf.events.view',
              // 'epf.users.events-view',
              'epf.users.events-edit',
              'epf.users.events-new'
            ]
          }
        }
        // {
        //   id: 'event-linking-targets',
        //   title: 'Link to goal targets',
        //   directive: 'sidebar-linking-targets',
        //   options: {
        //     routes: [
        //       'epf.events.section-new',
        //       'epf.events.section-fillin',
        //       'epf.users.section-new',
        //       'epf.users.events-edit'
        //     ]
        //   }
        // },
        // {
        //   id: 'events-all-search',
        //   title: 'Advanced search',
        //   directive: 'sidebar-advanced-search',
        //   diroptions: {
        //     source: 'allevents',
        //     getSearch: ['EventSearch', function(EventSearch) {
        //       return EventSearch.getSearch('allsearch');
        //     }]
        //   },
        //   options: {
        //     routes: [
        //       'epf.allevents.index'
        //     ]
        //   }
        // }
      ];

      Sidebar.registerCards(cards);
    };

    return service;
  }

  Cards.$inject = ['SidebarService'];

  angular.module('component.events')
    .service('EventsCards', Cards);
})();
