(function() {
  'use strict';

  function DocumentBrowserPopup($uibModalInstance, popupOptions) {
    var ctrl = this;
    ctrl.popupOptions = popupOptions;
    ctrl.actions = popupOptions.actionButtons || [];
    _.forEach(ctrl.actions, function(action) {
      action.origOnCLick = action.onClick;
      action.onClick = function() {
        return action.origOnCLick(ctrl);
      };
    });

    ctrl.close = function(result) {
      if (result === undefined) {
        $uibModalInstance.close('cancel');
      } else {
        $uibModalInstance.close(result);
      }
    };

    ctrl.folderChanged = function(folderId) {
      console.log('Setting new folder Id', folderId);
      ctrl.folderId = folderId;
    };

    ctrl.modal = $uibModalInstance;
  }

  DocumentBrowserPopup.$inject = ['$uibModalInstance', 'popupOptions'];

  angular.module('component.documents')
    .controller('DocumentBrowserPopup', DocumentBrowserPopup);
})();
