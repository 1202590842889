(function() {
  'use strict';

  function SectionController(
    resolvedEventSection,
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $q,
    $log,
    Auth,
    Profile,
    EventSectionEdit,
    Utils,
    Notify
  ) {
    var ctrl = this;
    var action = $state.current.data.action;
    ctrl.isNew = ['new', 'draft'].indexOf(action) !== -1;

    ctrl.prefs = Profile.getPreferences();

    ctrl.eventOwner = $stateParams.user || Auth.currentUser();

    var goBack = function() {
      return Utils.go('epf.events.index', {}, { tryBack: true });
    };

    function loadOriginalEvent() {
      ctrl.originalEvent = ctrl.eventSection.loadAdditionalActions(
        angular.copy(ctrl.eventSection.originalEvent)
      );
    }

    var defaults = {
      eventSection: resolvedEventSection,
      eventType: $stateParams.eventTypeId,
      eventOwner: ctrl.eventOwner
    };
    defaults = _.assignIn(defaults, $stateParams.defaults || {});
    EventSectionEdit.init(action, defaults)
      .then(function(options) {
        ctrl.eventSection = EventSectionEdit.eventSection;
        ctrl.eventExtras = EventSectionEdit.eventExtras;
        ctrl.actionTitle = EventSectionEdit.getActionTitle();
        ctrl.options = options;
        ctrl.authorized = true;
        ctrl.loaded = true;
        var pageEventTitle = ctrl.eventSection.eventType
          ? ctrl.eventSection.eventType.name
          : 'draft';
        var pageActionTitle = ctrl.isNew ? 'New' : 'Edit';
        Utils.setPageTitle(pageActionTitle + ' ' + pageEventTitle);
        $rootScope.$broadcast('KZCurrentEventChange', { event: ctrl.eventSection });
        // ctrl.prefs.currentEventSection = ctrl.eventSection;
        ctrl.setupNextSectionWatch();
      })
      .then(function() {
        loadOriginalEvent();
      })
      .catch(function(err) {
        $log.error(err);
        Utils.showError(err);
        goBack();
      });

    // We have to broadcast the formControll only when initialized and it's done only after
    // the form element (directive) is processed
    // $scope.$watch('eventCtrl.form', function(form) {
    //   if (form !== undefined) {
    //     $rootScope.$broadcast('KZCurrentFormChange', { form: form });
    //   }
    // });

    ctrl.confirmationDialogToStartGoals = function() {
      return Utils.confirm({
        title: 'This will create and start the goals which you have just defined. ' +
          'Would you like to proceed?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Proceed'
      });
    };

    ctrl.save = function(form, action /* either publish or save */) {
      $scope.loading = true;

      var eventSection = ctrl.eventSection;
      var promise = $q.when();

      // We require the form to be valid if it is not a draft
      if (action === 'publish') {
        ctrl.submitted = true;
        form.$setSubmitted();
        ctrl.formIsSubmitted = true;
        if (form.$invalid) {
          Utils.swal({
            title: 'Please ensure all mandatory fields are completed and all errors corrected.',
            text: '',
            type: 'warning',
            showCancelButton: false,
            allowEscapeKey: true,
            confirmButtonText: 'OK',
            closeOnConfirm: true
          }, function() {
            ctrl.submitted = false;
            $scope.loading = false;
          });
          return;
        }
        if (eventSection.containsGoal) {
          promise = promise.then(function() {
            return ctrl.confirmationDialogToStartGoals();
          });
        }
      }

      return promise
        .then(function() {
          return EventSectionEdit.save(action);
        })
        .then(function(statusMessage) {
          Notify.success(statusMessage, 'Success');
          $scope.loading = false;
          if (action === 'publish') {
            return goBack();
          }
        })
        .catch(function(error) {
          ctrl.submitted = false;

          // Ignore user canceling promise
          if (error && error.status === 510) {
            return;
          }

          Utils.swal({
            title: (error && error.message) || 'Unknown error',
            type: 'error'
          });
        });
    };

    ctrl.quickDraftToEvent = function() {
      return $state.go('epf.events.new', {
        defaults: {
          _id: ctrl.eventSection.doc._id,
          quickDraft: true
        },
        route: 'epf.events.section-fillin'
      });
    };

    $scope.$on('$destroy', function() {
      EventSectionEdit.clearModel();
      $rootScope.$broadcast('KZCurrentEventChange', {});
      $rootScope.$broadcast('KZCurrentFormChange', {});
    });

    $scope.$on('KZItemRemoved', goBack);

    var currentNextSection;
    var handleNext = function handleNextSection() {
      var nextSection = ctrl.eventSection.getNextSection();
      if (nextSection === currentNextSection) {
        return;
      }

      var oldNextSection = currentNextSection;
      currentNextSection = nextSection;
      if (nextSection) {
        ctrl.nextDefSection = ctrl.eventSection.getDefSection(nextSection);
        // ctrl.eventSection.nextDefSection = ctrl.nextDefSection;
        if (!ctrl.eventSection.doc.nextSection) {
          ctrl.eventSection.doc.nextSection = {};
        }

        if (ctrl.eventSection.doc.invitation === undefined) {
          ctrl.eventSection.doc.invitation = {};
        }

        // We want to clear it only if oldNextSection is a different section
        // if it is undefined it means it is the first display
        if (oldNextSection !== undefined) {
          ctrl.eventSection.doc.invitation = {};
        }
      } else {
        // ctrl.eventSection.nextDefSection = undefined;
        delete ctrl.nextDefSection;
      }
      ctrl.nextFillOnSameDevice = EventSectionEdit.getFillOnSameDevice(ctrl.nextDefSection);
      ctrl.showNextSection = EventSectionEdit.showNextSection(ctrl.nextDefSection);
      if (!ctrl.eventSection.doc.nextInvitation) {
        ctrl.eventSection.doc.nextInvitation = {};
      }

      ctrl.nextSectionVisible = ctrl.nextFillOnSameDevice.enabled &&
        (ctrl.eventSection.doc.invitation || {}).filledOnSameDevice;

      console.log('Next section is', nextSection);
      $scope.$broadcast('KZNextSectionChanged', { idx: 0 });
    };

    var oldNextNextSection;
    var handleNextNext = function handleNextNextSection() {
      // If there is no next section it doesn't make sense looking for
      // another one
      if (!currentNextSection) {
        return;
      }
      var nextSection = ctrl.eventSection.findNextSection(
        currentNextSection, ctrl.eventSection.doc.nextSection
      );

      if (nextSection === oldNextNextSection) {
        return;
      }

      oldNextNextSection = nextSection;
      if (nextSection) {
        ctrl.nextNextDefSection = ctrl.eventSection.getDefSection(nextSection);
        if (!ctrl.eventSection.doc.nextInvitation) {
          ctrl.eventSection.doc.nextInvitation = {};
        }
      } else {
        delete ctrl.nextNextDefSection;
      }

      // FIXME
      // ctrl.showNextSection = EventSectionEdit.showNextSection();

      // ctrl.nextSectionVisible = ctrl.nextFillOnSameDevice.enabled &&
      //   (ctrl.eventSection.doc.invitation || {}).filledOnSameDevice;

      console.log('Next next section is', nextSection);
      $scope.$broadcast('KZNextSectionChanged', { idx: 1 });
    };

    ctrl.setupNextSectionWatch = function() {
      $scope.$watch(function() {
        return ctrl.eventSection.doc;
      }, function() {
        handleNext();
        handleNextNext();
      }, true);
    };

    $scope.$on('KZNextSectionCleared', function() {
      ctrl.eventSection.doc.nextSection = {};
      ctrl.eventSection.doc.nextInvitation = {};
    });

    $scope.$on('PostponedAction', function(_evt, postponedAction) {
      ctrl.eventSection.addAdditionalAction(postponedAction);
      loadOriginalEvent();
    });

    $scope.$on('CancelPostponedActions', function() {
      ctrl.originalEvent = angular.copy(ctrl.eventSection.originalEvent);
    });
  }

  SectionController.$inject = [
    'resolvedEventSection',
    '$scope',
    '$rootScope',
    '$state',
    '$stateParams',
    '$q',
    '$log',
    'AuthService',
    'ProfileService',
    'EventSectionEditService',
    'UtilsService',
    'NotifyService'
  ];

  angular.module('component.events')
    .controller('SectionController', SectionController);
})();
