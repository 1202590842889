(function() {
  'use strict';

  function UserBasicController($rootScope, $scope, Form, Notify, User, Utils) {
    var ctrl = this;

    ctrl.action = $scope.action;
    if (ctrl.action === 'edit') {
      $scope.$watch('ctrl.userBasicForm', function(val) {
        $scope.$emit('KZUserFormChanged', { form: val });
      });
      ctrl.profile = angular.copy($scope.profile);
    } else {
      ctrl.profile = $scope.profile;
    }

    ctrl.basicForm = new Form([
      { id: 'firstName', label: 'First Name', type: 'string', required: true },
      { id: 'lastName', label: 'Last Name', type: 'string', required: true }
    ]);

    ctrl.profilePhotoForm = new Form([
      {
        id: 'profilePicture',
        label: 'Profile picture',
        type: 'file',
        acceptedFileTypes: 'image/*',
        maxFilesize: 2000000, // 1MB
        multiple: false,
        showPreview: false,
        onRemove: function() {
          ctrl.profile.profilePicture = {
            content: null
          };
        },
        // eslint-disable-next-line max-len
        helpText: 'Please make sure the photo is square and at least 100 x 100px. Maximum file size is 2MB.'
      }
    ]);

    ctrl.saveProfile = function(isValid) {
      if (!isValid) {
        Utils.showError({ message: 'Form is not valid' });
        return;
      }

      var hasProfilePicture = typeof ctrl.profile.profilePicture !== 'undefined'
        && typeof ctrl.profile.profilePicture.content !== 'undefined';

      var formFields = hasProfilePicture
        ? _.union(ctrl.basicForm.fields, ctrl.profilePhotoForm.fields)
        : ctrl.basicForm.fields;

      return User.partiallySaveProfile('basic', formFields, ctrl.profile)
        .then(function(newProfile) {
          _.assignIn($scope.profile, newProfile);
          ctrl.profile = newProfile;
          Notify.success('The basic information has been saved!');
          $rootScope.$broadcast('KZProfileUpdated', { profile: ctrl.profile });
          ctrl.userBasicForm.$setPristine();
        })
        .catch(function(e) {
          Notify.error(e.message, 'An error occured while saving');
        });
    };
  }

  UserBasicController.$inject = [
    '$rootScope',
    '$scope',
    'FormsService',
    'NotifyService',
    'UserFactory',
    'UserFieldsService',
    'UtilsService'
  ];

  function UserBasicDirective() {
    return {
      scope: {
        action: '@',
        profile: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/users/directives/userBasic.html',
      replace: true,
      controller: UserBasicController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.users')
    .directive('userBasic', UserBasicDirective)
    .controller('UserBasicController', UserBasicController);
})();
