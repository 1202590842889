import './widgets.module.js';
import './filters';
import './forms';
import './buttons';
import './progress';
import './kz-tree';
import './deletable-items';
import './state';
import './search';
import './rateindicator';
import './dashboard';
import './header';
import './auditlog';
