import './dashboard-templates.module.js';
import './directives';
import './profile-templates.constants.js';
import './dashboard-template.controller.js';
import './dashboard-templates.service.js';
import './dashboard-templates.routes.js';
import './profile-templates.utils.js';
import './profile-templates.routes.js';
import './templates.utils.js';
import './dashboard-templates.constants.js';
import './profile-template.controller.js';
import './dashboard-templates.controller.js';
import './profile-templates.controller.js';
import './profile-templates.service.js';
import './dashboard-templates.utils.js';
