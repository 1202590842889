(function() {
  'use strict';

  function EventTypesService(
    $q,
    EasApiVersionStore,
    EasApi,
    Api,
    Auth,
    Security,
    Utils,
    Network,
    Cache,
    ALLOW_DRAFT_EVENT_TYPES
  ) {
    var cacheKeys = [
      'eventtypes-system-document'
    ];

    var service = new EasApiVersionStore('event-types', { cacheKeys: cacheKeys, envelope: 'doc' });

    service.findAll = function() {
      console.log('Event types, obsolete call to findAll');
      return $q.when([]);
    };

    /**
     * Return the initial structure of the event type
     * @return {object}
     */
    service.getDefaultDoc = function() {
      return {
        _id: Utils.guid(),
        versionGroupId: Utils.guid(),
        type: this.docType,
        state: 'draft',
        organisation: Auth.currentOrganisation(),
        defaultVisibility: 'public',
        color: Utils.generateColor(),
        version: 1
      };
    };

    service.searchIds = function(data) {
      return Api.post('es_eventtypes_ids', data);
    };

    service.fetchIds = function(ids, options) {
      return Api.post('eventtypes_ids', { ids: ids, options: options || {} })
        .then(function(data) {
          return data.hits;
        });
    };

    service.updateVersionGroup = function(versionGroupId) {
      return service.searchIds({ versionGroupId: versionGroupId, purpose: 'other', size: 100 })
        .then(function(ids) {
          return service.fetchIds(_.map(ids.hits, 'id'), { transformType: 'full' });
        })
        .then(function(data) {
          return service.clearGroupById(versionGroupId)
            .then(function() {
              return data;
            });
        })
        .then(function(data) {
          return service.storeItems(_.map(data, function(item) {
            return item.doc;
          }));
        });
    };

    service.getField = function(fieldId, eventTypeId) {
      return this.find(eventTypeId, { cache: 'cached' })
        .then(function(eventType) {
          var sections = eventType.sections || [];
          var field;
          for (var i = 0; i < sections.length; i++) {
            field = _.find(sections[i].fields || [], { _id: fieldId });
            if (field !== undefined) {
              return field;
            }
          }

          return $q.reject({ status: 404, message: 'Could not find field: ' + fieldId });
        });
    };

    service.getOptions = function(options, kzOpts) {
      options = options || {};
      var filterable = options.filterable ? 'filterable' : 'all';
      kzOpts = kzOpts || {};
      var ext = _.assignIn({
        key: 'options-findall-grouped-' + filterable,
        maxAge: 60 * 1000,
        cached: true
      }, kzOpts);
      var func = function() {
        return Api.get('eventtypes_options', { filterable: filterable });
      };

      return Cache.cachedPromise(func, ext);
    };

    service.getGoalOptions = function(kzOpts) {
      kzOpts = kzOpts || {};
      var ext = _.assignIn({
        key: 'goal-options-findall-grouped',
        maxAge: 60 * 1000,
        cached: true
      }, kzOpts);
      var func = function() {
        return Api.get('eventtypes_goal_options');
      };

      return Cache.cachedPromise(func, ext);
    };

    function filterOne(res, user) {
      var eventType = res.doc;

      if (!eventType.sections) {
        return $q.when(false);
      }

      if (eventType.sections.length === 0) {
        return $q.when(false);
      }

      var allowedTypes = ['published'];
      if (ALLOW_DRAFT_EVENT_TYPES) {
        allowedTypes.push('draft');
      }

      if (allowedTypes.indexOf(eventType.state) === -1) {
        return $q.when(false);
      }

      var ownerRole = eventType.ownerRole;
      var firstSectionResponsible = eventType.sections[0].filledBy;

      if (user === Auth.currentUser()) {
        // Event on my timeline can be created if the section is filled by owner
        // and if the section can be created on my timeline
        if (firstSectionResponsible.indexOf('system:timeline-owner') === -1) {
          return $q.when(false);
        }

        return Security.hasRole(ownerRole);
      } else if (user === '__bulk__') {
        // If user is bulk then we can only check that originating user
        // has a potential role of a first responsible and maybe that
        // second section is timeline owner (?)
        if (eventType.sections.length > 1) {
          var secondSectionResponsible = eventType.sections[1].filledBy;
          if (secondSectionResponsible.indexOf('system:timeline-owner') === -1) {
            return $q.when(false);
          }
        }

        return Security.hasRole(firstSectionResponsible);
      }

      // Event on user's timeline can be created if it can be on users timeline
      // and I'm first section responsible

      return $q.all([
        Security.hasRoleFor(user, firstSectionResponsible),
        Security.userHasRole(user, ownerRole)
      ]).then(function(result) {
        return result[0] && result[1];
      });
    }

    service.findAvailableFor = function(user) {
      if (Network.isOffline()) {
        return this.findLatestPublishedCached()
          .then(function(data) {
            return Utils.asyncFilter(data, function(res) {
              return filterOne(res, user);
            });
          })
          .then(function(data) {
            var sorted = _.sortBy(data, function(item) {
              return (item.doc.name || '').toLowerCase();
            });
            return _.map(sorted, 'doc');
          });
      }

      return service.findAvailableForViaApi(user);
    };

    service.findAvailableForViaApi = function(username) {
      return Api.get('eventtypesavailablefor', {}, { username: username })
        .then(function(data) {
          return data.event_types;
        });
    };

    service.findSystemDocument = function() {
      var ext = {
        key: 'eventtypes-system-document',
        maxAge: 5 * 60 * 1000,
        cached: true
      };
      var _this = this;
      var func = function() {
        return _this.findSystemIds('uploaded_document');
      };

      return Cache.cachedPromise(func, ext);
    };

    service.findSystemIds = function(systemId) {
      return this.searchIds({ systemId: systemId, purpose: 'latestPublished' })
        .then(function(ids) {
          return _.map(ids.hits, 'id');
        });
    };

    service.isUploadedEventType = function(id) {
      return this.find(id, { cached: true })
        .then(function(eventType) {
          if (
            eventType.systemType === 'system' &&
              eventType.systemId === 'uploaded_document'
          ) {
            return true;
          }
        }
        );
    };

    service.findReport = function(reportId) {
      var opts = { version: this.apiVersion };
      var url = this.specificUrl('event_report/' + reportId);
      return EasApi.get(url, undefined, undefined, opts);
    };

    service.getOwnReviewableSections = function() {
      var opts = { version: this.apiVersion };
      var url = this.specificUrl('reviewable_sections');
      return EasApi.get(url, undefined, undefined, opts);
    };

    service.getFlattenFieldCategories = function(fieldId, eventTypeId) {
      // Can this be cached somehow?
      return this.getField(fieldId, eventTypeId)
        .then(function(field) {
          return Utils.flattenCategories(field.categories || [], field, field);
        });
    };

    service.findByFieldCategoryId = function(category, fieldId, eventTypeId) {
      return this.getFlattenFieldCategories(fieldId, eventTypeId)
        .then(function(flatten) {
          return flatten[category];
        });
    };


    return service;
  }

  EventTypesService.$inject = [
    '$q',
    'EasApiVersionStore',
    'EasApiService',
    'ApiService',
    'AuthService',
    'SecurityService',
    'UtilsService',
    'NetworkService',
    'CacheService',
    'ALLOW_DRAFT_EVENT_TYPES'
  ];

  angular.module('component.eventTypes')
    .factory('EventTypesService', EventTypesService);
})();
