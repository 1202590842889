(function() {
  'use strict';

  function ImportController($q, $stateParams, AsyncTasks, Form, Security, Utils) {
    var ctrl = this;

    ctrl.importId = $stateParams.id;
    if (_.isUndefined($stateParams.id)) {
      Utils.setPageTitle('New import');
      ctrl.model = { taskAction: 'import_file' };
      Security.hasRole('system:superadmin', { promise: true })
        .then(function(hasRole) {
          if (!hasRole) {
            return;
          }

          ctrl.taskActionChooserForm = new Form([
            {
              id: 'taskAction',
              type: 'discrete',
              label: 'What action would you like to perform?',
              required: true,
              options: [
                { _id: 'import_file', name: 'Bulk import' },
                { _id: 'bulk_merge_users', name: 'Merge users in Bulk' },
                { _id: 'bulk_merge_msfs', name: 'Merge MSFs events in Bulk' }
              ]
            }
          ]);
        });
    } else {
      AsyncTasks.find($stateParams.id, { cached: false })
        .then(function(importDoc) {
          Utils.setPageTitle('Edit import task: ' + importDoc.data.name);
          if (_.indexOf(['new', 'processing'], importDoc.state) > -1) {
            var msg = 'You cannot restart a new import of a running or completed task';
            return $q.reject(
              { status: 405, message: msg }
            );
          }

          ctrl.name = importDoc.data.name;
          var data = importDoc.data;
          data.taskAction = importDoc.action;

          // deserialize (a bit ugly :()
          if (data.extra) {
            data.eventTypeId = data.extra.event_type_id;
            delete data.extra;
          }

          ctrl.model = data;
        })
        .catch(Utils.showError);
    }
  }

  ImportController.$inject = [
    '$q',
    '$stateParams',
    'AsyncTasksService',
    'FormsService',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.importer')
    .controller('ImportController', ImportController);
})();
