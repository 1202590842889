(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.documents', {
        url: 'documents',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],
          auth: ['setup', 'SecurityService', function(_setup, Security) {
            return Security.hasPermission('documents.view.own');
          }]
        }
      })

      .state('epf.documents.index', {
        url: '/',
        views: {
          content: {
            templateUrl: 'app/components/documents/list.html',
            controller: 'DocumentsController',
            controllerAs: 'documentsCtrl'
          }
        }
      })

      .state('epf.documents.folder', {
        url: '/:folderId',
        params: {
          folderId: ''
        },
        views: {
          content: {
            templateUrl: 'app/components/documents/list.html',
            controller: 'DocumentsController',
            controllerAs: 'documentsCtrl'
          }
        }
      })

      .state('epf.documents.bulkmove', {
        url: '/bulkmove/:folderId',
        params: {
          folderId: ''
        },
        views: {
          content: {
            templateUrl: 'app/components/documents/bulk/move.html',
            controller: 'DocumentsBulkMoveController',
            controllerAs: 'documentsBulkMoveCtrl'
          }
        }
      })

      .state('epf.documents.bulkconfirmation', {
        url: '/bulkconfirmation/:action',
        views: {
          content: {
            templateUrl: 'app/components/documents/bulk/confirmation.html',
            controller: 'DocumentsBulkConfirmationController',
            controllerAs: 'documentsBulkConfirmationCtrl'
          }
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.documents')
    .config(config);
})();
