(function() {
  'use strict';

  function DocumentUtilsService(ICONS) {
    var service = {};

    service.getFileIcon = function(documentType) {
      var type = documentType || '';
      return type.toLowerCase() === 'folder' ? 'folder' : ICONS[type.toLowerCase()];
    };

    service.getBaseName = function(name) {
      return name.substr(0, name.lastIndexOf('.')) || name;
    };

    return service;
  }

  DocumentUtilsService.$inject = [
    'ICON_FILES_EXTENSIONS_MAP'
  ];

  angular.module('component.documents')
    .service('DocumentUtilsService', DocumentUtilsService);
})();
