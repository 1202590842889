(function() {
  'use strict';

  // Document download

  function DocumentDownloadController(
    $scope,
    $window,
    $timeout,
    Document,
    Documents,
    Utils
  ) {
    var ctrl = this;
    ctrl.downloading = false;
    ctrl.docName = 'Loading...';

    var _waitABit = function() {
      ctrl.docName = 'Please wait...';
    };

    ctrl.download = function() {
      var _docName = ctrl.docName;
      var toutPromise = $timeout(_waitABit, 500);
      ctrl.downloading = true;

      Documents.getDownloadToken(ctrl.document.doc)
        .then(function(data) {
          $timeout.cancel(toutPromise);
          $window.location = data.url;
          ctrl.docName = _docName;
          ctrl.downloading = false;
        })
        .catch(function(data) {
          $timeout.cancel(toutPromise);
          ctrl.docName = _docName;
          ctrl.downloading = false;
          var message = 'Unable to retrieve document url';
          if (data && data.message) {
            message = data.message;
          }

          console.log(message, data);
          Utils.showError({ message: message });
        });
    };

    ctrl.loadDocument = function() {
      if ($scope.document) {
        ctrl.document = $scope.document;
        ctrl.canDownload = true;
        ctrl.isFolder = ctrl.document.isFolder();
        ctrl.docName = $scope.title || ctrl.document.doc.name;
        ctrl.loaded = true;
        return;
      }

      ctrl.document = new Document();
      var documentId = $scope.documentId;
      if (documentId !== undefined) {
        ctrl.document.load(documentId)
          .then(function() {
            ctrl.isFolder = ctrl.document.isFolder();
            ctrl.docName = $scope.title || ctrl.document.doc.name;
            ctrl.canDownload = ctrl.document.canView();
            ctrl.loaded = true;
          })
          .catch(function(error) {
            ctrl.loaded = true;
            ctrl.isFolder = false;
            ctrl.canDownload = false;
            ctrl.error = true;
            if (error && error.status === 404) {
              ctrl.docName = 'This file has been deleted';
            } else if (error && error.message) {
              ctrl.docName = 'Could not load the file name. ' + error.message;
            } else {
              ctrl.docName = 'Could not load the file name. Unexpected error';
            }
          });
      }
    };

    ctrl.updateIsShared = function(value) {
      if (value) {
        ctrl.document.makePublic();
      } else {
        ctrl.document.makePrivate();
      }
    };

    ctrl.loadDocument();
  }

  DocumentDownloadController.$inject = [
    '$scope',
    '$window',
    '$timeout',
    'DocumentFactory',
    'DocumentsService',
    'UtilsService'
  ];

  function DocumentDownloadDirective() {
    return {
      scope: {
        documentId: '=',
        document: '=',
        title: '@',
        doc: '=',
        onRemove: '&'
      },
      restrict: 'E',
      templateUrl: 'app/components/documents/doc-download.html',
      replace: true,
      controller: DocumentDownloadController,
      controllerAs: 'ctrl',
      link: function(scope, _elem, attrs) {
        scope.onRemoveIsPresent = 'onRemove' in attrs;

        scope.removeDoc = function(docId) {
          scope.onRemove({ docId: docId });
        };
      }
    };
  }

  // Document upload

  function DocumentUploadController(
    $log,
    $scope,
    DetectBrowser,
    Documents,
    Events,
    Auth,
    Utils,
    Notify
  ) {
    var ctrl = this;

    var browser = DetectBrowser.detect();
    ctrl.isOldBrowser = false;
    if (browser.name === 'safari' && parseInt(browser.version) < 13) {
      ctrl.isOldBrowser = true;
      return;
    }

    var getDropzoneConfig = function() {
      return Documents.getUploadInfo()
        .then(function(info) {
          $log.warn('Dropzone: Setting');

          // var acceptedFiles = [];
          // AUTHORIZED_FILES_EXTENSIONS.forEach(
          //   function(element) {
          //     acceptedFiles.push('.' + element);
          //   }
          // );

          // var acceptedFilesJoined = acceptedFiles.join();

          var dropzoneAllowMultipleFiles = $scope.multiple !== undefined;
          var dropzoneSquashOnUpload = $scope.squashOnUpload !== undefined;
          var dictDefaultMessage =
            '<span>Drag and drop documents here  or Click to select from your device</span>';

          var maxSize;
          if (info.max_file_size) {
            maxSize = info.max_file_size / 1024 / 1024;
            dictDefaultMessage += '<p>Maximum upload file size: ' + maxSize + 'MB</p>';
          }

          ctrl.dropzoneConfig = {
            url: '/will-be-defined-in-accept',
            method: 'POST',
            // acceptedFiles: acceptedFilesJoined,
            maxFilesize: maxSize,
            dictDefaultMessage: dictDefaultMessage,
            init: function() {
              this.on('success', function(file) {
                $log.warn('file init: ' + file.name);
                if (dropzoneSquashOnUpload) {
                  this.removeFile(file);
                }
              });

              this.on('addedfile', function() {
                if (!dropzoneAllowMultipleFiles && this.files.length > 1) {
                  if (this.files[1] !== null) {
                    this.removeFile(this.files[0]);
                  }
                }
              });
            },
            sending: function(file, xhr) {
              var dp = this;
              var formData = new FormData();
              _.forOwn(dp.fields[file.name], function(obj, key) {
                formData.append(key, obj);
              });
              formData.append('file', file);

              $log.warn('file sending: ' + file.name);
              $log.warn('Dropzone: Sending');
              var _send = xhr.send;
              xhr.send = function() {
                _send.call(xhr, formData);
              };
            },
            processing: function(file) {
              this.options.url = this.urls[file.name];
            },
            accept: function(file, done) {
              $log.warn('file accept: ' + file.name);
              $log.warn('Dropzone: Accepting');
              var dp = this;

              // this would store original filename to the S3 metadata,
              // but the filename is never changed
              // dp.options.headers['Content-Disposition'] = 'attachment; filename=' + file.name;
              var username = Auth.currentUser();
              var fileNameSplitted = file.name.split('.');
              var fileExtension = fileNameSplitted[fileNameSplitted.length - 1];
              var fileSize = file.size;
              Notify.success('Documets have been scheduled for upload');
              Documents.getUploadToken(username, fileExtension, fileSize)
                .then(function(data) {
                  if (_.isUndefined(dp.s3KeyNames)) {
                    dp.s3KeyNames = {};
                  }
                  dp.s3KeyNames[file.name] = data.key;

                  if (_.isUndefined(dp.urls)) {
                    dp.urls = {};
                  }

                  dp.urls[file.name] = data.url;

                  if (_.isUndefined(dp.fields)) {
                    dp.fields = {};
                  }

                  dp.fields[file.name] = data.fields;


                  $log.warn('Dropzone: accept done');
                  return done();
                }).catch(function(data) {
                  console.log('error:', data);
                  Utils.showError({ message: data.message });
                });
            },
            success: function(file) {
              this.options.url = undefined; // reset url for next file to be uploaded

              $log.warn('file success: ' + file.name);
              $log.warn('Dropzone: success');
              var now = Utils.now();
              var fileNameSplitted = file.name.split('.');
              var fileExtension = fileNameSplitted[fileNameSplitted.length - 1];
              var documentId = Utils.guid();
              var path;

              if ($scope.path) {
                path = $scope.path.slice();
              } else {
                path = [];
              }

              path.push(documentId);

              var organisation = Auth.currentOrganisation();

              var doc = {
                _id: documentId,
                date: {
                  added: now,
                  modified: now
                },
                type: 'document',
                documentType: fileExtension,
                organisation: organisation,
                user: Auth.currentUser(),
                name: file.name,
                isShared: true,
                visibility: 'public',
                path: path,
                s3KeyName: this.s3KeyNames[file.name]
              };

              var eventId = Utils.guid();
              return Documents.save(doc)
                .then(function(_data) {
                  $scope.onUpload({ doc: doc }); // call callback
                  $scope.linkDocument({ doc: doc }); // call callback

                  return Events.createUploadedDocumentEvent(
                    eventId, documentId, now, file.name, organisation
                  );
                })
                .then(function() {
                  $log.warn('Dropzone: success done');
                  Notify.success('Document has been uploaded');
                  if (file.previewElement) {
                    return file.previewElement.classList.add('dz-success');
                  }
                  // if ($scope.path.length > 0) {
                  //   var folderId = $scope.path.slice(-1).pop();
                  //   Documents.checkFolderStatus(folderId);
                  // }
                }).catch(function(data) {
                  console.log('error:', data);
                  Utils.showError({ message: data.message });
                });
            },
            error: function(_file, _errorMessage, _xhr) {
              // Raven.captureException(new Error(errorMessage));
            }
          };
        });
    };

    getDropzoneConfig();
  }

  DocumentUploadController.$inject = [
    '$log',
    '$scope',
    'DetectBrowser',
    'DocumentsService',
    'EventsService',
    'AuthService',
    'UtilsService',
    'NotifyService'
  ];

  function DocumentUploadDirective() {
    return {
      scope: {
        title: '@',
        path: '=',

        // on-upload attribute directive use
        onUpload: '&onUpload',

        // remove the file from dropzone after upload so it does not confuse user
        squashOnUpload: '@squashOnUpload',
        linkDocument: '&',
        doc: '=',
        multiple: '@' // if present, allow to upload multiple files
      },
      restrict: 'E',
      templateUrl: 'app/components/documents/doc-upload.html',
      replace: true,
      controller: DocumentUploadController,
      controllerAs: 'ctrl'
    };
  }


  function DocumentBrowserDirective($q, DocumentFactory, DocumentSearch, Documents, List) {
    return {
      scope: {
        model: '=',
        fieldKey: '=',
        multiple: '@' // if present, allow add more than one file to the model
      },
      restrict: 'E',
      templateUrl: 'app/components/documents/doc-browser.html',
      replace: true,

      link: function(scope, _elems, _attrs, _controller) {
        // FIXME - we probably want different doc browser widget
        // (popup?) so use unlimited pagination for now.
        function loadCurrentPath() {
          scope.currentPath = [];
          if (scope.helpers.folderId !== undefined) {
            Documents.find(scope.helpers.folderId)
              .then(function(document) {
                scope.currentPath = document.path || [];
              });
          }
        }

        function makeDocs(item) {
          return new DocumentFactory(item.doc);
        }

        scope.helpers = {};

        function loadList(folderId) {
          scope.helpers.folderId = folderId;
          return $q.all([DocumentSearch.search, Documents.findByFolderId(folderId)])
            .then(function(result) {
              var search = result[0];
              var items = _.map(result[1], makeDocs);
              scope.list = new List({
                search: search,
                model: {},
                idField: 'doc._id',
                findOptions: { limit: 999 }
              });
              return scope.list.doLoadItems(items);
            })
            .then(function() {
              loadCurrentPath();
              // setSpaceRate();
            });
        }

        loadList();
        scope.loadList = loadList;

        var isLinkedObj = function(obj, documentId) {
          if (!_.isArray(obj.value)) {
            return false;
          }

          return obj.value.indexOf(documentId) !== -1;
        };

        scope.isLinked = function(documentId) {
          if (scope.model[scope.fieldKey] === undefined) {
            return false;
          }

          if (_.isArray(scope.model[scope.fieldKey])) {
            var linked = false;
            _.forEach(scope.model[scope.fieldKey], function(obj) {
              if (!linked) {
                linked = isLinkedObj(obj, documentId);
              }
            });
            return linked;
          }
          return isLinkedObj(scope.model[scope.fieldKey], documentId);
        };

        var attachToModel = function(model, fieldId, doc, multiple) {
          // this adds a document to eventDoc[fieldId]. See also forms.service documentUploaded
          // we use type: document to be able to retrieve document fields from events
          // in events_by_document view
          if (multiple) {
            if (model[fieldId] === undefined) {
              model[fieldId] = [];
            }

            if (_.flatten(_.map(model[fieldId], 'value')).indexOf(doc._id) === -1) {
              // prevent duplicate files
              model[fieldId].push({ type: 'document', value: [doc._id] });
            }
          } else {
            model[fieldId] = { type: 'document', value: [doc._id] };
          }
        };

        var removeFromModel = function(model, fieldId, docId, multiple) {
          var index;

          if (multiple) {
            // If it's not an array it's not there thus can't be unlinked
            if (!_.isArray(model[fieldId])) {
              return;
            }

            var current = _.flatten(_.map(model[fieldId], 'value'));
            index = current.indexOf(docId);
            if (index > -1) {
              model[fieldId].splice(index, 1);
            }

            if (model[fieldId].length === 0) {
              model[fieldId] = [];
            }
          } else {
            index = model[fieldId].value.indexOf(docId);
            if (index > -1) {
              model[fieldId].value.splice(index, 1);
            }

            if (model[fieldId].value.length === 0) {
              model[fieldId] = {};
            }
          }
        };

        scope.unlinkDocument = function(document) {
          removeFromModel(
            scope.model,
            scope.fieldKey,
            document._id,
            scope.multiple !== undefined
          );
        };

        scope.linkDocument = function(document) {
          attachToModel(
            scope.model,
            scope.fieldKey,
            document,
            scope.multiple !== undefined
          );
        };
      }
    };
  }

  DocumentBrowserDirective.$inject = [
    '$q',
    'DocumentFactory',
    'DocumentSearch',
    'DocumentsService',
    'ListFactory'
  ];

  function DocumentTitleDirective(Documents) {
    return {
      scope: {
        docId: '@',
        onRemove: '&?'
      },
      restrict: 'EA',
      template: '<i class="icon-spin animate-spin" ng-if="!doc"></i>' +
                '{{ doc.name }} ' +
                '<i ng-click="removeDoc(doc._id)" ng-if="onRemove !== undefined"' +
                ' class="icon-trash"></i>',
      link: function(scope) {
        scope.removeDoc = function(docId) {
          scope.onRemove({ docId: docId });
        };

        Documents.find(scope.docId)
          .then(function(doc) {
            scope.doc = doc;
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    };
  }

  DocumentTitleDirective.$inject = ['DocumentsService'];

  function DocumentBreadcrumbsDirective($q, Documents, DocumentsStub) {
    return {
      scope: {
        docId: '@',
        useApi: '@', // present or not present
        username: '@',
        onKzClick: '&?'
      },
      restrict: 'EA',
      templateUrl: 'app/components/documents/doc-breadcrumbs.html',
      link: function(scope, _elem, attrs) {
        function buildPath(doc) {
          return '/' + doc.path.join('/');
        }

        function loadBreadcrumbsDb() {
          // load breadcrubms using Couch
          var breadcrumbs = [];
          if (scope.docId) {
            Documents.find(scope.docId)
              .then(function(document) {
                // we really need to process them in order
                var promises = [];
                document.path.forEach(function(docId) {
                  promises.push(Documents.find(docId));
                });

                $q.all(promises)
                .then(function(results) {
                  results.forEach(function(doc) {
                    breadcrumbs.push({
                      _id: doc._id,
                      name: doc.name,
                      visibility: doc.visibility
                    });
                  });
                  scope.breadcrumbs = breadcrumbs;
                });
              });
          }
        }

        function loadBreadcrumbsAPI() {
          // load breadcrubms using API service
          var breadcrumbs = [];
          if (scope.docId) {
            DocumentsStub.find(scope.docId)
              .then(function(document) {
                // we really need to process them in order
                var promises = [];
                document.path.forEach(function(docId) {
                  promises.push(DocumentsStub.find(docId));
                });

                $q.all(promises)
                .then(function(results) {
                  results.forEach(function(doc) {
                    breadcrumbs.push({
                      _id: doc._id,
                      name: doc.name,
                      visibility: doc.visibility,
                      path: buildPath(doc)
                    });
                  });
                  scope.breadcrumbs = breadcrumbs;
                });
              });
          }
        }

        // prepare breadcrumbs
        scope.$watch('docId', function() {
          if (_.isUndefined(attrs.useApi)) {
            loadBreadcrumbsDb();
          } else {
            loadBreadcrumbsAPI();
          }
        });
      }
    };
  }

  DocumentBreadcrumbsDirective.$inject = ['$q', 'DocumentsService', 'DocumentsStubService'];

  angular.module('component.documents')
    .directive('documentDownload', DocumentDownloadDirective)
    .controller('DocumentDownloadController', DocumentDownloadController);

  angular.module('component.documents')
    .directive('documentUpload', DocumentUploadDirective)
    .controller('DocumentUploadController', DocumentUploadController);

  angular.module('component.documents')
    .directive('documentBrowser', DocumentBrowserDirective);

  angular.module('component.documents')
    .directive('epfDocumentTitle', DocumentTitleDirective);

  angular.module('component.documents')
    .directive('epfDocumentBreadcrumbs', DocumentBreadcrumbsDirective);
})();
