import './reports.module.js';
import './directives';
import './partials';
import './widgets';
import './charts.constants.js';
import './reports.field.factory.js';
import './report.calculator.js';
import './multiReport.factory.js';
import './reportTemplate.controller.js';
import './reports.constants.js';
import './reportTemplate.search.js';
import './reporttemplates.service.js';
import './reportTemplates.controller.js';
import './reports.factory.js';
import './reportTemplate.slot.js';
import './report-view.filters.controller.js';
import './singleReport.factory.js';
import './multiReport.edit.element.controller.js';
import './report-view.events.controller.js';
import './report-run.controller.js';
import './report-viewer.factory.js';
import './reports.route.js';
import './sqlReport.factory.js';
