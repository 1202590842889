(function() {
  'use strict';

  function RateIndicatorDirective() {
    return {
      scope: {
        rate: '='
      },
      restrict: 'E',
      templateUrl: 'app/blocks/widgets/rateindicator/rate-indicator.html'
    };
  }

  RateIndicatorDirective.$inject = ['DocumentsService'];

  angular.module('blocks.widgets')
    .directive('rateIndicator', RateIndicatorDirective);
})();
