(function() {
  'use strict';

  function EventSectionHeaderDirective($stateParams, Auth, EVENT_STATES) {
    return {
      scope: {
        eventSection: '='
      },
      restrict: 'E',
      templateUrl: 'app/components/events/directives/event-section-header.html',
      link: function(scope) {
        scope.conf = {
          auditLogShown: false
        };

        scope.EVENT_STATES = EVENT_STATES;
        scope.sectionStates = scope.eventSection.getProgress();
        scope.username = $stateParams.user || Auth.currentUser();
        scope.formattedDocuments = _.map(scope.eventSection.documents, function(doc) {
          return { value: [doc] };
        });

        scope.reloadEvent = function() {
          scope.$emit('EventReload', { id: scope.eventSection.doc._id });
        };
      }
    };
  }

  EventSectionHeaderDirective.$inject = [
    '$stateParams',
    'AuthService',
    'EVENT_STATES'
  ];

  angular.module('events.directives')
    .directive('eventSectionHeader', EventSectionHeaderDirective);
})();
