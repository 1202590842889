import './goals.define.filter.measurements.js';
import './target.filters.description.js';
import './goal.slot.js';
import './target.linkedEvents.js';
// import './targetFilter.define.js';
import './goal.wrapper.js';
import './goals.define.js';
import './simple.goals.list.js';
import './goalset.periods.select.js';
import './target.title.js';
import './goals.field.js';
import './target.slot.js';
import './event.linkedTargets.js';
import './goals.define.period.measurements.js';
import './goalset.periods.js';
