(function() {
  'use strict';

  function AnnouncementSlotController($rootScope, $state, $scope, $uibModal, Announcements,
                                  Security, Notify, Utils, LocalizationService) {
    var ctrl = this;

    var now = new Date();
    ctrl.today = now.toISOString();
    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.showLimit = 2;

    /**
     * We need this bound to the controller so that we can
     * either display the edit URL or just the announcement
     * title in the view.
     * @type {Boolean}
     */
    ctrl.canEditAnnouncement = false;

    Security.hasPermission('announcements.edit')
      .then(function(res) {
        ctrl.canEditAnnouncement = res === undefined ? true : res;
      });

    ctrl.actionButtons = [
      {
        label: 'Edit',
        ariaLabel: 'Edit ' + $scope.item.doc.title,
        icon: 'icon-edit',
        href: (function() {
          return $state.href('epf.announcements.edit', { id: $scope.item.doc._id });
        })(),
        showCondition: Security.hasPermission.bind(this, 'announcements.edit'),
        klass: 'text-primary'
      },
      {
        label: 'Delete',
        ariaLabel: 'Delete ' + $scope.item.doc.title,
        icon: 'icon-trash',
        onClick: function() {
          Utils.swal({
            title: 'Are you sure you want to remove this announcement?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes'
          },
          function(isConfirm) {
            if (isConfirm) {
              var id = $scope.item.doc._id;
              Announcements.remove(id)
                .then(function() {
                  $rootScope.$broadcast('KZItemRemoved', { item: $scope.item });
                  Notify.success('Announcement successfully removed!', 'Success');
                  $state.go('epf.announcements.index');
                });
            }
          });
        },
        showCondition: Security.hasPermission.bind(this, 'announcements.edit'),
        klass: 'text-danger'
      },
      {
        label: 'Show audit log',
        ariaLabel: 'Show audit log for ' + $scope.item.doc.title,
        icon: 'icon-history',
        onClick: function() {
          ctrl.shownDescription = !ctrl.shownDescription;
          this.label = ctrl.shownDescription ? 'Hide audit log' : 'Show audit log';
        },
        showCondition: function() {
          return !_.isUndefined($scope.item.doc.dates);
        },
        klass: 'text-info'
      }
    ];

    ctrl.secondaryActionButtons = [
      {
        label: 'Preview',
        ariaLabel: 'Preview ' + $scope.item.doc.title,
        icon: 'icon-view',
        onClick: function() {
          $uibModal.open({
            animation: true,
            size: 'md',
            templateUrl: 'app/components/announcements/preview.html',
            controller: 'AnnouncementPreviewController',
            controllerAs: 'announcementPreviewCtrl',
            resolve: {
              announcementDoc: function() {
                return $scope.item.doc;
              },
              allowDismiss: function() {
                return false;
              },
              markAsRead: function() {
                return false;
              }
            }
          });
        },
        klass: 'text-info'
      }
    ];
  }

  function AnnouncementSlotDirective() {
    return {
      scope: {
        item: '=',
        onRemove: '&'
      },
      restrict: 'AE',
      templateUrl: 'app/components/announcements/directives/announcement.slot.html',
      controller: AnnouncementSlotController,
      controllerAs: 'ctrl'
    };
  }

  AnnouncementSlotController.$inject = [
    '$rootScope',
    '$state',
    '$scope',
    '$uibModal',
    'AnnouncementsService',
    'SecurityService',
    'NotifyService',
    'UtilsService',
    'LocalizationService'
  ];

  angular.module('component.announcements')
    .directive('announcementSlot', AnnouncementSlotDirective);
})();
