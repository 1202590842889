import './settings.module.js';
import './status.card.js';
import './offlinetest.controller.js';
import './settings.controller.js';
import './review.slot.js';
import './settings.route.js';
import './settings.cards.js';
import './offlinesettings.controller.js';
import './offline.banner.js';
import './cleanup.banner.js';
import './settings.service.js';
import './review.controller.js';
import './status.controller.js';
import './offlineresult.directive.js';
