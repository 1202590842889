(function() {
  'use strict';

  function MergeUsersController($scope, $state, AsyncTasks, Notify, Utils) {
    var ctrl = this;

    $scope.readFile = function(event) {
      ctrl.mapping = {};
      ctrl.fileColumns = undefined;
      ctrl.kzFields = undefined;
      ctrl.fileError = undefined;

      ctrl.bulkChangesParams.file = {};

      var file = event.target.files[0];
      if (_.isUndefined(file)) {
        return;
      }

      if (_.indexOf(['text/csv', 'application/vnd.ms-excel'], file.type) === -1) {
        ctrl.fileError = 'File with type ' + file.type + ' is not allowed to be uploaded, only ' +
          'csv is.';
      }

      // no more than 10 Mo
      if (file.size > 10 * 1000 * 1000) {
        ctrl.fileError = 'The file should not exceed 10Mb';
      }

      if (ctrl.fileError) {
        return;
      }

      var reader = new FileReader();

      reader.readAsText(file);
      reader.onload = function() {
        ctrl.bulkChangesParams.csv = {
          name: file.name,
          type: file.type,
          size: file.size,
          lastModifiedDate: file.lastModifiedDate,
          content: btoa(unescape(encodeURIComponent(reader.result))),
          columns: reader.result.split(/\r?\n/)[0].split(',')
        };
      };
      reader.onerror = function() {
        console.log(reader.error);
      };
    };

    this.$onInit = function() {
      ctrl.loaded = true;
    };

    this.downloadTemplate = function() {
      var data = 'src_profile_id,dest_profile_id';
      var fileName = 'merge_users.csv';
      Utils.downloadCSV(data, fileName);
    };

    this.startMergingUsers = function(bulkChangesParams) {
      AsyncTasks.start(bulkChangesParams.taskAction, bulkChangesParams)
        .then(function(taskId) {
          Notify.success('Task to merge users added, please wait...', 'Success!');
          $state.go('epf.importer.view', { id: taskId });
        });
    };
  }

  MergeUsersController.$inject = [
    '$scope',
    '$state',
    'AsyncTasksService',
    'NotifyService',
    'UtilsService'
  ];

  angular.module('component.importer')
    .component('mergeUsers', {
      templateUrl: 'app/components/importer/components/mergeUsers.html',
      controller: MergeUsersController,
      bindings: { bulkChangesParams: '<' }
    });
})();
