(function() {
  'use strict';

  function MenuPlusButtonDirective() {
    function MenuPlusButtonController($state, $uibModal, Auth, EventTypes, Security, SweetAlert,
                                      Utils) {
      var ctrl = this;

      // this may be also used for the details view, move it if necessary
      var actions = [
        { label: 'Event', route: 'epf.events.new', permission: 'events.create.own' },
        {
          label: 'Goal',
          links: function() {
            return EventTypes.findAvailableFor(Auth.currentUser())
              .then(function(eventTypes) {
                return _.filter(eventTypes, function(eventType) {
                  if (!eventType.doc.sections || !eventType.doc.sections.length) {
                    return false;
                  }

                  var goalField = _.find(eventType.doc.sections[0].fields, function(field) {
                    return field.type === 'goal';
                  });
                  return goalField;
                });
              })
              .then(function(eventTypesWithGoals) {
                return _.map(eventTypesWithGoals, function(eventType) {
                  return {
                    label: eventType.doc.name,
                    href: (
                      function() {
                        return $state.href(
                          'epf.events.section-new',
                          { eventTypeId: eventType.doc._id }
                        );
                      }
                    )()
                  };
                });
              });
          },
          permission: 'eventTypes.edit'
        },
        { label: 'Document', route: 'epf.documents.index', permission: 'documents.view.own' },
        { label: 'Event Type', route: 'epf.event-types.new', permission: 'eventTypes.edit' },
        { label: 'FAQ', href: 'epf.faqs.new', permission: 'faqs.edit' },
        { label: 'Blueprint', route: 'epf.blueprints.new', permission: 'blueprints.edit' },
        { label: 'Announcement', route: 'epf.announcements.new', permission: 'announcements.edit' },
        { label: 'User', route: 'epf.users.new', permission: 'users.create' },
        { label: 'User Field', route: 'epf.user-fields.new', permission: 'userFields.edit' },
        { label: 'Roles', route: 'epf.roles.new', permission: 'roles.edit' },
        { label: 'Relations', route: 'epf.relations.new', permission: 'relations.edit' }
      ];

      var checkPermission = function(link) {
        var hasPerm = Security.hasPermission(link.permission, { noCatch: true });
        return _.isUndefined(link.permission) ? true : hasPerm;
      };

      ctrl.links = [];
      Utils.asyncFilter(actions, checkPermission)
        .then(function(actionsFiltered) {
          ctrl.links = _.map(actionsFiltered, function(action) {
            if (!_.isUndefined(action.route)) {
              action.href = (function() { return $state.href(action.route); })();
            }

            return action;
          });
        });

      ctrl.openLinksDialog = function(links) {
        ctrl.closeDialog = function() {
          SweetAlert.close();
        };

        $uibModal.open({
          animation: true,
          templateUrl: 'app/layout/header/partials/action-menu.html',
          controller: [
            '$q',
            '$scope',
            '$uibModalInstance',
            'links',
            'openLinksDialog',
            function($q, $scope, $uibModalInstance, links, openLinksDialog) {
              $scope.openLinksDialog = openLinksDialog;

              var prom;
              if (_.isFunction(links)) {
                prom = links();
              } else {
                prom = $q.when(links);
              }

              prom
                .then(function(links) {
                  $scope.links = links;
                });


              $scope.dismiss = function() {
                $uibModalInstance.dismiss('cancel');
              };
            }
          ],
          size: 'md',
          resolve: {
            links: function() {
              return links;
            },
            openLinksDialog: function() {
              return ctrl.openLinksDialog;
            }
          }
        });
      };
    }

    MenuPlusButtonController.$inject = [
      '$state',
      '$uibModal',
      'AuthService',
      'EventTypesService',
      'SecurityService',
      'SweetAlert',
      'UtilsService'
    ];

    return {
      restrict: 'EA',
      templateUrl: 'app/layout/header/kz-menu-plus-button.html',
      replace: true,
      controller: MenuPlusButtonController,
      controllerAs: 'plusButtonCtrl'
    };
  }

  angular.module('kzHeaderCard')
    .directive('kzMenuPlusButton', MenuPlusButtonDirective);
})();
