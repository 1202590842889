(function() {
  'use strict';

  function AnnouncementsController(
    $state, $rootScope, Announcements, AnnouncementsUtils, List, Security, Utils
  ) {
    var ctrl = this;

    Utils.setPageTitle('Announcements');

    /**
     * Return a list of possible actions the user can take in the list.
     * @param  {Boolean} showReorder Whether to show the Reorder action or not.
     * @return {Array}               A list of possible actions.
     */
    var getActions = function(showReorder) {
      var actions = [{
        label: 'New',
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.announcements.new'); })(),
        showCondition: Security.hasPermission.bind(this, 'announcements.edit'),
        klass: 'btn-success'
      }];

      if (showReorder) {
        actions.push({
          label: 'Reorder',
          icon: 'icon-move',
          href: (function() { return $state.href('epf.announcements.reorder'); })(),
          showCondition: Security.hasPermission.bind(this, 'announcements.edit'),
          klass: 'btn-border btn-info'
        });
      }

      return actions;
    };

    ctrl.list = new List({
      search: AnnouncementsUtils.search,
      model: AnnouncementsUtils.searchModel,
      idField: 'doc._id'
    });
    $rootScope.$broadcast(
      'KZCurrentListChange',
      { list: 'announcements', search: ctrl.list.search }
    );

    ctrl.options = {
      trackBy: 'doc._id',
      borderClass: AnnouncementsUtils.getBorderClass
    };

    Announcements.findAll({ cached: false })
      .then(function(items) {
        items = _.chain(items)
                 .sortBy(function(item) { return item.doc.order; })
                 .value();

        var unOrderedAnnouncements = items.filter(function(item) {
          return AnnouncementsUtils.canBeReordered(item.doc);
        });

        ctrl.actions = getActions(unOrderedAnnouncements.length > 1);

        ctrl.list.doLoadItems(items);
      });
  }

  AnnouncementsController.$inject = [
    '$state',
    '$rootScope',
    'AnnouncementsService',
    'AnnouncementsUtils',
    'ListFactory',
    'SecurityService',
    'UtilsService'
  ];

  angular.module('component.announcements')
    .controller('AnnouncementsController', AnnouncementsController);
})();
