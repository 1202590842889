(function() {
  'use strict';

  function AnnouncementsService($q, ConfService, UserStore, Profile, Roles, Relations, Utils,
                                AnnouncementsUtils) {
    var service = new ConfService('announcement');

    /**
     * Find the order property for the last announcement,
     * according to the user's order.
     * @return {Number} The order.
     */
    service.findOrderIndexForLast = function() {
      return service.findAll()
        .then(function(items) {
          return _.chain(items)
                      .map(function(item) { return item.doc; })
                      .sortBy('order') // we sort them descendingly by order
                      .pluck('order') // we only need the order properties
                      .last() // we want the last order
                      .value();
        });
    };

    /**
     * Modify the announcement to contain the audit log dates.
     * @param  {Object}   doc         The announcement being modified.
     * @param  {Object}   originalDoc The original announcement for comparison.
     * @param  {Boolean}  isNew       Whether the document already exists or not.
     * @param  {String}   actor       Who is making the change.
     * @return {Promise}              A manually created promise so it can be chained.
     */
    service.saveAuditLog = function(doc, originalDoc, isNew, actor) {
      if (isNew) {
        Utils.recordLog(doc, 'created', actor);
      } else {
        // has the status been changed to unpublish?
        if (originalDoc.status !== 'unpublish' && doc.status === 'unpublish') {
          Utils.recordLog(doc, 'unpublished', actor);
        }

        // has the status been changed to active?
        if (originalDoc.status === 'unpublish' && doc.status === 'active') {
          Utils.recordLog(doc, 'published', actor);
        }
      }

      return $q.when(doc);
    };

    /**
     * Save the order for an announcement.
     * @param  {String} id    The ID of the announcement.
     * @param  {Number} order The new order.
     * @return {Promise}      A promise from the Store.
     */
    service.saveOrder = function(id, order) {
      return service.find(id)
        .then(function(announcement) {
          announcement.order = order;
          return service.save(announcement);
        });
    };

    /**
     * Return all the announcement extras.
     * @return {Promise}        A promise.
     */
    service.findAllExtras = function() {
      return UserStore.findAll('announcementExtra')
        .then(function(data) {
          return _.map(data, 'doc');
        });
    };

    /**
     * Return extras for one announcement.
     * @return {Promise}        A promise.
     */
    service.findAllExtrasFor = function(id) {
      return service.findAllExtras().then(function(data) {
        return _.find(data, function(doc) {
          return doc.announcement === id;
        });
      });
    };

    /**
     * Return all the announcements for a given user.
     * @param  {String} user    The user's username.
     * @return {Promise}        A manually created promise.
     */
    service.findAllForUser = function(user) {
      return service.findAll()
        .then(function(announcements) {
          return _.chain(announcements)
                  .map(function(announcement) { return announcement.doc; })
                  .filter(function(announcement) {
                    // We only want the current announcements for the display
                    return AnnouncementsUtils.isCurrent(announcement);
                  })
                  .sortBy('order') // sorted descendingly by order
                  .value();
        })
        .then(function(announcements) {
          return $q.all([
            Profile.getRoles(), // get the roles for the current user
            Profile.getParentRelations(), // get the relations for the current user
            announcements,
            service.findAllExtras()
          ]);
        })
        .then(function(data) {
          return _.chain(data[2])
                  .filter(function(announcement) {
                    // Only the visible announcements
                    return AnnouncementsUtils.isVisible(announcement, data[0], data[1], user);
                  })
                  .map(function(announcement) {
                    // Figure out if an announcement is read and/or dismissed
                    return AnnouncementsUtils.markAnnouncement(announcement, user, data[3]);
                  })
                  .value();
        });
    };

    /**
     * Add user extra to the database.
     * This is called when a user reads or dismisses an announcement.
     * @param {Promise} extra A promise from the userDb.
     */
    service.addUserExtra = function(extra) {
      return UserStore.save('announcementExtra', extra);
    };

    /**
     * Get the roles and relations for the announcement visibility form.
     * @return {Promise} A promise resolved for both roles and relations.
     */
    service.getVisibilityFormData = function() {
      return $q.all([
        Roles.findAll(),
        Relations.findAll({ cache: 'cached' })
      ]);
    };

    return service;
  }

  AnnouncementsService.$inject = [
    '$q',
    'ConfService',
    'BaseUserStoreService',
    'ProfileService',
    'RolesService',
    'RelationsService',
    'UtilsService',
    'AnnouncementsUtils'
  ];

  angular.module('component.announcements')
    .factory('AnnouncementsService', AnnouncementsService);
})();
