(function() {
  'use strict';

  function EventSectionEditService($q, $rootScope, EventSection, EventSections, Goal, AutoSave) {
    var service = {};

    service.isSet = false;

    service.setModel = function(data, autoSaveOpts) {
      autoSaveOpts = autoSaveOpts || {};
      if (autoSaveOpts.preserveState) {
        return;
      }
      service.isSet = true;
      service.isNew = data.isNew;
      service.eventSection = data.eventSection;
      service.eventExtras = data.eventExtras;
      service.autoSaveInfo = service.getAutoSaveInfo(autoSaveOpts);
    };

    service.clearModel = function() {
      service.isSet = false;
      delete service.isNew;
      delete service.eventSection;
      delete service.eventExtras;
      delete service.autoSaveInfo;
    };

    service.init = function(action, defaults, autoSaveOpts) {
      autoSaveOpts = autoSaveOpts || {};
      if (!autoSaveOpts.preserveState) {
        if (service.isSet) {
          console.warn('ESEDIT: It is set');
          service.clearModel();
        } else {
          console.info('ESEDIT: Not set all ok');
        }
      }
      defaults = _.assignIn({}, defaults || {});
      if (action === 'draft') {
        defaults.quickDraft = true;
        return service.initNew(defaults, autoSaveOpts);
      } else if (action === 'new') {
        if (!defaults.eventType) {
          return $q.reject({ status: 400, message: 'You must select an event type!' });
        }
        return service.initNew(defaults, autoSaveOpts);
      } else if (action === 'edit') {
        return service.initExisting(defaults, autoSaveOpts);
      }
      throw new Error('Unknown action');
    };

    service.initNew = function(defaults, autoSaveOpts) {
      defaults = defaults || {};
      var options = {};
      if (!defaults.eventOwner) {
        return $q.reject({ status: 400, message: 'You must select event owner!' });
      }

      var eventSection = new EventSection();
      var eventExtras = eventSection.getInitialExtra();

      var initial = _.assignIn({}, defaults, { relatedExtra: eventExtras._id });
      return eventSection.setInitial(initial)
        .then(function() {
          return eventSection.checkPermission('canCreate');
        })
        .then(function() {
          // This will allow the basic form to have tags and documents form fields.
          options.allowBasicFormExtras = eventSection.isMineEvent();
          options.allowLinking = eventSection.isMineEvent();
          service.setModel({
            isNew: true,
            eventSection: eventSection,
            eventExtras: eventExtras
          }, autoSaveOpts);
          options.autoSaveInfo = service.autoSaveInfo;
          return AutoSave.setupAutoSave(options.autoSaveInfo);
        })
        .then(function() {
          options.autosaveisEnabled = AutoSave.isEnabled(options.autoSaveInfo);
          // if (options.allowLinking) {
          //   return service.loadAutoLinkedTargets(eventSection);
          // }
        })
        .then(function() {
          return options;
        });
    };

    service.initExisting = function(defaults, autoSaveOpts) {
      var options = {};
      if (!defaults.eventSection) {
        return $q.reject({ status: 400, message: 'The event section should be already loaded' });
      }
      var eventSection = defaults.eventSection;
      var eventExtras;
      if (eventSection.doc.state !== 'draft') {
        return $q.reject({
          status: 403,
          message: 'The event is not in a draft state any more. It is not possible to edit'
        });
      }

      return eventSection.init()
        .then(function() {
          return eventSection.noPending();
        })
        .then(function() {
          return eventSection.loadOriginalEvent();
        })
        .then(function() {
          options.allowBasicFormExtras = eventSection.isMineEvent();
          return service.getExtra(eventSection, options)
            .then(function(extra) {
              eventExtras = extra;
              eventSection.doc.relatedExtra = extra._id;
            });
        })
        .then(function() {
          service.setModel({
            isNew: false,
            eventSection: eventSection,
            eventExtras: eventExtras
          }, autoSaveOpts);
          options.autoSaveInfo = service.autoSaveInfo;
          return AutoSave.setupAutoSave(options.autoSaveInfo);
        })
        .then(function() {
          options.autosaveisEnabled = AutoSave.isEnabled(options.autoSaveInfo);
          options.allowLinking = eventSection.isMineEvent() && eventSection.getCurrentIndex() < 1;
          // if (options.allowLinking && !_.isUndefined(eventSection.doc.meta.linkedTargets)) {
          //   // check if already linkedTargets do not have the eventVersionGroupId attr
          //   var found = _.filter(eventSection.doc.meta.linkedTargets, function(target) {
          //     return !_.has(target, 'eventTypeVersionGroupId');
          //   });


          //   if (found) {
          //     // map targets with versionGroupId
          //     return Goal.findAll()
          //       .then(function(goals) {
          //         var mapGoals = {};
          //         _.forEach(goals, function(goal) {
          //           if (_.isUndefined(goal.event) || _.isEmpty(goal.event)) {
          //             return;
          //           }

          //           mapGoals[goal.doc._id] = goal.event.eventType.versionGroupId;
          //         });

          //         eventSection.doc.meta.linkedTargets =
          //           _.chain(eventSection.doc.meta.linkedTargets)
          //           .map(function(target) {
          //             if (!_.has(target, 'eventTypeVersionGroupId')) {
          //               target.eventTypeVersionGroupId = mapGoals[target.goalId];
          //             }

          //             return target;
          //           })
          //           .value();
          //       });
          //   }
          // }

          // There was a progress here which is going to change
          return $q.when();
        })
        .then(function() {
          return options;
        });
    };

    service.getExtra = function(eventSection, options) {
      var promise = $q.when();
      if (options.allowBasicFormExtras && eventSection.doc.relatedExtra) {
        promise = eventSection.getExtra()
          .then(function(extras) {
            return _.find(extras, function(item) {
              return item._id === eventSection.doc.relatedExtra;
            });
          });
      }

      return promise
        .then(function(extra) {
          if (extra === undefined) {
            extra = eventSection.getInitialExtra();
          }
          return extra;
        });
    };

    service.loadAutoLinkedTargets = function(eventSection) {
      if (!eventSection.doc.eventType) {
        return $q.when();
      }
      var options = { eventType: eventSection.eventType.versionGroupId };
      if (!eventSection.isMineEvent()) {
        options.username = eventSection.doc.eventOwner;
      }

      return Goal.findLinkableTargets(options)
        .then(function(targets) {
          var autoLinkedTargets = _.chain(targets)
            .filter(function(target) {
              return target.isAutoLinked();
            })
            .map(function(target) {
              return {
                goalId: target.goal.doc._id,
                targetId: target._id,
                eventTypeVersionGroupId: target.goal.event.eventType.versionGroupId
              };
            })
            .value();

          var currentLinkedTargets = eventSection.doc.meta.linkedTargets;
          var merged = _.uniqBy(currentLinkedTargets.concat(autoLinkedTargets), function(item) {
            return item.targetId + '-' + item.goalId;
          });
          eventSection.doc.meta.linkedTargets = merged;
        });
    };

    service.getAutoSaveInfo = function(autoSaveOpts) {
      if (!service.isSet) {
        throw new Error('Service has not yet been set or was cleared');
      }

      return _.assignIn({}, autoSaveOpts, {
        isNew: service.isNew,
        fetchModel: function() {
          return $q.all([
            service.eventSection.fetchDoc(),
            service.eventSection.getExtra() // May need a nocache
          ])
            .then(function(result) {
              return {
                _id: result[0]._id,
                doc: result[0],
                extra: result[1].length > 0 ? result[1][0] : {}
              };
            });
        },
        getModel: function() {
          return {
            _id: service.eventSection.doc._id,
            doc: service.eventSection.doc,
            extra: service.eventExtras
          };
        },
        getMeta: function(model) {
          return {
            user: model.doc.user,
            organisation: model.doc.organisation,
            type: model.doc.type,
            _id: model.doc._id
          };
        },
        getSaveInfo: function(model) {
          return model.doc.saveInfo || {};
        },
        saveObject: function(model, saveInfo) {
          if (saveInfo !== undefined) {
            model.doc.saveInfo = saveInfo;
          }
          var action = model.doc._rev ? 'objectUpdated' : 'objectCreated';
          return service.eventSection.save(model.doc)
            .then(function() {
              if (!_.isEmpty(model.extra.blueprints)) {
                return service.eventSection.addExtra(model.extra, { noLog: true });
              }
            })
            .then(function() {
              return { action: action };
            });
        },
        removeObject: function(model) {
          return EventSections.remove(model.doc._id);
        },
        validate: function(model) {
          var doc = model.doc;
          if (doc.state !== 'draft') {
            return $q.reject({
              status: 403,
              message: 'The response is not in a draft state any more'
            });
          }
          return $q.when(model);
        },
        toAutoSave: function(model) {
          return {
            doc: _.pick(model.doc, 'data', 'nextSection', 'meta', 'invitation'),
            extra: _.pick(model.extra, 'blueprints')
          };
        },
        toObject: function(model, data) {
          _.assignIn(model.doc, data.doc);
          _.assignIn(model.extra, data.extra);
        },
        applyAutosave: function(model, asdoc) {
          return {
            _id: model._id,
            doc: _.assignIn({}, model.doc, asdoc.data.doc),
            extra: _.assignIn({}, model.extra, asdoc.data.extra)
          };
        },
        override: function(oldmodel, newmodel) {
          oldmodel.doc._rev = newmodel.doc._rev;
          oldmodel.extra._rev = newmodel.extra._rev;
        }
      });
    };

    service.save = function(action) {
      if (!service.isSet) {
        throw new Error('Service has not yet been set or was cleared');
      }

      var eventSection = service.eventSection;

      var promise = $q.when();
      if (action === 'publish') {
        eventSection.clearUnknownFields();

        promise = promise.then(function() {
          return eventSection.validateForPublish();
        });
      }

      // Confirmation around starting goals

      return promise
        .then(function() {
          return AutoSave.manualSave(service.autoSaveInfo);
        })
        .then(function() {
          if (action === 'publish') {
            return eventSection.publish()
              .then(function() {
                return AutoSave.removeAll(service.autoSaveInfo);
              });
          }
        })
        .then(function() {
          var msg;
          if (action === 'publish') {
            msg = 'Event successfully submitted!';
          } else {
            msg = 'Your progress has been saved!';
          }
          $rootScope.$broadcast('KZStoreInvalidated', { type: 'event' });
          return msg;
        })
        .catch(function(error) {
          var message = (error && error.message) || 'Unknown error';
          if (error && error.status === 412) {
            var fields = [];
            _.forOwn(error.errors || {}, function(err) {
              fields.push(err);
            });
            if (!_.isEmpty(fields)) {
              message = message + ': ' + fields.join(', ');
            }
          }
          var status = (error && error.status) || 500;
          return $q.reject({ status: status, message: message });
        });
    };

    /**
     * showNextSection return true if the invitation box should be shown
     *
     * !! It is not whether the form should be shown
     */
    service.showNextSection = function(nextDefSection) {
      // If there is no next section, do not display it
      if (_.isUndefined(nextDefSection)) {
        return false;
      }

      // If next section is multisource we do not show next section as it will get into
      // Awaiting responses state
      if (
        _.indexOf(nextDefSection.filledBy, 'system:anonymous-external') > -1
      ) {
        return false;
      }

      // Goals get into In progress till they are moved further
      if (service.eventSection.containsGoal && !service.eventSection.containsAutoCloseGoal) {
        return false;
      }

      return true;
    };

    service.getFillOnSameDevice = function(defSection) {
      if (!defSection) {
        return {};
      }

      return _.assign({
        enabled: true,
        defaultVisibility: 'collapsed',
        changable: true,
        confirmationType: 'default'
      }, defSection.fillOnSameDevice || {});
    };

    service.getActionTitle = function() {
      if (service.eventSection.eventType === undefined) {
        return 'Submit';
      }
      if (service.eventSection.currentDefSection.actionTitle) {
        return service.eventSection.currentDefSection.actionTitle;
      }
      if (service.eventSection.containsGoal) {
        return 'Start goals';
      }
      return 'Submit';
    };

    return service;
  }

  EventSectionEditService.$inject = [
    '$q',
    '$rootScope',
    'EventSectionFactory',
    'EventSectionsService',
    'GoalFactory',
    'AutoSaveService'
  ];

  angular.module('component.events')
    .factory('EventSectionEditService', EventSectionEditService);
})();
