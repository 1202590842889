import './relations.module.js';
import './filters';
import './partials';
import './relations.directives.js';
import './relations.route.js';
import './relations.service.js';
import './relation.controller.js';
import './relations.utils.js';
import './relations-reorder.controller.js';
import './relation.search.js';
import './relations.controller.js';
