import { isArray } from 'lodash';

(function() {
  'use strict';

  function ReportFieldService(
    $q,
    $log,
    Blueprints,
    Relations,
    Users,
    UserStubs,
    UserFields,
    Roles,
    EventTypes,
    EventTypesStore,
    Utils,
    EVENT_STATES,
    USER_STATES,
    REPORT_CONDITIONS,
    REPORT_FILTERS,
    REPORT_OUTPUT_FIELDS,
    REPORT_SORT_FIELDS,
    TREE_OPTIONS,
    GOALS_STATES
  ) {
    function eventFields(options) {
      return EventTypes.getOptions(options);
      // This may be used later for offline
      //
      // var parseField = function(field, eid) {
      //   return {
      //     _id: '__et__' + eid + '_' + field._id,
      //     name: field.name || field.type,
      //     categories: []
      //   };
      // };

      // var parseSection = function(section, idx, eid) {
      //   var cat = {
      //     _id: '__et__' + eid + '_' + section._id,
      //     name: 'Section ' + (idx + 1),
      //     categories: []
      //   };
      //   var fields = section.fields || [];
      //   fields.forEach(function(field) {
      //     cat.categories.push(parseField(field, eid));
      //   });
      //   return cat;
      // };

      // var parseEventType = function(et, idx) {
      //   var name = et.name + ' (v' + (idx + 1) + ')';
      //   var cat = { _id: et._id, name: name, categories: [] };
      //   var sections = et.sections || [];
      //   sections.forEach(function(section, idx) {
      //     cat.categories.push(parseSection(section, idx, et._id));
      //   });
      //   return cat;
      // };

      // var buildVersionsTree = function(versions) {
      //   return _.chain(versions)
      //     .map(function(version) {
      //       var parent = version;
      //       var categories = _.map(parent.linkedVersions, function(version, idx) {
      //         return parseEventType(version, idx);
      //       });

      //       return {
      //         _id: '__last__' + parent.doc._id,
      //         name: parent.doc.name,
      //         categories: categories
      //       };
      //     })
      //     .sortBy('name')
      //     .value();
      // };


      // return EventTypes.findLatestAvailableGrouped()
      //   .then(function(availableVersions) {
      //     return buildVersionsTree(availableVersions);
      //   });
    }

    var service = {};


    // function toEventTypeOptions(versions) {
    //   return _.chain(versions)
    //     .map(function(version) {
    //       return {
    //         _id: version.doc.versionGroupId,
    //         key: version.doc.versionGroupId,
    //         name: version.doc.name
    //       };
    //     })
    //     .sortBy('name')
    //     .value();
    // }

    var loaders = {};

    /** Option loaders **/
    loaders.eventType = function() {
      return EventTypesStore.fetch({
        purpose: 'latestPublished',
        size: 10000
      }, { transformType: 'stub' }).then(function(eventTypes) {
        return _.map(eventTypes, function(eventType) {
          return {
            _id: eventType.versionGroupId,
            key: eventType.versionGroupId,
            name: eventType.name
          };
        });
      });
    };

    loaders.goalResolveStates = function() {
      var states = _.map(GOALS_STATES, function(value) {
        return {
          _id: value.id,
          key: value.name,
          name: value.name
        };
      });
      return $q.when(states);
    };

    loaders.goalSetStates = function() {
      return $q.when([
        {
          _id: 'open',
          key: 'open',
          name: 'Open'
        },
        {
          _id: 'closed',
          key: 'closed',
          name: 'Closed'
        }
      ]);
    };

    loaders.eventType_versionGroupId = function(opts) {
      var options = opts || {};

      var eventTypesProm;
      if (options.findByRoles) {
        var eventTypeId = options.findByRoles;
        eventTypesProm = EventTypes.findAvailableByRoles(eventTypeId);
      } else {
        eventTypesProm = loaders.eventType();
      }

      return eventTypesProm
        .then(function(items) {
          // var options = toEventTypeOptions(versions);

          if (options.includeAllOption) {
            var anyOption = [{ _id: '__all__', key: '__all__', name: 'Any' }];
            return items.unshit(anyOption);
          }

          return items;
        });
    };

    /** Field extenders */
    loaders.eventState = function() {
      var states = [];
      _.forOwn(EVENT_STATES, function(item) {
        states.push({ _id: item.id, key: item.id, name: item.title });
      });
      return states;
    };

    loaders.userState = function() {
      var states = [];
      _.forOwn(USER_STATES, function(item) {
        states.push({ _id: item.id, key: item.id, name: item.name });
      });
      return states;
    };

    loaders.roles = function() {
      return Roles.findAll()
        .then(function(roles) {
          return _.map(roles, function(item) {
            return { _id: item.doc._id, key: item.doc._id, name: item.doc.title };
          });
        });
    };

    loaders.blueprintCategory = function() {
      return {
        controller: ['$scope', function($scope) {
          $scope.tree = [];
          Blueprints.findAll()
            .then(function(data) {
              $scope.tree = _.chain(data)
                // .filter(function(obj) {
                //   return _.indexOf(
                //     ['discrete', 'discrete_multiple', 'tree'], obj.doc.blueprintType
                //   ) !== -1;
                // })
                .map(function(obj) {
                  var categories = [];
                  var prefixId = function(prefix, cat) {
                    return {
                      _id: prefix + cat._id,
                      name: cat.name,
                      categories: _.map(cat.categories || [], prefixId.bind(this, prefix))
                    };
                  };
                  categories = _.map(obj.doc.categories, prefixId.bind(this, obj.doc._id + ':'));
                  return { _id: obj.doc._id, name: obj.doc.name, categories: categories };
                })
                .sortBy('name')
                .value();
            });

          $scope.nodeTemplate = { id: '_id', title: 'name', nodes: 'categories' };
          $scope.treeOptions = _.assign(angular.copy(TREE_OPTIONS), { inline: false, limit: 0 });
        }],
        id: 'blueprint',
        type: 'tree',
        label: 'Show only this blueprint',
        required: false,
        ngModelAttrs: {
          sequoiaTree: {
            bound: 'sequoia-tree',
            attribute: 'sequoia-tree'
          },
          nodeTemplate: {
            bound: 'node-template',
            attribute: 'node-template'
          },
          options: {
            bound: 'treeOptions',
            attribute: 'options'
          }
        },
        ngModelAttrsValues: [
          {
            name: 'nodeTemplate',
            value: 'nodeTemplate'
          },
          {
            name: 'options',
            value: 'treeOptions'
          },
          {
            name: 'sequoiaTree',
            value: 'tree'
          }
        ]
      };
    };

    var cachedOptionsBlueprints = [];

    loaders.blueprint = function(fltr) {
      fltr = fltr || {};
      var canCache = fltr.blueprints === undefined;

      var loader = function() {
        var options;
        if (canCache && !_.isEmpty(cachedOptionsBlueprints)) {
          options = $q.when(cachedOptionsBlueprints);
        } else {
          options = Blueprints.findAll()
            .then(function(data) {
              if (fltr.blueprints) {
                data = _.filter(data, function(itm) {
                  return _.indexOf(fltr.blueprints, itm.doc._id) !== -1;
                });
              }
              var tree = _.chain(data)
                .map(function(obj) {
                  var categories = obj.doc.categories;
                  // likert should be concidered as numeric even it contains categories
                  if (!fltr.likertsAsDiscrete && obj.doc.blueprintType === 'likert') {
                    categories = [];
                  }
                  return { _id: obj.doc._id, name: obj.doc.name, categories: categories };
                })
                .sortBy('name')
                .value();


              if (tree.length === 1) {
                tree = tree[0].categories;
              }

              if (fltr.allowAll) {
                tree = angular.copy(tree);
                tree.unshift({ _id: '__all__', key: '__all__', name: 'Any', categories: [] });
              }
              if (canCache) {
                cachedOptionsBlueprints = angular.copy(tree);
              }
              return tree;
            });
        }

        return options.then(function(tree) {
          if (Utils.suitableForSuperSimpleField(tree)) {
            return {
              id: fltr.id,
              type: 'discrete_multiple',
              label: fltr.label || 'Show only this blueprint',
              required: false,
              options: tree
            };
          }
          return {
            controller: ['$scope', function($scope) {
              $scope.tree = tree;
              $scope.nodeTemplate = { id: '_id', title: 'name', nodes: 'categories' };
              $scope.treeOptions = _.assign(
                angular.copy(TREE_OPTIONS),
                { inline: false, limit: 0 }
              );
            }],
            id: fltr.id,
            type: 'tree',
            label: fltr.label || 'Show only this blueprint',
            required: false,
            ngModelAttrs: {
              sequoiaTree: {
                bound: 'sequoia-tree',
                attribute: 'sequoia-tree'
              },
              nodeTemplate: {
                bound: 'node-template',
                attribute: 'node-template'
              },
              options: {
                bound: 'treeOptions',
                attribute: 'options'
              }
            },
            ngModelAttrsValues: [
              {
                name: 'nodeTemplate',
                value: 'nodeTemplate'
              },
              {
                name: 'options',
                value: 'treeOptions'
              },
              {
                name: 'sequoiaTree',
                value: 'tree'
              }
            ]
          };
        });
      };
      return {
        type: 'discrete',
        required: fltr.required,
        label: fltr.label,
        kzType: 'discrete',
        loader: loader
      };
    };

    loaders.relation = function(fltr) {
      fltr = fltr || {};
      var loader = function() {
        var options = Relations.findAll()
          .then(function(data) {
            if (fltr.relations) {
              data = _.filter(data, function(itm) {
                return _.indexOf(fltr.relations, itm.doc._id) !== -1;
              });
            }
            var categories;
            if (data.length === 1) {
              categories = data[0].doc.categories;
            } else {
              categories = _.map(data, function(row) {
                return { _id: row.doc._id, name: row.doc.name, categories: row.doc.categories };
              });
            }

            if (fltr.allowAll) {
              categories = angular.copy(categories);
              categories.unshift({
                _id: '__all__',
                key: '__all__',
                name: 'Any',
                categories: []
              });
            }
            return categories;
          });

        return options.then(function(tree) {
          if (Utils.suitableForSuperSimpleField(tree)) {
            return {
              id: fltr.id,
              type: 'discrete_multiple',
              label: fltr.label || 'Relation',
              required: false,
              options: tree
            };
          }
          return {
            controller: ['$scope', function($scope) {
              $scope.tree = tree;
              $scope.nodeTemplate = { id: '_id', title: 'name', nodes: 'categories' };
              $scope.treeOptions = _.assign(
                angular.copy(TREE_OPTIONS),
                { inline: false, limit: 0 }
              );
            }],
            id: fltr.id,
            type: 'tree',
            label: fltr.label || 'Relation',
            required: false,
            ngModelAttrs: {
              sequoiaTree: {
                bound: 'sequoia-tree',
                attribute: 'sequoia-tree'
              },
              nodeTemplate: {
                bound: 'node-template',
                attribute: 'node-template'
              },
              options: {
                bound: 'treeOptions',
                attribute: 'options'
              }
            },
            ngModelAttrsValues: [
              {
                name: 'sequoiaTree',
                value: 'tree'
              },
              {
                name: 'nodeTemplate',
                value: 'nodeTemplate'
              },
              {
                name: 'options',
                value: 'treeOptions'
              }
            ]
          };
        });
      };
      return {
        type: 'discrete',
        required: fltr.required,
        label: fltr.label || 'Relation',
        kzType: 'discrete',
        loader: loader
      };
    };

    loaders.user = function() {
      return {
        required: false,
        controller: ['$scope', function($scope) {
          var current = $scope.model[$scope.id];
          if (isArray(current)) {
            var promises = _.map(current, function(userId) {
              return UserStubs.find(userId)
                .then(function(user) {
                  return {
                    _id: user.user,
                    key: user.user,
                    name: _.trim((user.firstName || '') + ' ' + (user.lastName || '') +
                      ' - ' + (user.email || '')) || user.username
                  };
                });
            });
            $q.all(promises).then(function(res) {
              $scope.options.templateOptions.options = res;
            });
          }
          $scope.reload = function(search) {
            if (search.length < 3) {
              var mapped = [
                {
                  _id: '__current__',
                  key: '__current__',
                  name: 'Current user'
                },
                {
                  _id: '__all__',
                  key: '__all__',
                  name: 'All permissible users'
                }];
              $scope.options.templateOptions.options = mapped;
              return;
            }

            Users.findAll({ autocomplete_fullname: search })
              .then(function(data) {
                var mapped = data.hits.map(function(user) {
                  return {
                    _id: user.username,
                    key: user.username,
                    name: _.trim((user.firstName || '') + ' ' + (user.lastName || '') +
                      ' - ' + (user.email || '')) || user.username
                  };
                });

                mapped.splice(0, 0, {
                  _id: '__current__',
                  key: '__current__',
                  name: 'Current user'
                });
                $scope.options.templateOptions.options = mapped;
              });
          };
        }]
      };
    };

    /** Extra fields */

    loaders.eventProperty = function() {
      return {
        controller: ['$scope', function($scope) {
          $scope.tree = [];
          eventFields({ filterable: true }).then(function(cats) {
            _.assignIn($scope.tree, cats);
          });
          $scope.nodeTemplate = { id: '_id', title: 'name', nodes: 'categories' };
          $scope.treeOptions = _.assign(angular.copy(TREE_OPTIONS), { inline: false, limit: 1 });
        }],
        id: 'extra',
        type: 'tree',
        kzType: 'tree',
        label: 'Select output property',
        required: true,
        ngModelAttrs: {
          sequoiaTree: {
            bound: 'sequoia-tree',
            attribute: 'sequoia-tree'
          },
          nodeTemplate: {
            bound: 'node-template',
            attribute: 'node-template'
          },
          options: {
            bound: 'treeOptions',
            attribute: 'options'
          }
        },
        ngModelAttrsValues: [
          {
            name: 'nodeTemplate',
            value: 'nodeTemplate'
          },
          {
            name: 'options',
            value: 'treeOptions'
          },
          {
            name: 'sequoiaTree',
            value: 'tree'
          }
        ]
      };
    };

    loaders.periodChooser = function() {
      return {
        controller: ['$scope', function($scope) {
          $scope.tree = [];
          Blueprints.findAll().then(function(blueprints) {
            var filtered = _.filter(blueprints, function(item) {
              return (item.doc.usage || []).indexOf('goal_periods') !== -1;
            });
            _.assignIn($scope.tree, _.map(filtered, 'doc'));
          });
          $scope.nodeTemplate = { id: '_id', title: 'name', nodes: 'categories' };
          $scope.treeOptions = _.assign(angular.copy(TREE_OPTIONS), { inline: false, limit: 1 });
        }],
        id: 'extra',
        type: 'tree',
        kzType: 'tree',
        label: 'Select period',
        required: false,
        ngModelAttrs: {
          sequoiaTree: {
            bound: 'sequoia-tree',
            attribute: 'sequoia-tree'
          },
          nodeTemplate: {
            bound: 'node-template',
            attribute: 'node-template'
          },
          options: {
            bound: 'treeOptions',
            attribute: 'options'
          }
        },
        ngModelAttrsValues: [
          {
            name: 'nodeTemplate',
            value: 'nodeTemplate'
          },
          {
            name: 'options',
            value: 'treeOptions'
          },
          {
            name: 'sequoiaTree',
            value: 'tree'
          }
        ]
      };
    };

    loaders.periodChooser2 = function() {
      return {
        type: 'discrete',
        id: 'blueprintPeriod',
        label: 'Select period',
        required: true,
        options: Blueprints.findAll()
          .then(function(blueprints) {
            return _.map(blueprints, function(item) {
              return { _id: item.doc._id, key: item.doc._id, name: item.doc.name };
            });
          })
      };
    };

    loaders.blueprintChooser = function() {
      return {
        type: 'discrete_multiple',
        id: 'blueprint',
        label: 'Select blueprint',
        required: true,
        options: Blueprints.findAll()
          .then(function(blueprints) {
            return _.map(blueprints, function(item) {
              return { _id: item.doc._id, key: item.doc._id, name: item.doc.name };
            });
          })
      };
    };

    loaders.relationChooser = function() {
      return {
        type: 'discrete_multiple',
        id: 'relation',
        label: 'Select relation',
        required: true,
        options: Relations.findAll()
          .then(function(relations) {
            return _.map(relations, function(item) {
              return { _id: item.doc._id, key: item.doc._id, name: item.doc.name };
            });
          })
      };
    };

    loaders.userOutputField = function() {
      return {
        type: 'discrete',
        id: 'extraField',
        label: 'Select user field',
        required: true,
        options: UserFields.findAll({ includeSystemUserFields: true })
          .then(function(ufs) {
            var options = _(ufs)
              .map(function(item) {
                return {
                  _id: item.doc._id,
                  key: item.doc._id,
                  name: item.doc.name,
                  isSystem: item.doc.isSystem
                };
              })
              .sortBy(function(item) {
                return item.name;
              })
              .sortBy(function(item) {
                return item.isSystem;
              })
              .value();
            options.unshift({ _id: 'fullname', key: 'fullname', name: 'Full Name' });
            return options;
          })
      };
    };

    loaders.roleChooser = function() {
      return {
        type: 'discrete',
        id: 'role',
        label: 'Select role',
        required: true,
        options: loaders.roles()
      };
    };

    loaders.userField = function() {
      return {
        id: 'userField',
        controller: ['$scope', function($scope) {
          $scope.tree = [];
          UserFields.findAll()
            .then(function(res) {
              var cats = _.map(res, function(item) {
                return { _id: item.doc._id, name: item.doc.name, categories: [] };
              });
              _.assignIn($scope.tree, cats);
            });
          $scope.nodeTemplate = { id: '_id', title: 'name', nodes: 'categories' };
          $scope.treeOptions = _.assign(
            angular.copy(TREE_OPTIONS),
            { inline: false, limit: 1 }
          );
        }],
        type: 'tree',
        label: 'Select user field',
        required: true,
        ngModelAttrs: {
          sequoiaTree: {
            bound: 'sequoia-tree',
            attribute: 'sequoia-tree'
          },
          nodeTemplate: {
            bound: 'node-template',
            attribute: 'node-template'
          },
          options: {
            bound: 'treeOptions',
            attribute: 'options'
          }
        },
        ngModelAttrsValues: [
          {
            name: 'nodeTemplate',
            value: 'nodeTemplate'
          },
          {
            name: 'options',
            value: 'treeOptions'
          },
          {
            name: 'sequoiaTree',
            value: 'tree'
          }
        ],
        expressionProperties: {
          hide: function(_$viewValue, _$modelValue, scope) {
            return _.isUndefined(scope.model.field) || scope.model.field !== 'userField';
          }
        }
      };
    };

    loaders.goalProgress = function() {
      return {
        controller: ['$scope', function($scope) {
          $scope.tree = [];
          EventTypes.getGoalOptions().then(function(options) {
            _.assignIn($scope.tree, options);
          });
          $scope.nodeTemplate = { id: '_id', title: 'name', nodes: 'categories' };
          $scope.treeOptions = _.assign(angular.copy(TREE_OPTIONS), { inline: false, limit: 0 });
        }],
        id: 'goals',
        type: 'tree',
        kzType: 'tree',
        label: 'Select goals',
        required: false,
        ngModelAttrs: {
          sequoiaTree: {
            bound: 'sequoia-tree',
            attribute: 'sequoia-tree'
          },
          nodeTemplate: {
            bound: 'node-template',
            attribute: 'node-template'
          },
          options: {
            bound: 'treeOptions',
            attribute: 'options'
          }
        },
        ngModelAttrsValues: [
          {
            name: 'nodeTemplate',
            value: 'nodeTemplate'
          },
          {
            name: 'options',
            value: 'treeOptions'
          },
          {
            name: 'sequoiaTree',
            value: 'tree'
          }
        ]
      };
    };

    loaders.goalViewType = function() {
      return {
        type: 'discrete',
        id: 'view_type',
        label: 'Select view',
        required: true,
        options: [
          { _id: 'value', key: 'value', name: 'Progress value' },
          { _id: 'percentage', key: 'percentage', name: 'Percentage' },
          { _id: 'achieved', key: 'achieved', name: 'Met/Not met minimum progress value' },
          { _id: 'links', key: 'links', name: 'Number of linked events' },
          { _id: 'full', key: 'full', name: 'All detail' }
        ]
      };
    };

    loaders.achievedText = function() {
      return {
        type: 'string',
        id: 'achieved_text',
        label: '\'Met\' text',
        required: true,
        expressionProperties: {
          hide: function(_$viewValue, _$modelValue, scope) {
            return scope.model.view_type !== 'achieved';
          }
        }
      };
    };

    loaders.notAchievedText = function() {
      return {
        type: 'string',
        id: 'not_achieved_text',
        label: '\'Not met\' text',
        required: true,
        expressionProperties: {
          hide: function(_$viewValue, _$modelValue, scope) {
            return scope.model.view_type !== 'achieved';
          }
        }
      };
    };

    function getLoader(property, fld) {
      if (_.isUndefined(property)) {
        return;
      }

      if (_.isUndefined(loaders[property])) {
        $log.warn('Undefined loader', property);
        return;
      }

      var result = loaders[property](fld);
      return result;
    }

    service.getFilterFormFields = function(leadField, source, opts) {
      var options = opts || {};

      var conditions = [];
      var valueFields = [{ id: 'value', type: 'string', label: 'Value', required: true }];
      var extraField;

      if (!_.isUndefined(leadField)) {
        var defFil = REPORT_FILTERS[source][leadField];
        var availableConditions = angular.copy(defFil.availableConditions);
        if (defFil.extraConditions && options.includeExtraConditions) {
          _.forEach(defFil.extraConditions, function(extraCondition) {
            availableConditions.push(extraCondition);
          });
        }

        conditions = _.map(availableConditions, function(item) {
          return { _id: item, key: item, name: REPORT_CONDITIONS[item] };
        });

        var fltr = {
          field: leadField,
          id: 'value',
          label: 'Enter a value',
          required: false
        };

        var buildValueOptions = { includeExtraConditions: options.includeExtraConditions };
        valueFields = this.buildValueField(source, fltr, buildValueOptions);

        if (defFil.extraField) {
          extraField = this.buildExtraField(source, defFil);
        }
      }

      // FIXME: We have the same ID multiple times on the page
      // whenever we say field.id = 'field' or 'condition' etc.
      // I don't know if we rely on this hardcoded value anywhere else :(

      // options.dataTypeId used because it's different when defining filters on goals
      var fields = [
        {
          id: options.dataTypeId || 'field',
          type: 'discrete',
          label: 'Select data type',
          required: true,
          options: _.values(REPORT_FILTERS[source])
            .filter(function(item) {
              if (options.dataTypeToFilter) {
                return _.indexOf(options.dataTypeToFilter, item.id) > -1;
              } else if (options.dataTypeToExclude) {
                return _.indexOf(options.dataTypeToExclude, item.id) === -1;
              }

              return true;
            })
            .map(function(item) {
              return { _id: item.id, key: item.id, name: item.label };
            })
        }
      ];

      if (extraField) {
        fields.push(extraField);
      }

      fields.push(
        {
          id: 'condition',
          type: 'discrete',
          label: 'Select condition',
          required: true,
          options: conditions
        }
      );
      _.forEach(valueFields, function(valueField) {
        fields.push(valueField);
      });

      if (options.showVariableField) {
        fields.push({
          id: 'variable',
          type: 'discrete',
          label: 'User can override',
          required: false,
          options: [{ _id: false, key: false, name: 'No' }, { _id: true, key: true, name: 'Yes' }]
        });
      }

      if (options.showLabelField) {
        fields.push({
          id: 'label',
          type: 'string',
          label: 'Input label',
          required: true,
          expressionProperties: {
            hide: function(_$viewValue, _$modelValue, scope) {
              return !scope.model.variable;
            }
          }
        });
      }

      if (options.disableAll) {
        return _.map(fields, function(field) {
          field.disabled = true;
          return field;
        });
      }

      return fields;
    };

    service.buildField = function(source, fltr) {
      var def = REPORT_FILTERS[source][fltr.field] || {};
      var options = getLoader(def.optionsLoader);
      var type = def.type === 'timedelta' ? 'numeric' : def.type;

      var field = {
        id: fltr.id,
        type: type,
        label: fltr.label || def.label,
        kzType: type,
        default: fltr.value,
        options: options,
        required: fltr.required || false
      };

      var newField = getLoader(def.fieldExtender, fltr);
      if (!_.isUndefined(newField)) {
        field = _.assignIn(field, newField);
      }

      return field;
    };

    service.buildValueField = function(source, fltr, opts) {
      var options = opts || {};

      // build common valueField
      var valueField = this.buildField(source, fltr);
      if (!options.includeExtraConditions) {
        return [valueField];
      }

      var fields = [valueField];
      // build uncommon valueFields
      var def = REPORT_FILTERS[source][fltr.field] || {};
      _.forEach(def.extraConditions, function(extraCondition) {
        var extraField = getLoader(extraCondition);

        valueField.expressionProperties = {
          hide: function(_$viewValue, _$modelValue, $scope) {
            var cond = _.indexOf(def.extraConditions, $scope.model.condition) > -1;
            if (cond) {
              delete $scope.model.value;
            }
            return cond;
          }
        };

        // Lets tweak the ids after the loader has processed it.
        var origLoader = extraField.loader;
        extraField.loader = function() {
          return origLoader()
            .then(function(valueExtraField) {
              // construct a unique id to not confuse the frontend.
              valueExtraField.id = extraCondition + 'Value';
              valueExtraField.label = 'Value';
              valueExtraField.required = false;
              // valueExtraField.type = 'string';
              valueExtraField.expressionProperties = {
                hide: function(_$viewValue, _$modelValue, $scope) {
                  var cond = !$scope.model || $scope.model.condition !== extraCondition;
                  if (cond) {
                    delete $scope.model[valueExtraField.id];
                  }
                  return cond;
                }
              };
              return valueExtraField;
            });
        };
        fields.push(extraField);
      });

      return fields;
    };

    service.buildExtraField = function(_source, defFil) {
      return getLoader(defFil.extraField);
    };

    service.buildExtraOutField = function(_source, defFil) {
      return getLoader(defFil.extraOutField);
    };

    service.buildExtraOutFields = function(_source, defFil) {
      return _.filter(_.map(defFil.extraOutFields, function(fld) {
        return getLoader(fld);
      }), Boolean);
    };

    service.buildOutputOptions = function() {
      return eventFields();
    };

    service.buildSortFields = function(source, multiValue) {
      return service.buildSelectFields(source, multiValue, REPORT_SORT_FIELDS);
    };

    service.buildSelectFields = function(source, multiValue, defFields) {
      if (defFields === undefined) {
        defFields = REPORT_OUTPUT_FIELDS;
      }
      var displayField = {
        id: 'field',
        type: 'discrete',
        label: 'Select property',
        required: true,
        options: _.map(defFields[source], function(item) {
          return { _id: item.id, key: item.id, name: item.label };
        })
      };

      var propertyField = {
        id: 'eventProperty',
        controller: ['$scope', function($scope) {
          $scope.tree = [];
          eventFields().then(function(cats) {
            _.assignIn($scope.tree, cats);
          });
          $scope.nodeTemplate = { id: '_id', title: 'name', nodes: 'categories' };
          $scope.treeOptions = _.assign(
            angular.copy(TREE_OPTIONS),
            { inline: false, limit: multiValue ? 0 : 1 }
          );
        }],
        type: 'tree',
        label: 'Select output property',
        required: true,
        ngModelAttrs: {
          sequoiaTree: {
            bound: 'sequoia-tree',
            attribute: 'sequoia-tree'
          },
          nodeTemplate: {
            bound: 'node-template',
            attribute: 'node-template'
          },
          options: {
            bound: 'treeOptions',
            attribute: 'options'
          }
        },
        ngModelAttrsValues: [
          {
            name: 'nodeTemplate',
            value: 'nodeTemplate'
          },
          {
            name: 'options',
            value: 'treeOptions'
          },
          {
            name: 'sequoiaTree',
            value: 'tree'
          }
        ],
        expressionProperties: {
          hide: function(_$viewValue, _$modelValue, scope) {
            return _.isUndefined(scope.model.field) || scope.model.field !== 'eventProperty';
          }
        }
      };

      var userField = {
        id: 'userField',
        controller: ['$scope', function($scope) {
          $scope.tree = [];
          UserFields.findAll()
            .then(function(res) {
              var allFields = [];
              var fixed = [
                { _id: '__uf__firstName', name: 'First name', categories: [] },
                { _id: '__uf__lastName', name: 'Last name', categories: [] },
                { _id: '__uf__email', name: 'Email', categories: [] }
              ];
              allFields = allFields.concat(fixed);

              var cats = _.map(res, function(item) {
                return { _id: '__uf__' + item.doc._id, name: item.doc.name, categories: [] };
              });
              allFields = allFields.concat(cats);
              _.assignIn($scope.tree, allFields);
            });
          $scope.nodeTemplate = { id: '_id', title: 'name', nodes: 'categories' };
          $scope.treeOptions = _.assign(
            angular.copy(TREE_OPTIONS),
            { inline: false, limit: multiValue ? 0 : 1 }
          );
        }],
        type: 'tree',
        label: 'Select user field',
        required: true,
        ngModelAttrs: {
          sequoiaTree: {
            bound: 'sequoia-tree',
            attribute: 'sequoia-tree'
          },
          nodeTemplate: {
            bound: 'node-template',
            attribute: 'node-template'
          },
          options: {
            bound: 'treeOptions',
            attribute: 'options'
          }
        },
        ngModelAttrsValues: [
          {
            name: 'nodeTemplate',
            value: 'nodeTemplate'
          },
          {
            name: 'options',
            value: 'treeOptions'
          },
          {
            name: 'sequoiaTree',
            value: 'tree'
          }
        ],
        expressionProperties: {
          hide: function(_$viewValue, _$modelValue, scope) {
            return _.isUndefined(scope.model.field) || scope.model.field !== 'userField';
          }
        }
      };

      var credentialTypes = {
        id: 'credentialType',
        type: 'discrete_multiple',
        label: 'Select credential type',
        options: [
          { _id: 'proxy', key: 'proxy', name: 'SSO' },
          { _id: 'local', key: 'local', name: 'Local' }
        ],
        required: true,
        expressionProperties: {
          hide: function(_$viewValue, _$modelValue, scope) {
            return _.isUndefined(scope.model.field) || scope.model.field !== 'credentials';
          }
        }
      };

      return [displayField, propertyField, userField, credentialTypes];
    };

    return service;
  }

  ReportFieldService.$inject = [
    '$q',
    '$log',
    'BlueprintsService',
    'RelationsService',
    'UsersService',
    'UsersStubService',
    'UserFieldsService',
    'RolesService',
    'EventTypesService',
    'EventTypesStore',
    'UtilsService',
    'EVENT_STATES',
    'USER_STATES',
    'REPORT_CONDITIONS',
    'REPORT_FILTERS',
    'REPORT_OUTPUT_FIELDS',
    'REPORT_SORT_FIELDS',
    'TREE_OPTIONS',
    'GOALS_STATES'
  ];

  angular.module('component.reports')
    .factory('ReportFieldService', ReportFieldService);
})();
