(function() {
  'use strict';

  function AttachedDocumentController($scope, Document, DocumentUtils) {
    var ctrl = this;

    var load = function() {
      ctrl.document = new Document();
      var documentId = $scope.documentId;
      if (documentId !== undefined) {
        ctrl.document.load(documentId)
          .then(function() {
            ctrl.isFolder = ctrl.document.isFolder();
            ctrl.documentBaseName = DocumentUtils.getBaseName(ctrl.document.doc.name);
            ctrl.fileIcon = DocumentUtils.getFileIcon(ctrl.document.doc.documentType);
            ctrl.canDownload = ctrl.document.canView();
            ctrl.loaded = true;
          })
          .catch(function() {
            ctrl.isFolder = false;
            ctrl.documentBaseName = 'This file is not visible to you here. It may have ' +
                           'been deleted or marked as private by the owner.';
            ctrl.canDownload = false;
            ctrl.loaded = true;
          });
      }
    };

    load();

    ctrl.updateVisibility = function(value) {
      $scope.updateVisibility({ id: $scope.documentId, value: value });
      load();
    };

    ctrl.download = function() {
      $scope.download({ doc: ctrl.document.doc });
    };

    ctrl.remove = function() {
      $scope.remove({ docId: $scope.documentId });
    };

    ctrl.canRemove = !_.isUndefined($scope.remove) && _.isFunction($scope.remove);

    var downloadStartedListener = $scope.$on('KZDocumentDownloadStarted', function(_event, data) {
      if (data.id === $scope.documentId) {
        ctrl.message = data.message;
        ctrl.downloading = true;
      }
    });

    var downloadFinishedListener = $scope.$on('KZDocumentDownloadFinished', function(_event, data) {
      if (data.id === $scope.documentId) {
        ctrl.message = data.message;
        ctrl.downloading = false;
      }
    });

    // CLeanup
    $scope.$on('$destroy', downloadStartedListener);
    $scope.$on('$destroy', downloadFinishedListener);
  }

  function AttachedDocumentDirective() {
    return {
      restrict: 'E',
      scope: {
        documentId: '=',
        download: '&?',
        remove: '&?',
        updateVisibility: '&?'
      },
      templateUrl: 'app/components/documents/directives/kz-attached-document.html',
      controller: AttachedDocumentController,
      controllerAs: 'documentCtrl'
    };
  }

  AttachedDocumentController.$inject = [
    '$scope',
    'DocumentFactory',
    'DocumentUtilsService'
  ];

  angular.module('component.documents')
    .directive('kzAttachedDocument', AttachedDocumentDirective);
})();
