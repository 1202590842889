(function() {
  'use strict';

  function DocumentsStubService(RestApi, Api, Documents) {
    var service = new RestApi('documentsstub');
    service.es_endpoint = 'es_documents';

    service.find = function(id) {
      return Documents.find(id, { cache: 'cached' })
             .catch(function() {
               return Api.get(service.rest_endpoint, { _id: id });
             });
    };

    return service;
  }

  DocumentsStubService.$inject = ['RestApi', 'ApiService', 'DocumentsService', 'AuthService'];

  angular.module('component.documents')
    .factory('DocumentsStubService', DocumentsStubService);
})();
