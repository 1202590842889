(function() {
  'use strict';

  function GoalWrapperController(
    $scope,
    $rootScope,
    $state,
    EventSection,
    EventSections,
    Notify,
    GOALS_STATES,
    LocalizationService
  ) {
    var ctrl = this;

    ctrl.GOALS_STATES = GOALS_STATES;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');

    ctrl.overdueAndNotClosed = $scope.goal.goalSet &&
                               $scope.goal.isOverdue() &&
                               !$scope.goal.goalSet.isClosed;

    if ($scope.goal.event) {
      _.forEach($scope.goal.event.doc.sections, function(section, sectionId) {
        if (sectionId !== $scope.goal.doc.sectionId) {
          _.forEach(section.items, function(item) {
            EventSections.find(item.eventSectionId)
              .then(function(eventSection) {
                ctrl.eventSection = new EventSection(
                  eventSection,
                  { eventType: $scope.goal.event.eventType }
                );
              });
          });
        }
      });
    }

    ctrl.getBorderClass = function() {
      ctrl.borderClass = $scope.goal.getBorderClass ?
        $scope.goal.getBorderClass() : 'progress-border-complete';
    };

    function loadButtons() {
      ctrl.actionButtons = [
        {
          label: 'Add more goals',
          ariaLabel: 'Add more goals to ' + $scope.goal.doc.title,
          icon: 'icon-plus',
          klass: 'text-success',
          href: (function() {
            var route = 'epf.goals.add';
            var args = {
              eventId: $scope.goal.doc.eventId,
              sectionId: $scope.goal.doc.eventSectionId,
              fieldId: $scope.goal.doc.eventFieldId
            };

            if (!$scope.goal.isMine()) {
              route = 'epf.users.goals-add';
              args.user = $scope.goal.doc.user;
            }

            return $state.href(route, args);
          })(),
          showCondition: function() {
            return $scope.goal.checkPermission('canAdd');
          }
        },
        {
          label: 'Close this goal set',
          ariaLabel: 'Close the ' + $scope.goal.doc.title + ' goal set',
          icon: 'icon-check',
          klass: 'text-success',
          onClick: function() {
            return $scope.goal.getFull()
              .then(function(goal) {
                return goal.closeEventSection();
              })
              .then(function() {
                $rootScope.$broadcast('KZApplySearch');
                // remove this when doAction is more general
                // var route = 'epf.goals.index';
                // var args = {};

                // if (!$scope.goal.isMine()) {
                //   route = 'epf.users.goals';
                //   args.user = $scope.goal.doc.user;
                // }
                // $state.go(route, args, { reload: true });
              });
          },
          showCondition: function() {
            return $scope.goal.checkPermission('canClose');
          }
        },
        {
          label: 'Re-open goal set',
          ariaLabel: 'Reopen the ' + $scope.goal.doc.title + ' goal set',
          icon: 'icon-check',
          klass: 'text-success',
          onClick: function() {
            return $scope.goal.event.getFull()
              .then(function(event) {
                return event.reOpen();
              })
              .then(function() {
                $rootScope.$broadcast('KZApplySearch');
                // var route = 'epf.goals.index';
                // var args = {};

                // if (!$scope.goal.isMine()) {
                //   route = 'epf.users.goals';
                //   args.user = $scope.goal.doc.user;
                // }
                // $state.go(route, args, { reload: true });
              });
          },
          showCondition: function() {
            return $scope.goal.checkPermission('canReOpen');
          }
        }
      ];

      ctrl.secondaryActionButtons = [
        {
          label: 'Change due date',
          ariaLabel: 'Change due date for ' + $scope.goal.doc.title,
          icon: 'icon-calendar',
          klass: 'text-success',
          onClick: function() {
            return $scope.goal.getFull()
              .then(function(goal) {
                return goal.openUpdateDueDateModal();
              })
              .then(function() {
                Notify.success('The due date has been updated successfully!');
                $rootScope.$broadcast('KZApplySearch');
              })
              // .then(function() {
              //   // remove this when doAction is more general
              //   var route = 'epf.goals.index';
              //   var args = {};

              //   if (!$scope.goal.isMine()) {
              //     route = 'epf.users.goals';
              //     args.user = $scope.goal.doc.user;
              //   }
              //   $state.go(route, args, { reload: true });
              // })
              .catch(function(err) {
                if (err === 'cancel' || err === 'escape key press') {
                  return;
                }

                var msg = 'Something wrong just happened while updating ' +
                  'the due date: ' + err.message;
                Notify.error(msg);
              });
          },
          showCondition: function() {
            if (_.isEmpty($scope.goal.event) || $scope.goal.isSetClosed()) {
              return false;
            }

            return $scope.goal.checkPermission('canUpdateDueDate');
          }
        },
        {
          label: 'Change periods',
          ariaLabel: 'Change periods for ' + $scope.goal.doc.title,
          icon: 'icon-history',
          klass: 'text-success',
          onClick: function() {
            return $scope.goal.getFull()
              .then(function(goal) {
                return goal.openUpdatePeriodsModal();
              })
              .then(function() {
                Notify.success('The due date has been updated successfully!');
              })
              .catch(function(err) {
                var msg = 'Something wrong just happened while updating ' +
                  'the periods: ' + err.message;
                Notify.error(msg);
              });
          },
          showCondition: function() {
            var goal = $scope.goal;
            if (_.isEmpty(goal.event) || goal.isSetClosed()) {
              return false;
            }

            return $scope.goal.checkPermission('canUpdatePeriods');
          }
        }
      ];
    }

    loadButtons();
    ctrl.getBorderClass();
    ctrl.loaded = true;
  }

  GoalWrapperController.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    'EventSectionFactory',
    'EventSectionsService',
    'NotifyService',
    'GOALS_STATES',
    'LocalizationService'
  ];

  function GoalWrapperDirective() {
    return {
      scope: {
        goal: '=',
        templateType: '@',
        options: '='
      },
      restrict: 'AE',
      templateUrl: function(_elem, attr) {
        return attr.template ? attr.template : 'app/components/goals/directives/goal.wrapper.html';
      },
      replace: true,
      controller: GoalWrapperController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.goals')
    .directive('goalWrapper', GoalWrapperDirective)
    .controller('GoalWrapperController', GoalWrapperController);
})();
