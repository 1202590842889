(function() {
  'use strict';

  function FAQsService(ConfService) {
    var service = new ConfService('faq');
    return service;
  }

  FAQsService.$inject = ['ConfService'];

  angular.module('component.faqs')
    .factory('FAQsService', FAQsService);
})();
