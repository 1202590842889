import './core.module.js';
import './widgets';
import './frylytics.service.js';
import './core.router.js';
import './helpeditor.controller.js';
import './upgrade.controller.js';
import './error.controller.js';
import './error.different-user.controller.js';
import './help.constants.js';
import './core.constants.js';
