(function() {
  'use strict';

  function TitleDirective($q, FileUpload, Notify, Auth, Goal, GoalsStore, LocalizationService) {
    return {
      scope: {
        item: '=',
        type: '@',
        kzType: '@',
        fieldId: '@',
        eventTypeId: '@',
        eventId: '@',
        sectionId: '@',
        user: '@',
        watch: '@',
        highlight: '=',
        options: '=',
        field: '=?',
        templateOptions: '=?',
        showHref: '=?',
        separator: '@?',
        parent: '=?'
      },
      restrict: 'EA',
      templateUrl: 'app/blocks/widgets/forms/form-title.html',
      link: function(scope) {
        scope.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
        scope.datetimeFormat = LocalizationService.getDateTimeFormat('datetime');

        function show() {
          scope.show = true;
          if (scope.type !== 'boolean' && scope.item === undefined) {
            scope.show = false;
            return;
          }

          scope.value = scope.item;
          if (!_.isArray(scope.value) || scope.type === 'filters') {
            scope.value = [scope.value];
          }

          if (['tree'].indexOf(scope.type) !== -1) {
            scope.klass = 'event-tag';
          }

          var types = ['likert', 'discrete', 'discrete_multiple', 'tree', 'select'];
          if (scope.templateOptions && scope.templateOptions.getTitle) {
            scope.loading = true;
            $q.all(_.map(scope.value, scope.templateOptions.getTitle.bind(scope.templateOptions)))
              .then(function(title) {
                scope.value = title;
                scope.loading = false;
                scope.showType = 'title';
              });
          } else if (types.indexOf(scope.type) !== -1) {
            if (scope.kzType === 'user') {
              scope.showType = 'user';
            } else if (scope.options !== undefined && scope.options.type === 'goal') {
              // todo: fix me, it should be more general! How to do this?
              scope.showType = 'eventType_versionGroupId';
            } else if (scope.kzType === 'eventType') {
              scope.showType = 'eventType';
            } else {
              scope.showType = 'categoryTitle';
            }
          } else if (['eventType_versionGroupId'].indexOf(scope.type) !== -1) {
            scope.showType = 'eventType_versionGroupId';
          } else if (['date'].indexOf(scope.type) !== -1) {
            scope.showType = 'date';
          } else if (['datetime'].indexOf(scope.type) !== -1) {
            scope.showType = 'datetime';
          } else if (['boolean'].indexOf(scope.type) !== -1) {
            scope.showType = 'bool';
          } else if (['document', 'document_multiple'].indexOf(scope.type) !== -1) {
            scope.showType = 'document';
          } else if (['user'].indexOf(scope.type) !== -1) {
            scope.showType = 'user';
          } else if (['role'].indexOf(scope.type) !== -1) {
            scope.showType = 'role';
          } else if (['report'].indexOf(scope.type) !== -1) {
            scope.showType = 'report';
          } else if (['signature'].indexOf(scope.type) !== -1) {
            scope.showType = 'signature';
          } else if (['file'].indexOf(scope.type) !== -1) {
            scope.showType = 'file';
            scope.openInNewTab = function(itm) {
              FileUpload.getDownloadUrl(
                itm,
                { oid: scope.parent || scope.eventId },
                { content_disposition_type: 'inline' }
              )
                .then(function(res) {
                  window.open(res.url, '_blank');
                })
                .catch(function(err) {
                  console.log(err);
                  Notify.error(err.message || 'The file cannot be downloaded');
                });
            };

            scope.download = function(itm) {
              FileUpload.getDownloadUrl(itm, { oid: scope.parent || scope.eventId })
                .then(function(res) {
                  window.open(res.url);
                })
                .catch(function(err) {
                  console.log(err);
                  Notify.error(err.message || 'The file cannot be downloaded');
                });
            };
            scope.separator = ' ';
          } else if (['blueprint'].indexOf(scope.type) !== -1) {
            scope.showType = 'blueprint';
          } else if (['relation'].indexOf(scope.type) !== -1) {
            scope.kzType = 'relation';
            scope.showType = 'categoryTitle';
          } else if (['filters'].indexOf(scope.type) !== -1) {
            scope.showType = 'filters';
          } else if (['goal'].indexOf(scope.type) !== -1) {
            scope.showType = 'goal';

            if (!_.isEmpty(scope.eventId) && !_.isEmpty(scope.sectionId)) {
              scope.loading = true;
              var customsDbOptions = { eventId: scope.eventId, sectionId: scope.sectionId },
                  linkedDbOptions = {
                    goalsIds: scope.item.existingGoalsLinked || [],
                    linked: customsDbOptions
                  };
              if (Auth.currentUser() !== scope.user) {
                customsDbOptions.username = scope.user;
                linkedDbOptions.username = scope.user;
              }

              GoalsStore.fetchLight(
                {
                  eventId: scope.eventId,
                  eventSectionId: scope.sectionId,
                  sort_on: 'eventAndDueDate',
                  user: scope.user
                },
                {
                  own: Auth.currentUser() === scope.user || !scope.user
                }
              ).then(function(goals) {
                return GoalsStore.getSimpleList(goals);
              }).then(function(result) {
                scope.loading = false;

                if (!_.isEmpty(result.list.allItems)) {
                  scope.list = result.list;
                  scope.goToPage = result.goToPageFunc;
                } else if (_.isUndefined(result.actions)) {
                  scope.goalActions = Goal.getActions(
                    scope.eventId,
                    scope.sectionId,
                    scope.fieldId,
                    scope.user
                  );
                }
              })
              .catch(function() {
                scope.loading = false;
              });

              scope.$on('GoalPeriodSelected', function(_evt, periodFilter) {
                scope.$broadcast('GoalPeriodUpdated', periodFilter);
              });
            }
          } else {
            scope.showType = 'text';
          }
        }

        if (scope.watch) {
          scope.$watch('item', function() {
            show();
          });
        } else {
          show();
        }
      }

    };
  }

  TitleDirective.$inject = [
    '$q',
    'FileUploadService',
    'NotifyService',
    'AuthService',
    'GoalFactory',
    'GoalsStore',
    'LocalizationService'
  ];

  // This is a custom directive inspired by https://github.com/angular/angular.js/blob/master/src/ng/directive/ngList.js
  function kzListDirective() {
    return {
      restrict: 'A',
      priority: 100,
      require: 'ngModel',
      link: function(_scope, _element, attr, ctrl) {
        var separator = new RegExp(attr.kzList) || ', ';

        var parse = function(viewValue) {
          // If the viewValue is invalid (say required but empty) it will be `undefined`
          if (angular.isUndefined(viewValue)) { return; }

          var list = [];

          if (viewValue) {
            _.forEach(viewValue.split(separator), function(value) {
              if (value) { list.push(value.trim()); }
            });
          }

          return list;
        };

        ctrl.$parsers.push(parse);
        ctrl.$formatters.push(function(value) {
          if (Array.isArray(value)) {
            return value.join(', ');
          }

          return undefined;
        });

        // Override the standard $isEmpty because an empty array means the input is empty.
        ctrl.$isEmpty = function(value) {
          return !value || !value.length;
        };
      }
    };
  }

  kzListDirective.$inject = [];

  angular.module('widgets.forms')
    .directive('kzFormTitle', TitleDirective)
    .directive('kzList', kzListDirective);
})();
