(function() {
  'use strict';

  function DocumentRenameController($uibModalInstance, resolvedDoc, options, Form, Notify) {
    var ctrl = this;
    ctrl.resolvedDoc = resolvedDoc;
    ctrl.options = options;
    ctrl.model = angular.copy(resolvedDoc.doc);
    ctrl.allowedActions = [];

    var buildForm = function() {
      ctrl.form = new Form([
        { id: 'name', type: 'string', label: 'Name', required: true }
      ]);
    };

    var setActions = function() {
      ctrl.actions = [
        {
          label: 'Save',
          icon: 'icon-save',
          klass: 'btn-sm btn-success',
          onClick: function() {
            resolvedDoc.doc = ctrl.model;
            return resolvedDoc.save()
              .then(function() {
                Notify.success('Document has been renamed', 'Document');
                ctrl.close();
              })
              .catch(function(err) {
                ctrl.messages.unshift({
                  message: err.message,
                  type: 'warning'
                });
              });
          }
        },
        {
          label: 'Cancel',
          icon: 'icon-cancel',
          klass: 'btn-sm btn-warning',
          onClick: function() {
            ctrl.close();
          }
        }
      ];
    };

    buildForm();
    setActions();

    ctrl.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    ctrl.modal = $uibModalInstance;
  }

  DocumentRenameController.$inject = [
    '$uibModalInstance',
    'resolvedDoc',
    'options',
    'FormsService',
    'NotifyService'
  ];

  angular.module('component.documents')
    .controller('DocumentRenameController', DocumentRenameController);
})();
