(function() {
  'use strict';

  function EventViewController(
    resolvedEvent,
    $scope,
    $rootScope,
    $state,
    $stateParams,
    Event,
    Utils,
    Auth,
    Api,
    EVENT_STATES,
    LocalizationService
  ) {
    var ctrl = this;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('dateonly');
    ctrl.EVENT_STATES = EVENT_STATES;
    ctrl.scrollTo = $stateParams.scrollTo;
    ctrl.extraIsExpanded = $stateParams.scrollTo === 'tags' ||
                            $stateParams.scrollTo === 'documents';

    var action = 'view';
    var isTearoffPage = ($state.current.data || {}).tearoff;
    ctrl.username = $stateParams.user || Auth.currentUser();
    ctrl.formOptions = {};

    ctrl.auditLogShown = false;

    if (!_.isUndefined(resolvedEvent)) {
      ctrl.event = resolvedEvent;
    }

    var currentEventExists = (
      !_.isEmpty($stateParams.event) &&
      $stateParams.event.doc._id === $stateParams.id
    );

    if (_.isUndefined(ctrl.event)) {
      // Create an event object at the top so that binding is easier
      ctrl.event = currentEventExists ? $stateParams.event : new Event({});
    }

    $rootScope.$broadcast('KZCurrentEventChange', { event: ctrl.event });

    // We have to broadcast the formControll only when initialized and it's done only after
    // the form element (directive) is processed
    // $scope.$watch('eventCtrl.form', function(form) {
    //   if (form !== undefined) {
    //     $rootScope.$broadcast('KZCurrentFormChange', { form: form });
    //   }
    // });

    function loadEvent(eid) {
      var setEventViewOptions = function() {
        ctrl.event.getProgress();
        ctrl.authorized = true;
        ctrl.formOptions.readOnly = true;
        ctrl.displayView = 'default';
        ctrl.actionButtons = ctrl.event.actionButtons;
        ctrl.actionButtons.push({
          label: 'Back',
          back: true,
          type: 'link',
          icon: 'icon-left-open-big',
          color: 'primary'
        });

        if (ctrl.event.isComplete()) {
          ctrl.displayView = ctrl.event.eventType.defaultView || 'default';
          if (ctrl.displayView !== 'default') {
            ctrl.availableViews = [
              { id: ctrl.displayView, label: 'Report view' }
            ];

            ctrl.event.checkPermission('canEditAny')
              .then(function() {
                ctrl.availableViews.push({ id: 'default', label: 'Full event view' });
              });
          } else {
            ctrl.availableViews = [
              { id: 'default', label: 'Full event view' }
            ];
          }
        }

        // Run this just if accessed from tear off page
        // for normal event it is fetch from the directive that is displaying it
        if (
          isTearoffPage &&
          ctrl.event.currentSection.def &&
          ctrl.event.currentSection.def.multiSource
        ) {
          // Not returning on purpose as this does not need to slow down loading
          ctrl.event.getSectionTokens(ctrl.event.currentSection._id)
            .then(function(data) {
              ctrl.tokens = _.map(data, function(token) {
                return {
                  url: Api.absHostUrl('token_url', { token: token.short_token }, { baseUrl: true })
                };
              });
            })
            .catch(function(err) {
              console.log(err);
            });
        }
      };

      if (currentEventExists) {
        setEventViewOptions();
      } else {
        ctrl.event.load(eid, $stateParams.user, $stateParams.token, action === 'edit')
          .then(function() {
            return ctrl.event.containsGoalInCurrentSection();
          })
          .then(function() {
            if (action === 'edit') {
              return ctrl.event.noPending();
            }
          })
          .then(setEventViewOptions)
          .then(function() {
            // We're doing this because the documents list format
            // is STARKLY different than what they attach
            // e.g. they attach this:
            // [ { "type": "document", "value": [ "bae26040-0dae-421c-ab73-b4d2b8889560" ] } ]
            // but we pull in just the id as a string
            ctrl.formattedDocuments = _.map(ctrl.event.documents, function(doc) {
              return { value: [doc] };
            });
          })
          .catch(function(error) {
            if (error && error.status && error.status === 403) {
              Utils.unauthorized(error);
              return;
            }

            Utils.showError(error);
          });
      }
    }

    // If action is edit and we don't have state params redirect back
    if (['edit', 'view'].indexOf(action) !== -1) {
      if (!$stateParams.id) {
        Utils.go('epf.events.index', {}, { tryBack: true });
      } else {
        loadEvent($stateParams.id);
      }
    } else if ($stateParams.id) {
      Utils.go('epf.events.index', {}, { tryBack: true });
    }

    $scope.$on('KZItemRemoved', function() {
      if (ctrl.username === ctrl.event.doc.user) {
        Utils.go('epf.events.index', {}, { tryBack: true });
      } else {
        return Utils.go('epf.users.events', { user: ctrl.username }, { tryBack: true });
      }
    });

    $scope.$on('EventReload', function(_evt, args) {
      if (ctrl.event.doc !== undefined && args.id === ctrl.event.doc._id) {
        ctrl.updateEvent();
      }
    });

    ctrl.updateEvent = function() {
      console.log('Reloading event');
      return ctrl.event.reload()
        .then(function() {
          loadEvent(ctrl.event.doc._id);
        });
    };
  }

  EventViewController.$inject = [
    'resolvedEvent',
    '$scope',
    '$rootScope',
    '$state',
    '$stateParams',
    'EventFactory',
    'UtilsService',
    'AuthService',
    'ApiService',
    'EVENT_STATES',
    'LocalizationService'
  ];

  angular.module('component.events')
    .controller('EventViewController', EventViewController);
})();
