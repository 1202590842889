(function() {
  'use strict';

  function UserDocumentsController($stateParams, Documents, DocumentFactory, DocumentSearch,
                                   DocumentsStub,
                                   APIList, Utils, Security, UsersStub,
                                   ICON_FILES_EXTENSIONS_MAP, AUTHORIZED_FILES_EXTENSIONS) {
    var ctrl = this;

    ctrl.username = $stateParams.user;

    // do you like me ? path is passed as /id/id/id but escaped
    // we need the path to search in Elastic against path_str index
    ctrl.path = $stateParams.path.replace(/%2F/g, '/');

    // last element in the path
    ctrl.folderId = _.last(ctrl.path.split('/'));

    if (ctrl.username === undefined) {
      Utils.unauthorized({ message: 'You are not authorised to access this page' });
      Utils.setPageTitle('User documents');
    } else {
      UsersStub.find(ctrl.username)
        .then(function(user) {
          var fullName = _.trim(
            (user.firstName || '') + ' ' + (user.lastName || '')
          );
          // FIXME: This will not make every page unique, because the path isn't included
          Utils.setPageTitle(fullName + '\'s documents');
        })
        .catch(function() {
          Utils.setPageTitle('User documents');
        });
    }

    ctrl.documents = [];
    ctrl.pageinfo = {};
    ctrl.finished = true;

    /* Handle Filters */
    var extensions = [];
    extensions.push({ _id: 'folder', name: 'folder' });
    _.forEach(AUTHORIZED_FILES_EXTENSIONS, function(ext) {
      extensions.push({ _id: ext, name: ext });
    });

    function makeDocs(item) {
      return new DocumentFactory(item, { source: 'ES' });
    }

    function setupList() {
      return DocumentSearch.search
        .then(function(search) {
          ctrl.list = new APIList(DocumentsStub, {
            search: search,
            model: { filteredBy: {} },
            defaultFilter: { user: ctrl.username, path: ctrl.path },
            makeDocs: makeDocs
          });
          ctrl.list.doSearch = function() {
            ctrl.haveFilter = !_.isEmpty(ctrl.list.searchModel.filteredBy);
            // ctrl.list.searchModel.filteredBy.user = ctrl.username;
            // ctrl.list.searchModel.filteredBy.path = ctrl.path;

            // if documents are searched by filtering, we want to ignore path
            if (
              _.has(ctrl.list.searchModel.filteredBy, 'name') ||
              _.has(ctrl.list.searchModel.filteredBy, 'documentType')
            ) {
              ctrl.list.searchModel.filteredBy.use_path = false;
            } else {
              ctrl.list.searchModel.filteredBy.use_path = true;
            }

            APIList.prototype.doSearch.call(this);
          };
        });
    }

    function load() {
      // force the following parameters
      // ctrl.list.searchModel.filteredBy.user = ctrl.username;
      // ctrl.list.searchModel.filteredBy.path = ctrl.path;

      ctrl.list.doLoadItems()
        .then(function(data) {
          if (data.length > 0) {
            ctrl.notification = '';
          } else {
            ctrl.notification = 'There are no documents';
          }
        }).catch(function(err) {
          ctrl.notification = err.message || err.reason || err.statusText;
        });
    }

    Security.hasPermissionFor('documents.view', ctrl.username)
      .then(function() {
        ctrl.authorized = true;
        return setupList();
      })
      .then(function() {
        load();
      })
      .catch(Utils.unauthorized);

    ctrl.buildPath = function(doc) {
      return '/' + doc.path.join('/');
    };

    ctrl.showDescription = function(doc) {
      ctrl.shownDescriptions = ctrl.shownDescriptions ? ctrl.shownDescriptions : {};
      if (_.has(ctrl.shownDescriptions, doc._id)) {
        ctrl.shownDescriptions[doc._id] = !ctrl.shownDescriptions[doc._id];
      } else {
        ctrl.shownDescriptions[doc._id] = true;
      }

      Documents.userDocumentLinks(doc)
        .then(function(links) {
          console.log(links);
          doc.events = links.events;
          doc.tags = links.tags;
        });
    };

    ctrl.getFileIcon = function(documentType) {
      return ICON_FILES_EXTENSIONS_MAP[documentType];
    };
  }

  UserDocumentsController.$inject = [
    '$stateParams',
    'DocumentsService',
    'DocumentFactory',
    'DocumentSearch',
    'DocumentsStubService',
    'APIListFactory',
    'UtilsService',
    'SecurityService',
    'UsersStubService',
    'ICON_FILES_EXTENSIONS_MAP',
    'AUTHORIZED_FILES_EXTENSIONS'
  ];

  angular.module('component.users')
    .controller('UserDocumentsController', UserDocumentsController);
})();
