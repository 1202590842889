(function() {
  'use strict';

  function kzGroupWrapperDirective() {
    function link(scope) {
      if (!scope.currentOrderGroup.groupBy) {
        return;
      }

      function concatOrdersValues(item) {
        if (_.isUndefined(item)) {
          return '';
        }

        var values = _.map(scope.currentOrderGroup.orders, function(key) {
          return scope.searchOrders[key.replace(/^-/g, '')](item);
        });

        return values.join();
      }

      // we could get the value from previous current
      var prevValue = concatOrdersValues(scope.prevItem);
      var currentValue = concatOrdersValues(scope.item);
      var nextValue = concatOrdersValues(scope.nextItem);

      var preTemplate = scope.currentOrderGroup.groupBy.preTemplate;
      var postTemplate = scope.currentOrderGroup.groupBy.postTemplate;

      var template;
      if (prevValue !== currentValue && scope.templateType === 'pre') {
        template = preTemplate;
      } else if (nextValue !== currentValue && scope.templateType === 'post') {
        template = postTemplate;
        if (!nextValue && !scope.finished) {
          template = undefined;
        }
      }

      if (template) {
        scope.show = true;
        // console.log('Wrapper. Linking template');
        // var linkFn = $compile(template);
        // var content = linkFn(scope);
        // element.append(content);
      }
    }

    return {
      scope: {
        item: '=groupItem',
        prevItem: '=',
        nextItem: '=',
        currentOrderGroup: '=',
        searchOrders: '=',
        templateType: '@',
        finished: '=?',
        options: '='
      },
      restrict: 'AE',
      replace: true,
      transclude: true,
      template: '<div><div ng-if="show"><ng-transclude></ng-transclude></div></div>',
      link: link
    };
  }

  kzGroupWrapperDirective.$inject = [];

  angular.module('blocks.list')
    .directive('kzGroupWrapper', kzGroupWrapperDirective);
})();
