(function() {
  'use strict';

  function RelationUtils($q, Profile, Relations) {
    var service = {};

    service.getRestrictedRelations = function(originalRelations) {
      var getRelations;
      getRelations = Profile.getRelations();

      // Get all user relations
      var relationBlueprints = getRelations

        // Find the actual subcategory for all of them
        .then(function(relations) {
          var promises = [];
          var keys = _.map(originalRelations, function(item) {
            return item._id;
          });

          // FIXME
          relations = _.pickBy(relations, function(_value, key) {
            return keys.indexOf(key) !== -1;
          });

          if (_.isEmpty(relations)) {
            return originalRelations;
          }

          function getSubCategory(subsubrel) {
            promises.push(Relations.findSubCategory(subsubrel));
          }

          _.forOwn(relations, function(subrel) {
            subrel.forEach(getSubCategory);
          });

          return $q.all(promises);
        })
        .then(function(blueprints) {
          return _.filter(blueprints, function(item) {
            return item !== undefined;
          });
        });

      return relationBlueprints;
    };

    service.getLabel = function(relations) {
      if (relations.length === 0) {
        return 'n/a';
      }

      var relationNames = _.map(relations, function(relation) { return relation.name; });

      if (relations[0].name !== relations[0].origName && !_.isUndefined(relations[0].origName)) {
        return relations[0].origName + ' at ' + relationNames.join(', ');
      }

      return relationNames.join(', ');
    };

    return service;
  }

  RelationUtils.$inject = ['$q', 'ProfileService', 'RelationsService', 'BlueprintsService'];

  angular.module('component.relations')
    .service('RelationUtils', RelationUtils);
})();
