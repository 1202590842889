(function() {
  'use strict';

  function TargetsLinkController($rootScope, $state, $stateParams, Profile,
    EventUtils, Goal, Notify, Utils) {
    var ctrl = this;

    ctrl.prefs = Profile.getPreferences();
    ctrl.options = {
      typesToUpdate: ['event', 'eventSection'],
      trackBy: 'id',
      borderClass: function(item) {
        return item.main.getBorderClass();
      }

    };

    var loadList = function() {
      if (_.isUndefined(ctrl.model)) {
        ctrl.model = {
          filteredBy: {}
        };
      }

      ctrl.options.search = ctrl.model;
      ctrl.options.noItemsText = undefined;
      if (ctrl.isSuggestionSearch) {
        ctrl.options.noItemsText = 'I\'m sorry but there are no events which match the ' +
                                    'requirements for this target. Click above to switch to ' +
                                    'viewing all the events in your portfolio.';
      }

      var listOptions = {
        // defaultFilter: defaultFilter
      };
      if (ctrl.isSuggestionSearch) {
        listOptions.resolvedTransformedFilter = { filters: ctrl.target.getLinkingSearchModel() };
      }

      ctrl.list = undefined;
      return EventUtils.getList('dbsearch', ctrl.model, listOptions)
        .then(function(list) {
          ctrl.list = list;
          ctrl.search = list.search;
          ctrl.list.doLoadItems();
        })
        .then(function() {
        }).then(function() {
          ctrl.loaded = true;
        });
    };

    var remoteUsername;
    Goal.find($stateParams.goalId, remoteUsername, { doNotLoadTargets: true })
      .then(function(goal) {
        return goal.checkPermission('canWork')
          .then(function() {
            return goal.getTargetById($stateParams.targetId, $stateParams.periodId);
          });
      })
      .then(function(target) {
        ctrl.target = target;
        Utils.setPageTitle(
          'Link events to ' +
            target.goal.doc.title +
            '\'s target: ' +
            target.doc.title
        );
        ctrl.prefs.targetToLink = ctrl.target;
        ctrl.linkingSearchModel = ctrl.target.getLinkingSearchModel() || {};

        ctrl.eventGroups = target.getRelevantEventTypes();
        ctrl.isSuggestionSearch = !_.isEmpty(ctrl.linkingSearchModel);

        $rootScope.$broadcast('UpdateLinkingStatus', { target: ctrl.target });
      })
      .then(function() {
        return loadList();
      })
      .then(function() {
        ctrl.loaded = true;
      })
      .catch(Utils.showError);

    ctrl.doneLinking = function() {
      $state.go('epf.goals.work', { id: ctrl.target.goal.doc._id });
    };

    ctrl.isSuggestionSearch = true;
    ctrl.updateSearch = function() {
      if (ctrl.search) {
        ctrl.isSuggestionSearch = !ctrl.isSuggestionSearch;
        loadList().then(function() {
          ctrl.list.doSearch();
        });
      }
    };

    function autoCloseMessageAndRedirection(hasBeenAutoClosed) {
      if (!hasBeenAutoClosed.result) {
        if (hasBeenAutoClosed.error) {
          Notify.error(
            'An error occured, here is the reason: ' + hasBeenAutoClosed.error,
            'Error while auto closing!'
          );
          $state.go('epf.goals.index', {}, { reload: true });
        }
        return;
      }

      Notify.success(
        'Congratulation your goal set has been auto closed because you achieved all' +
        ' the requirements!',
        'Congratulation!'
      );
      $state.go('epf.goals.index', {}, { reload: true });
    }

    $rootScope.$on('HasBeenAutoClosed', function(_event, args) {
      autoCloseMessageAndRedirection(args.hasBeenAutoClosed);
    });

    var postAction = function(hasBeenAutoClosed) {
      $rootScope.$broadcast('kzReloadActions');
      $rootScope.$broadcast('UpdateLinkingStatus', { target: ctrl.target });
      autoCloseMessageAndRedirection(hasBeenAutoClosed);
    };

    var linkEvent = function(objToLink) {
      var eventId,
          name,
          startDate;

      if (objToLink.doc.type === 'event') {
        eventId = objToLink.doc._id;
        name = objToLink.eventType.name;
        startDate = objToLink.doc.startDate;
      } else {
        eventId = objToLink.doc.event;
        name = 'Quick Note';
        if (!objToLink.meta.quickDraft) {
          name = objToLink.eventType.name;
        }

        startDate = objToLink.meta.startDate;
      }

      var event = {
        id: eventId,
        name: name,
        startDate: startDate
      };

      var exists = _.find(ctrl.target.linkedEvents, function(e) {
        return e.id === event.id;
      });
      if (exists) {
        return;
      }

      return ctrl.target.linkEventToTarget(eventId)
        .then(function(result) {
          ctrl.target.linkedEvents.push(event);
          return result.hasBeenAutoClosed;
        })
        .then(function(hasBeenAutoClosed) {
          return postAction(hasBeenAutoClosed);
        });
    };

    var unlinkEvent = function(objToUnlink) {
      var itemId;
      if (objToUnlink.doc.type === 'event') {
        itemId = objToUnlink.doc._id;
      } else {
        itemId = objToUnlink.doc.event;
      }
      return ctrl.target.unLinkEventFromTarget(itemId)
        .then(function(result) {
          _.remove(ctrl.target.linkedEvents, function(val) {
            return val.id === itemId;
          });

          return result.hasBeenAutoClosed;
        })
        .then(function(hasBeenAutoClosed) {
          return postAction(hasBeenAutoClosed);
        });
    };

    // Override event default buttons
    ctrl.options.highlightField = 'name';
    ctrl.options.showLinkedTargets = true;
    ctrl.options.actionButtons = function(item) {
      function isLinked(item) {
        var eventIds = _.map(ctrl.target.linkedEvents, function(event) {
          return event.id;
        });

        var id;
        if (item.doc.type === 'event') {
          id = item.doc._id;
        } else {
          id = item.doc.event;
        }
        return eventIds.indexOf(id) > -1;
      }

      return [
        {
          label: 'Link this event',
          icon: 'icon-link',
          onClick: linkEvent.bind(this, item),
          showCondition: function() {
            return !isLinked(item);
          },
          klass: 'text-info'
        },
        {
          label: 'Unlink from this event',
          icon: 'icon-check',
          onClick: unlinkEvent.bind(this, item),
          showCondition: function() {
            return isLinked(item);
          },
          klass: 'text-info'
        }
      ];
    };
  }

  TargetsLinkController.$inject = [
    '$rootScope',
    '$state',
    '$stateParams',
    'ProfileService',
    'EventUtils',
    'GoalFactory',
    'NotifyService',
    'UtilsService'
  ];

  angular.module('component.goals')
    .controller('TargetsLinkController', TargetsLinkController);
})();
