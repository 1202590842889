(function() {
  'use strict';

  function AnnouncementsUtils(Profile, Utils) {
    var service = {};

    /**
     * The search object required by facetly.
     * @type {Object}
     */
    service.search = {
      facetly: {
        facets: [
          {
            id: 'title',
            type: 'text',
            label: 'Start typing to search'
          },
          {
            id: 'status',
            type: 'select',
            label: 'State',
            options: [
              { id: 'publish', title: 'Published' },
              { id: 'schedule', title: 'Scheduled' },
              { id: 'unpublish', title: 'Unpublished' },
              { id: 'expire', title: 'Expired' }
            ],
            facetly: false,
            advanced: true
          }
        ],
        options: {
          defaultFacet: 'title',
          placeholder: 'Start typing to search...',
          listMaxItems: 10
        }
      },
      filters: [
        {
          id: 'title',
          matchFunc: function(doc, _key, value) {
            if (!value) { return true; }

            var val = value.toLowerCase(),
                title = doc.doc.title.toLowerCase();
            return title.indexOf(val) > -1;
          }
        },
        {
          id: 'status',
          matchFunc: function(doc, _key, value) {
            if (!value) { return true; }

            if (value === 'publish') {
              return service.isCurrent(doc.doc);
            } else if (value === 'schedule') {
              return service.isScheduled(doc.doc);
            } else if (value === 'unpublish') {
              return doc.doc.status === 'unpublish';
            }

            return service.isExpired(doc.doc); // value === 'expire'
          }
        }
      ],
      orders: {
        createdDate: function(item) {
          return item.doc.createdDate;
        },
        publishDate: function(item) {
          return item.doc.publishDate;
        },
        importance: function(item) {
          return item.doc.order;
        }
      },
      orderGroups: {
        createdDate: {
          title: 'Date created',
          orders: ['createdDate']
        },
        publishDate: {
          title: 'Date published',
          orders: ['publishDate']
        },
        importance: {
          title: 'Importance',
          orders: ['importance']
        }
      },
      defaultFilter: {
      },
      defaultOrder: 'createdDate'
    };

    /**
     * The search model required by facetly.
     * @type {Object}
     */
    service.searchModel = Profile.getListPreferences('announcements').current;

    /**
     * Get the border class for a given announcement.
     * @param  {Object} announcement The announcement for which to get the border class.
     * @return {String}              The border class.
     */
    service.getBorderClass = function(announcement) {
      var className = 'progress-border-',
          type,
          today = Utils.now();

      if (announcement.doc.status === 'unpublish') {
        type = 'danger';
      } else {
        if (announcement.doc.expireDate && announcement.doc.expireDate < today) {
          type = 'pending';
        }

        if (announcement.doc.publishDate <= today &&
            (!announcement.doc.expireDate || announcement.doc.expireDate >= today)
        ) {
          type = 'complete';
        }

        if (announcement.doc.publishDate > today) {
          type = 'info';
        }
      }

      return className + type;
    };

    /**
     * Figure out if the announcement is current,
     * meaning if it is published and not expired.
     * @param  {Object}  announcement The announcement object.
     * @return {Boolean}              True if it is current, False if not.
     */
    service.isCurrent = function(announcement) {
      var today = Utils.now();

      return announcement.status === 'active' &&
            announcement.publishDate <= today &&
            (!announcement.expireDate || announcement.expireDate >= today);
    };

    /**
     * Mark an announcement as read and dismissed.
     * @param  {Object} announcement The announcement.
     * @param  {String} user         The user that acts upon the announcement.
     * @param  {Object} extras       The extras object.
     * @return {Object}              The announcement, marked.
     */
    service.markAnnouncement = function(announcement, user, extras) {
      var announcementExtras = _.chain(extras)
              .filter(function(extra) {
                return extra.user === user && extra.announcement === announcement._id;
              })
              .value();

      if (announcementExtras.length) {
        announcementExtras.forEach(function(extra) {
          announcement.isRead = announcement.isRead || !_.isUndefined(extra.readDate);
          announcement.isDismissed = announcement.isDismissed ||
                                      !_.isUndefined(extra.dismissedDate);
        });
      }

      return announcement;
    };

    /**
     * Figure out if the announcement is scheduled to be shown to users at a later time,
     * meaning it is published and the publish date is after today.
     * @param  {Object}  announcement The announcement object.
     * @return {Boolean}              True if it is scheduled, False otherwise.
     */
    service.isScheduled = function(announcement) {
      var today = Utils.now();

      return announcement.status === 'active' && announcement.publishDate > today;
    };

    /**
     * Figure out if an announcement is expired,
     * meaning if is published and the expiry date is before today.
     * @param  {Object}  announcement The announcement object.
     * @return {Boolean}              True if it is expired, False otherwise.
     */
    service.isExpired = function(announcement) {
      var today = Utils.now();

      return announcement.status === 'active' && announcement.expireDate < today;
    };

    /**
     * Figure out if the announcement is visible for a user.
     * @param  {Object}  announcement The announcement object.
     * @param  {Array}   roles        The user's roles.
     * @param  {Array}   relations    The user's relations.
     * @param  {String}  user         The user's username.
     * @return {Boolean}              True if the announcement should be shown, False otherwise.
     */
    service.isVisible = function(announcement, roles, relations, user) {
      var visibleForRolesRelations = false,
          hasRoles = _.isArray(announcement.visibleForRoles) &&
                    announcement.visibleForRoles.length,
          hasRelations = _.isArray(announcement.visibleForRelations) &&
                        announcement.visibleForRelations.length,
          visibleForUsers = _.isArray(announcement.visibleForUsers) &&
                          announcement.visibleForUsers.length;

      if (hasRelations) {
        relations = _.flattenDeep(_.values(relations));
      }

      if (hasRoles && !hasRelations) {
        visibleForRolesRelations = _.intersection(roles, announcement.visibleForRoles).length;
      }

      if (hasRelations && !hasRoles) {
        visibleForRolesRelations = _.intersection(relations, announcement.visibleForRelations).length; // eslint-disable-line max-len
      }

      if (hasRoles && hasRelations) {
        visibleForRolesRelations = _.intersection(roles, announcement.visibleForRoles).length &&
                  _.intersection(relations, announcement.visibleForRelations).length;
      }

      if (visibleForUsers) {
        visibleForUsers = _.indexOf(announcement.visibleForUsers, user) !== -1;
      }

      return visibleForRolesRelations || visibleForUsers;
    };

    /**
     * Figure out if the announcement can be reordered,
     * meaning if is published and has no expiry date or is not expired.
     * @param  {Object} announcement The announcement object.
     * @return {Boolean}             True if it can be reordered, False otherwise.
     */
    service.canBeReordered = function(announcement) {
      var today = Utils.now();

      return announcement.status === 'active' &&
            (!announcement.expireDate || announcement.expireDate >= today);
    };

    return service;
  }

  AnnouncementsUtils.$inject = ['ProfileService', 'UtilsService'];

  angular.module('component.announcements')
    .service('AnnouncementsUtils', AnnouncementsUtils);
})();
