(function() {
  'use strict';

  function DocumentBrowser($q, DocumentSearch, Documents, DocumentFactory, List) {
    return {
      restrict: 'E',
      scope: {
        folderChanged: '&?'
      },
      templateUrl: 'app/components/documents/directives/document.browser.html',
      link: function(scope) {
        scope.searchModel = { filteredBy: {} };
        function loadSearch() {
          return DocumentSearch.search
            .then(function(search) {
              scope.search = search;
            });
        }

        function loadCurrentPath() {
          scope.currentPath = [];
          if (scope.folderId === undefined) {
            return $q.when();
          }

          return Documents.find(scope.folderId, { cache: 'cached' })
            .then(function(doc) {
              scope.currentPath = doc.path || [];
            });
        }

        function makeDocs(item) {
          return new DocumentFactory(item.doc);
        }

        function loadList(folderId) {
          scope.folderId = folderId;
          return Documents.findByFolderId(folderId)
            .then(function(result) {
              var items = _.map(result, makeDocs);
              scope.list = new List({
                findOptions: { limit: 10 },
                search: scope.search,
                model: scope.searchModel,
                idField: 'doc._id',
                pagination: 'links'
              });
              scope.list.loadList = loadList;

              return scope.list.doLoadItems(items);
            })
            .then(function() {
              return loadCurrentPath();
            })
            .then(function() {
              scope.folderChanged({ folderId: folderId });
            });
        }

        loadSearch().then(loadList);
        scope.loadList = loadList;

        scope.goToPage = function(page) {
          scope.list.goToPage(+page);
        };

        scope.$on('FolderChanged', function(_evt, args) {
          loadList(args.folderId);
        });
      }
    };
  }

  DocumentBrowser.$inject = [
    '$q',
    'DocumentSearch',
    'DocumentsService',
    'DocumentFactory',
    'ListFactory'
  ];

  angular.module('component.documents')
    .directive('kzDocumentBrowser', DocumentBrowser);
})();
