(function() {
  'use strict';

  function EventsService($q, UserService, EventExtras, Api, Goals, Network, Queue, Cache) {
    var service = new UserService('event');

    service.es_endpoint = 'es_events';

    service.findFor = function(id, username) {
      return Api.get('events', { _id: id }, {}, { noCache: true })
        .then(function(doc) {
          if (username !== undefined && doc.user !== username) {
            $q.reject({ status: 403, message: 'Wrong user' });
          }

          return { doc: doc, isShared: true };
        });
    };

    service.getBlueprints = function(id) {
      return Api.get('eventblueprints', { _id: id })
        .then(function(doc) {
          return doc.blueprints;
        });
    };

    service.findAllFor = function(username, options) {
      var endpoint = 'userevents';
      options = options || {};
      if (options.type === 'private') {
        endpoint = 'userprivateevents';
      }

      return Api.get(endpoint, {}, { username: username }, { noCache: true });
    };

    service.findAllByIds = function(eventIds) {
      return this.findKeys(eventIds);
    };

    service.findAllByIdsFor = function(username, goalId, targetId, periodId) {
      return Api.get(
        'usertargetlinkedevents',
        {},
        { username: username, goal_id: goalId, target_id: targetId, period_id: periodId },
        { noCache: true }
      );
    };

    service.searchOwnIds = function(data) {
      return Api.post('es_events_own_ids', data);
    };

    service.searchIds = function(data) {
      return Api.post('es_events_ids', data);
    };

    service.fetchIds = function(ids) {
      if (ids.length === 0) {
        return $q.when([]);
      }

      return Api.post('events_ids', { ids: ids })
        .then(function(data) {
          return data.hits;
        });
    };

    service.getUniqueEventTypes = function(username) {
      if (Network.isOffline()) {
        return this.findAll()
          .then(function(data) {
            return _.chain(data)
              .map(function(item) {
                return item.doc.eventType;
              })
              .uniq()
              .value();
          })
          .catch(function(error) {
            console.log(error);
            return [];
          });
      }
      return service.getUniqueEventTypesFor(username);
    };

    service.getUniqueEventTypeGroupIds = function() {
      return this.findAll()
        .then(function(data) {
          return _.chain(data)
            .map(function(item) {
              return item.doc.versionGroupId;
            })
            .uniq()
            .value();
        })
        .catch(function(error) {
          console.log(error);
          return [];
        });
    };


    service.getUniqueEventTypesFor = function(username) {
      return Api.get('uniqueeventtypes', {}, { username: username });
    };

    service.invalidate = function() {
      console.log('Trying to invalidate');
      if (!_.isUndefined(service.list)) {
        console.log('Invalidating');
        service.list.loaded = false;
      }

      Cache.remove('events-store-events');

      service.navtree = undefined;
    };

    service.saveFor = function(event) {
      return Api.put('events', event)
        .then(function(doc) {
          return doc;
        });
    };

    service.findByToken = function(token) {
      return Api.get('eventrequests', { _id: token });
    };

    service.removeCompleted = function(doc) {
      return service.findFor(doc._id, doc.user)
        .then(function(doc) {
          return Api.delete('events', { _id: doc.doc._id });
        })
        .then(function() {
          service.invalidate();
        });
    };

    service.closeGoal = function(event, invites, options) {
      var opts = options || {};
      var msg = {
        event_id: event.doc._id,
        section_id: _.last(event.completedSections)._id,
        invitation: invites
      };

      var def;
      if (opts.useQueue) {
        def = Queue.postRequest('section_closegoal', msg);
      } else {
        def = Queue.submitRequest('section_closegoal', msg);
      }

      return def
        .then(function() {
          Goals.invalidateCachedEvent(event.doc._id);
        })
        .finally(function() {
          service.invalidate();
        });
    };

    service.close = function(event, invites) {
      var msg = {
        event_id: event.doc._id,
        section_id: event.currentSection._id,
        invitation: invites
      };

      var useQueue = false;
      // if (event.isLocal) {
      var def;
      if (useQueue) {
        def = Queue.postRequest('section_close', msg, event.doc._id);
      } else {
        def = Queue.submitRequest('section_close', msg, event.doc._id);
      }

      return def
        .finally(function() {
          service.invalidate();
        });
    };

    service.reOpen = function(event) {
      var msg = {
        event: event.doc._id,
        rev: event.doc._rev || event.doc.rev
      };

      return Queue.submitRequest('event_reopen', msg, event.doc._id);
    };

    service.makeObsolete = function(event) {
      var msg = {
        event: event.doc._id,
        rev: event.doc._rev,
        dbName: 'not used',
        originUser: event.originUser || event.doc.user
      };

      return Queue.submitRequest('event_obsolete', msg, event.doc._id);
    };

    service.decrypt = function(id, enc, options) {
      options = options || {};
      options._id = id;
      options.enc = enc;
      return Api.post('eventdecrypt', options);
    };

    service.addLog = function(event, action) {
      return Api.post(
        'eventaddlog',
        { isShared: event.isShared, user: event.doc.user, action: action },
        { pk: event.doc._id }
      );
    };

    // FIXME - update
    service.addCommentLog = function(event, extra) {
      var msg = { event_id: event.doc._id, extra_doc: extra };

      return Queue.postRequest('extra_post_add', msg, event.doc._id);
    };

    service.addExtra = function(event, extra, options) {
      options = options || {};
      if (!event.isMine() || !Network.isOffline()) {
        return EventExtras.saveApi(event.doc._id, extra);
      }

      return EventExtras.save(extra)
        .then(function(data) {
          if (options.justAutosave) {
            return data;
          }

          // Omiting - do not fail if a problem with this
          service.addCommentLog(event, extra)
            .catch(function(err) {
              console.log(err);
            });

          return data;
        });
    };

    service.deleteExtra = function(event, extra) {
      if (!event.isMine() || !Network.isOffline()) {
        return EventExtras.removeApi(event.doc._id, extra);
      }

      return EventExtras.remove(extra._id);
    };

    service.getExtra = function(event) {
      // Get extra only for local ones
      if (!event.isMine() || !Network.isOffline()) {
        return EventExtras.findApi(event.doc._id)
                  .catch(function() {
                    return [];
                  });
      }

      return EventExtras.findForEvent(event.doc._id);
    };

    service.createUploadedDocumentEvent = function(
      eventId,
      documentId,
      _startDate,
      _name,
      _organisation
    ) {
      var data = {
        eventId: eventId,
        documentId: documentId
      };
      return this.doAction('document_create_event', data, documentId);
    };

    service.getPendingSections = function(eventId, sectionId, options) {
      options = options || {};

      var endpoint;
      if (options.onlyCounts) {
        endpoint = 'event_sections_counts';
      } else {
        endpoint = 'event_sections';
      }

      return Api.get(
        endpoint,
        {},
        { event_id: eventId, section_id: sectionId },
        { noCache: true }
      )
      .then(function(data) {
        return data.result;
      });
    };

    service.getSectionsMetaData = function(eventId, sectionId) {
      return Api.get(
        'event_sections_meta_data',
        {},
        { event_id: eventId, section_id: sectionId },
        { noCache: true }
      )
      .then(function(data) {
        return data.result;
      });
    };

    service.getSectionTokens = function(eventId, sectionId) {
      return Api.get(
        'event_sections_tokens',
        {},
        { event_id: eventId, section_id: sectionId },
        { noCache: true }
      )
      .then(function(data) {
        return data.result;
      });
    };

    service.sectionAccept = function(sectionId) {
      return Api.post('section_accept', {}, { section_id: sectionId });
    };

    service.sectionReject = function(sectionId) {
      return Api.post('section_reject', {}, { section_id: sectionId });
    };

    service.sectionReopen = function(sectionId) {
      return Api.post('section_reopen', {}, { section_id: sectionId });
    };

    service.getSection = function(sectionId) {
      return Api.get('sections', { _id: sectionId }, {}, { noCache: true });
    };

    service.saveSection = function(sectionData) {
      return Api.put('sections', sectionData)
        .then(function(res) {
          sectionData._rev = res.rev;
          return sectionData;
        });
    };

    service.doAction = function(action, actionData, relatedId, options) {
      options = options || {};
      var preferQueue = Network.isOffline() && !options.forceApi;
      if (preferQueue || options.queue) {
        return Queue.postRequest(action, actionData, relatedId);
      }

      // For all event actions we want to try and use async
      actionData.async_target_linking = true;
      return Queue.submitRequest(action, actionData, relatedId);
    };

    service.updateGoalsetDueDate = function(eventId, sectionId, newDueDate) {
      var data = { eventId: eventId, sectionId: sectionId, dueDate: newDueDate };
      return Api.post('updategoalsetduedate', data);
    };

    service.updateGoalsetPeriods = function(eventId, sectionId, newPeriods) {
      var data = { eventId: eventId, sectionId: sectionId, periods: newPeriods };
      return Api.post('updategoalsetperiods', data);
    };

    return service;
  }

  EventsService.$inject = [
    '$q',
    'UserService',
    'EventExtrasService',
    'ApiService',
    'GoalsService',
    'NetworkService',
    'QueueService',
    'CacheService'
  ];

  angular.module('component.events')
    .factory('EventsService', EventsService);
})();
