(function() {
  'use strict';

  function UserEventsController(resolvedFilter, $q, $scope, $stateParams, $state, $rootScope,
                                Profile, Event, Events, EventSection,
                                EventSearch, EventUtils, EventTypes,
                                Blueprints, APIList, Utils, Security, UsersStub) {
    var ctrl = this;

    ctrl.username = $stateParams.user;
    if (_.isEmpty(ctrl.username)) {
      Utils.unauthorized(
        { message: 'You are not authorised to access this page. User is not defined.' }
      );
      Utils.setPageTitle('User events');
    } else {
      UsersStub.find(ctrl.username)
        .then(function(user) {
          var fullName = _.trim(
            (user.firstName || '') + ' ' + (user.lastName || '')
          );
          Utils.setPageTitle(fullName + '\'s events');
        })
        .catch(function() {
          Utils.setPageTitle('User events');
        });
    }


    ctrl.options = {
      highlightField: 'text'
    };
    ctrl.todoOptions = {
      trackBy: 'id',
      hideEndOfListText: true
    };
    ctrl.resolvedFilter = resolvedFilter;
    var listName = resolvedFilter ? 'userevents-' + resolvedFilter.id : 'userevents';

    var makeDocs = function(item) {
      if (item.type === 'event') {
        return new Event(item, { noExtra: false, isLocal: false });
      } else if (item.type === 'eventSection') {
        return new EventSection(item, { noExtra: false, isLocal: false });
      }
    };

    var loadEvents = function() {
      return $q.all([
        EventTypes.findAll(),
        Blueprints.findAll()
      ])
      .then(function() {
        $scope.$watch(function() {
          return ctrl.list.search.filteredBy.blueprint;
        }, function() {
          ctrl.list.doSearch();
        });
      })
      .catch(function(error) {
        Utils.showError(error, function() {
          $state.go('epf.users.index');
        });
      });
    };

    function setupList(search) {
      var defaultFilter = _.assignIn(
        {},
        search.defaultFilter || {},
        { extraFilter: (resolvedFilter || {}).filters || [] },
        { user: ctrl.username }
      );

      ctrl.searchModel = Profile.getListPreferences(listName).current;
      ctrl.options.search = ctrl.searchModel;

      ctrl.list = new APIList(Events, {
        search: search,
        model: ctrl.searchModel,
        defaultFilter: defaultFilter,
        makeDocs: makeDocs
      });

      return loadEvents();
    }

    function setupTodoList() {
      return EventUtils.getList('todosearch', ctrl.searchModel, {
        defaultFilter: {
          extendedState: ['draft'],
          eventOwner: ctrl.username
        },
        listOptions: {
          maxSize: 5
        }
      }).then(function(list) {
        ctrl.sectionList = list;
        return ctrl.sectionList.doLoadItems();
      });
      // return $q.all([
      //   EventSections.findAllByUser(ctrl.username, 'draft'),
      //   EventSearch.getSearch('todosearch')
      // ])
      //   .then(function(result) {
      //     var sections = result[0];
      //     var search = result[1];
      //     var items = _.map(sections, function(item) {
      //       return new EventSection(item.doc);
      //     });

      //     ctrl.sectionList = new List({
      //       search: search
      //     });
      //     return ctrl.sectionList.doLoadItems(items);
      //   });
    }

    setupTodoList()
      .then(function() {
        return EventSearch.getSearch('usersearch');
      })
      .then(function(search) {
        return setupList(search);
      })
      .then(function() {
        ctrl.loaded = true;
        $rootScope.$broadcast('KZCurrentListChange', { list: listName, search: ctrl.list.search });
      });

    $scope.$on('KZStoreInvalidated', function(_evt, args) {
      if (args.type === 'event' || args.type === 'eventSection') {
        setupTodoList();
      }
    });

    ctrl.actions = [
      {
        label: 'Create new',
        icon: 'icon-plus',
        href: (
          function() { return $state.href('epf.events.new', { user: ctrl.username }); }
        )(),
        showCondition: Security.hasPermissionFor.bind(this, 'events.create', ctrl.username),
        klass: 'btn-success'
      }
    ];
  }

  UserEventsController.$inject = [
    'resolvedFilter',
    '$q',
    '$scope',
    '$stateParams',
    '$state',
    '$rootScope',
    'ProfileService',
    'EventFactory',
    'EventsService',
    'EventSectionFactory',
    'EventSearch',
    'EventUtils',
    'EventTypesService',
    'BlueprintsService',
    'APIListFactory',
    'UtilsService',
    'SecurityService',
    'UsersStubService'
  ];

  angular.module('component.users')
    .controller(
      'UserEventsController', UserEventsController);
})();
