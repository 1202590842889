import './component.module.js';
import './settings';
import './blueprints';
import './importer';
import './relations';
import './goals';
import './email-templates';
import './event-types';
import './drafts';
import './admin';
import './dashboard-templates';
import './roles';
import './announcements';
import './faqs';
import './dashboard';
import './organisations';
import './user-fields';
import './accounts';
import './users';
import './documents';
import './todos';
import './events';
import './email-logs';
import './reports';
