(function() {
  'use strict';

  function DocumentController($scope, $timeout, $window, Documents, DocumentUtils, Utils) {
    var ctrl = this;

    ctrl.fileIcon = DocumentUtils.getFileIcon($scope.document.doc.documentType);
    ctrl.documentBaseName = DocumentUtils.getBaseName($scope.document.doc.name);

    ctrl.load = function() {
      $scope.loadList({ id: $scope.document.doc._id });
    };

    ctrl.linkDocument = function() {
      $scope.linkDocument({ document: $scope.document.doc });
    };

    ctrl.unlinkDocument = function() {
      $scope.unlinkDocument({ document: $scope.document.doc });
    };

    ctrl.open = function() {
      var toutPromise = $timeout(function() {
        ctrl.message = 'Please wait...';
      }, 500);

      Documents.getDownloadToken($scope.document.doc)
        .then(function(data) {
          $timeout.cancel(toutPromise);
          $window.location = data.url;
          ctrl.message = '';
        })
        .catch(function(error) {
          console.log(error);
          $timeout.cancel(toutPromise);
          ctrl.message = '';
          var message = 'Unable to retrieve document url';
          if (error && error.message) {
            message = error.message;
          }
          Utils.showError({ message: message });
        });
    };
  }

  function DocumentDirective() {
    return {
      restrict: 'E',
      scope: {
        document: '=',
        loadList: '&',
        linkDocument: '&?',
        unlinkDocument: '&?',
        isLinked: '=?'
      },
      templateUrl: 'app/components/documents/directives/kz-document.html',
      controller: DocumentController,
      controllerAs: 'documentCtrl'
    };
  }

  DocumentController.$inject = [
    '$scope',
    '$timeout',
    '$window',
    'DocumentsService',
    'DocumentUtilsService',
    'UtilsService'
  ];

  angular.module('component.documents')
    .directive('kzDocument', DocumentDirective);
})();
