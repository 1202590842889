import './organisations.module.js';
import './organisation.search.js';
import './organisations.controller.js';
import './organisation.controller.js';
import './localization.service.js';
import './organisation.settings.controller.js';
import './organisations.service.js';
import './organisations.route.js';
import './cluster.service.js';
import './organisation.controlpanel.component.js';
import './turnitin.controlpanel.component.js';
import './form.popup.component.js';
