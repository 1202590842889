(function() {
  'use strict';

  function Controller($state, $rootScope, $scope, $log, Profile,
                      EventUtils, Utils, Auth) {
    var ctrl = this;

    var listName = 'events';
    ctrl.options = {
      highlightField: 'name',
      trackBy: 'id',
      borderClass: function(item) {
        return item.main.getBorderClass();
      }
    };
    ctrl.username = Auth.currentUser();

    ctrl.prefs = Profile.getPreferences();
    ctrl.model = _.assignIn({
      selected: []
    }, ctrl.prefs.bulkTagging || {});

    ctrl.searchModel = Profile.getListPreferences(listName).current;
    ctrl.options.search = ctrl.searchModel;

    EventUtils.getList('dbsearch', ctrl.searchModel, { resolvedFilter: ctrl.resolvedFilter })
      .then(function(list) {
        ctrl.list = list;
        ctrl.list.doLoadItems();
        $rootScope.$broadcast('KZCurrentListChange', { list: listName });
        $rootScope.loading = false;
        ctrl.loaded = true;
      })
      .catch(function(error) {
        $rootScope.loading = false;
        ctrl.loaded = true;
        $log.warn(error);
      });

    ctrl.actions = [
      {
        label: 'Select tags',
        icon: 'icon-edit',
        href: (function() { return $state.href('epf.events.selecttags'); })(),
        klass: 'btn-success'
      },
      {
        label: 'Exit bulk tagging',
        icon: 'icon-cancel',
        href: (function() { return $state.href('epf.events.index'); })(),
        klass: 'btn-warning'
      }
    ];

    var addTag = function(item) {
      var extra = {
        blueprints: ctrl.model.selected,
        tags: [],
        documents: []
      };

      item.addExtra(extra)
        .then(function() {
          return item.reload();
        })
        .then(function() {
          $scope.$broadcast('kzReloadActions');

          // Utils.swal({
          //   title: 'Nice!',
          //   text: 'Tags have been applied',
          //   type: 'success',
          //   showConfirmButton: false,
          //   timer: 500
          // });
        })
        .catch(function(err) {
          Utils.showError(err);
        });
    };

    // Override event default buttons
    ctrl.options.actionButtons = function(item) {
      return [
        {
          label: 'Apply tags',
          icon: 'icon-edit',
          onClick: addTag.bind(this, item),
          showCondition: function() {
            if (_.difference(ctrl.model.selected, item.categories).length === 0) {
              return false;
            }

            return true;
          },
          klass: 'text-info'
        },
        {
          label: 'Tags already applied',
          icon: 'icon-edit',
          labelOnly: true,
          showCondition: function() {
            return _.difference(ctrl.model.selected, item.categories).length === 0;
          },
          klass: 'text-info'
        }
      ];
    };
  }

  Controller.$inject = [
    '$state',
    '$rootScope',
    '$scope',
    '$log',
    'ProfileService',
    'EventUtils',
    'UtilsService',
    'AuthService'
  ];

  angular.module('component.events')
    .controller('ApplyTagsController', Controller);
})();
