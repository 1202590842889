(function() {
  'use strict';

  function DocumentsBulkMoveController(
    $state,
    $stateParams,
    Documents,
    Utils,
    ICON_FILES_EXTENSIONS_MAP
  ) {
    var ctrl = this;

    ctrl.getFileIcon = function(documentType) {
      return ICON_FILES_EXTENSIONS_MAP[documentType];
    };

    // build breadcrumbs
    ctrl.breadcrumbs = [];
    if ($stateParams.folderId) {
      Documents.find($stateParams.folderId)
        .then(function(document) {
          Utils.setPageTitle('Bulk move documents: ' + document.name);
          document.path.forEach(
            function(docId) {
              Documents.find(docId)
                .then(function(document) {
                  ctrl.breadcrumbs.push(document);
                });
            }
          );
        });
    } else {
      Utils.setPageTitle('Bulk move documents');
    }

    ctrl.isLoading = true;
    Documents.getSource()
      .then(function(source) {
        ctrl.source = source;
        if (ctrl.source.length === 0) {
          $state.go('epf.documents.index');
        }
      })
      .then(function() {
        if ($stateParams.folderId) {
          return Documents.findFolderByPath($stateParams.folderId);
        }

        return Documents.findFolderRoot();
      })
      .then(function(documents) {
        // do not show source folder
        var sourceIds = _.map(ctrl.source, function(doc) {
          return doc._id;
        });

        return _.filter(documents, function(document) {
          return sourceIds.indexOf(document.doc._id) === -1;
        });
      })
      .then(function(documents) {
        ctrl.documents = documents;
        ctrl.isLoading = false;
      });

    ctrl.selectBulkDestination = function(folder) {
      Documents.saveDestination(folder);
      $state.go('epf.documents.bulkconfirmation', { action: 'move' });
    };
  }

  DocumentsBulkMoveController.$inject = [
    '$state',
    '$stateParams',
    'DocumentsService',
    'UtilsService',
    'ICON_FILES_EXTENSIONS_MAP'
  ];

  angular.module('component.documents')
    .controller('DocumentsBulkMoveController', DocumentsBulkMoveController);
})();
