(function() {
  'use strict';

  function DocumentSearch($q) {
    var getSearch = function() {
      var search = {
        facetly: {
          facets: [
            {
              id: 'name',
              type: 'text',
              label: 'Start typing to search'
            }
          ],
          options: {
            defaultFacet: 'name',
            placeholder: 'Start typing to search...',
            listMaxItems: 10
          }
        },
        filters: [
          {
            id: 'name',
            directive: 'kz-field-filter',
            type: 'string',
            label: 'Document name or type matches',
            required: false,
            matchFunc: function(item, _key, value) {
              if (!value) { return true; }

              var val = value.toLowerCase(),
                  name = item.doc.name.toLowerCase() + item.doc.documentType.toLowerCase();
              return name.indexOf(val) > -1;
            }
          }
        ],
        orders: {
          isFolder: function(item) {
            return item.doc.documentType === 'folder' ? 0 : 1;
          },
          name_sort: function(item) {
            if (_.isUndefined(item.doc.name)) {
              return '';
            }

            return item.doc.name.toLowerCase();
          }
        },
        orderGroups: {
          foldersThenFiles: {
            title: 'foldersThenFiles',
            orders: ['isFolder', 'name_sort']
          }
        },
        defaultFilter: {
        },
        defaultOrder: 'foldersThenFiles'
      };

      return $q.when(search);
    };

    return {
      search: getSearch()
    };
  }

  DocumentSearch.$inject = ['$q'];

  angular.module('component.documents')
    .service('DocumentSearch', DocumentSearch);
})();
