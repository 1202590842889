(function() {
  'use strict';

  function DocumentsService($q, $injector, UserService, Api, Events) {
    var service = new UserService('document');

    service.findRoot = function() {
      return this.findAll()
        .then(function(items) {
          return _.filter(items, function(item) {
            return item.doc.path.length === 1;
          });
        });

      // return query('user/find_root', { include_docs: true })
      //   .then(function(results) {
      //     return results;
      //   });
    };

    service.findByPath = function(folderId) {
      // Setting maxAge to zero here so that the promise is disregarded immediately
      return this.findAll({ maxAge: 0 })
        .then(function(items) {
          return _.filter(items, function(item) {
            if (item.doc.path.length < 2) {
              return false;
            }
            return item.doc.path[item.doc.path.length - 2] === folderId;
          });
        });

      // var path = [folderId],
      //     options = {
      //       startkey: path,
      //       endkey: path.concat([{}]),
      //       include_docs: true
      //     };
      //
      // return query('user/documents_by_path', options)
      //   .then(function(results) {
      //     return results;
      //   });
    };

    service.findFolderRoot = function() {
      return this.findRoot()
        .then(function(items) {
          return _.filter(items, function(item) {
            return item.doc.documentType === 'folder';
          });
        });
    };

    service.findFolderByPath = function(folderId) {
      return this.findByPath(folderId)
        .then(function(items) {
          return _.filter(items, function(item) {
            return item.doc.documentType === 'folder';
          });
        });

      // var path = [folderId],
      //     options = {
      //       startkey: path,
      //       endkey: path.concat([{}]),
      //       include_docs: true
      //     };
      //
      // return query('user/folders_by_path', options);
    };

    service.findByFolderId = function(folderId) {
      if (folderId) {
        return service.findByPath(folderId);
      }

      return service.findRoot();
    };

    service.linkedEventsLocal = function(id) {
      return $injector.invoke(['EventsStore', function(EventsStore) {
        return EventsStore.loadGrouped()
          .then(function(events) {
            return _.chain(events)
              .filter(function(group) {
                if (group.event && group.event.documents
                    && group.event.documents.indexOf(id) !== -1) {
                  return true;
                }

                return _.some(group.sections || [], function(section) {
                  return section.documents && section.documents.indexOf(id) !== -1;
                });
              })
              .map(function(item) {
                return item.id;
              })
              .value();
          });
      }]);
    };

    service.linkedEvents = function(id) {
      var _this = this;
      // return IDs of events which have linked this document
      // EventsService.query search through both shared and private database
      if (!_.isEmpty(id)) {
        return Events.store.query('events_by_document', { key: id })
          .then(function(results) {
            return _.map(results.rows, 'value');
          })
          .catch(function(err) {
            if (err && err.status === 509) {
              return _this.linkedEventsLocal(id);
            }

            return $q.reject(err);
          });
      }
    };

    /**
     * Return all items in the given folder
     *
     * This includes the folder as well

     * @param  {uuid} folderId   The folder to get the items from
     * @return {Array}           List of documents
     */
    service.findSubTree = function(folderId) {
      return this.findAll({ maxAge: 0 })
        .then(function(items) {
          return _.filter(items, function(item) {
            return item.doc.path.indexOf(folderId) !== -1;
          });
        });
    };

    service.removeById = function(documentId) {
      return Api.delete('document', {}, { pk: documentId });
    };

    service.getUploadToken = function(username, fileExtension, fileSize) {
      return Api.post(
        'get_upload_token',
        {
          username: username,
          file_extension: fileExtension,
          file_size: fileSize
        }
      );
    };

    service.getDownloadToken = function(doc) {
      return Api.get('get_download_token', {}, { pk: doc._id });
    };

    service.getUploadInfo = function() {
      // Disabled until it is configurable
      service.upload_info = { max_file_size: undefined };

      if (service.upload_info) {
        return $q.when(service.upload_info);
      }

      return Api.get('documents_upload_info')
        .then(function(res) {
          service.upload_info = res;
          return res;
        });
    };

    service.userDocumentLinks = function(document) {
      return Api.get(
        'userdocumentlinks',
        {},
        {
          username: document.user,
          document_id: document._id
        }
      );
    };

    service.getRateSpace = function(username) {
      return Api.get('documents_space_taken', {}, { username: username });
    };

    return service;
  }

  DocumentsService.$inject = [
    '$q',
    '$injector',
    'UserService',
    'ApiService',
    'EventsService'
  ];

  angular.module('component.documents')
    .factory('DocumentsService', DocumentsService);
})();
