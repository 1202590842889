import './documents.module.js';
import './directives';
import './bulk';
import './cards';
import './documents.search.js';
import './documents.bulk.move.controller.js';
import './documents.controller.js';
import './document.simple.slot.js';
import './documentsStub.service.js';
import './documents.service.js';
import './document.browser.popup.js';
import './document.rename.js';
import './documents.bulk.confirmation.controller.js';
import './document-utils.service.js';
import './document.slot.js';
import './documents.constants.js';
import './document.factory.js';
import './documents.route.js';
import './documents.cards.js';
import './documents.directives.js';
