(function() {
  'use strict';

  var permissions = [
    {
      name: 'credential.manage.own',
      description: 'Allow user to manage their own login credentials'
    },
    {
      name: 'credential.manage',
      description: 'Allow user to manage the login credentials of other users'
    },
    {
      name: 'users.create',
      description: 'Allow user to create new users'
    },
    {
      name: 'users.edit',
      description: 'Allow user to edit the profile of other users'
    },
    {
      name: 'users.edit.locked',
      description: 'Allow user to edit locked user fields'
    },
    {
      name: 'users.view',
      description: 'Allow user to view users list and profile details'
    },
    {
      name: 'users.review',
      description: 'Allow user to disable and enable users'
    },

    {
      name: 'users.lase',
      description: 'Allow user to login as other users'
    },

    // {
    //   name: 'users.delete.own',
    //   description: 'Allow user to delete their profile'
    // },
    {
      name: 'users.edit.own',
      description: 'Allow user to edit their own profile'
    },

    {
      name: 'roles.delete',
      description: 'Allow user to delete roles'
    },
    {
      name: 'roles.edit',
      description: 'Allow user to create and edit roles'
    },
    {
      name: 'roles.view',
      description: 'Allow user to view roles list'
    },
    {
      name: 'roles.assign',
      description: 'Allow user to assign roles to other people'
    },
    {
      name: 'roles.assign.own',
      description: 'Allow user to assign roles to themselves'
    },
    {
      name: 'blueprints.edit',
      description: 'Allow user to create and edit blueprints'
    },
    {
      name: 'blueprints.view',
      description: 'Allow user to view blueprints list'
    },
    {
      name: 'eventTypes.edit',
      description: 'Allow user to create and edit event types'
    },
    {
      name: 'eventTypes.view',
      description: 'Allow user to view event types list'
    },
    {
      name: 'events.create',
      description: 'Allow user to create events on the timelines of other users'
    },
    {
      name: 'events.create.own',
      description: 'Allow user to create events on their own timeline'
    },
    {
      name: 'events.delete.own', // REVIEW it does not make sense
      description: 'Allow user to delete their own draft events'
    },
    {
      name: 'events.deleteCompleted',
      description: 'Allow user to delete completed events of other users'
    },
    {
      name: 'events.deleteCompleted.own',
      description: 'Allow user to delete their own completed events'
    },
    {
      name: 'events.deleteMultiSourceResponse',
      description: 'Allow user to remove multi source responses of other users'
    },
    {
      name: 'events.edit',
      description: 'Allow user to fill in sections of other users\' events'
    },
    {
      name: 'events.edit.own',
      description: 'Allow user to edit editable authored sections'
    },
    {
      name: 'events.reviewPending',
      description: 'Allow user to approve all pending sections'
    },
    {
      name: 'events.forceClose',
      description: 'Allow user to close and re-open multi source events of other users'
    },
    {
      name: 'events.forceClose.own',
      description: 'Allow user to close their own multi source events even if the minimum number ' +
      'is not reached'
    },
    {
      name: 'events.reopen.own',
      description: 'Allow user to re-open their own multi source events'
    },
    {
      name: 'events.reopengoal.own',
      description: 'Allow user to re-open their own goal events'
    },
    {
      name: 'events.forceCloseGoal',
      description: 'Allow user to close and re-open the goals of other users'
    },
    {
      name: 'events.editCompleted',
      description: 'Allow user to edit all completed events of other users'
    },
    {
      name: 'events.editCompleted.own',
      description: 'Allow user to edit all their own completed events'
    },

    {
      name: 'events.view',
      description: 'Allow user to view the events of other users'
    },
    {
      name: 'events.view.own',
      description: 'Allow user to view their own events'
    },
    {
      name: 'events.viewAnon',
      description: 'Allow user to view anonymised sections'
    },
    // {
    //   name: 'events.import',
    //   description: 'Allow user to import responses for other users'
    // },
    {
      name: 'comments.deleteall',
      description: 'Allow user to delete all comments'
    },
    {
      name: 'comments.deleteall.own',
      description: 'Allow user to delete all comments on own events'
    },
    {
      name: 'comments.deleteown',
      description: 'Allow user to delete own comments'
    },
    {
      name: 'comments.deleteown.own',
      description: 'Allow user to delete own comments on own events'
    },

    // {
    //   name: 'todos.delete',
    //   description: 'Allow user to delete todos'
    // },
    // {
    //   name: 'todos.edit',
    //   description: 'Allow user to edit todos'
    // },
    // {
    //   name: 'todos.view',
    //   description: 'Allow user to view their own todo list'
    // },

    // GOALS
    {
      name: 'goals.view',
      description: 'Allow user to view the shared goals of other users'
    },
    {
      name: 'goals.work',
      description: 'Allow user to work on shared goals of other users from open goalsets'
    },
    {
      name: 'goals.work.own',
      description: 'Allow user to view and work on their own goals from open goalsets'
    },
    {
      name: 'goals.manage',
      description: 'Allow user to add, edit, delete or resolve any goals of other users'
    },

    // DOCUMENTS
    {
      name: 'documents.view',
      description: 'Allow user to view the document library of other users'
    },
    {
      name: 'documents.view.own', // Review document library permissions
      description: 'Allow user to manage their own document library'
    },
    // {
    //   name: 'documents.edit.own',
    //   description: 'Allow user to manage their own document library'
    // },
    {
      name: 'faqs.view',
      description: 'Allow user to view FAQ'
    },
    {
      name: 'faqs.edit',
      description: 'Allow user to edit FAQ'
    },
    {
      name: 'userFields.edit',
      description: 'Allow user to edit or create user fields'
    },
    {
      name: 'userFields.view',
      description: 'Allow user to view user fields list'
    },
    {
      name: 'relations.view',
      description: 'Allow user to view relations list'
    },
    {
      name: 'relations.edit',
      description: 'Allow user to edit relations'
    },
    {
      name: 'reports.view',
      description: 'Allow user to run reports'
    },
    {
      name: 'reports.edit',
      description: 'Allow user to manage report templates for the entire organisation'
    },
    {
      name: 'announcements.view',
      description: 'Allow user to view announcements list'
    },
    {
      name: 'announcements.edit',
      description: 'Allow user to edit announcements'
    },
    {
      name: 'dashboardTemplates.view',
      description: 'Allow user to view dashboard templates list'
    },
    {
      name: 'dashboardTemplates.edit',
      description: 'Allow user to edit dashboard templates'
    },
    {
      name: 'profileTemplates.view',
      description: 'Allow user to view summary templates list'
    },
    {
      name: 'profileTemplates.edit',
      description: 'Allow user to edit summary templates'
    },
    // {
    //   name: 'reports.edit.own',
    //   description: 'Allow user to manage reports for their own personal use'
    // },
    // {
    //   name: 'reports.view.others',
    //   description: 'Allow user to view and run reports for other users'
    // },
    {
      name: 'organisation.manage-timelines',
      description: 'Allow user to manage timelines'
    },
    {
      name: 'organisation.manage-categories',
      description: 'Allow user to manage goal categories'
    },

    // Importer
    {
      name: 'importer.users',
      description: 'Allow user to import users'
    },
    {
      name: 'importer.events',
      description: 'Allow user to import events'
    },
    {
      name: 'emailTemplates.view',
      description: 'Allow user to view email templates'
    },
    {
      name: 'emailTemplates.edit',
      description: 'Allow user to manage email templates'
    },
    {
      name: 'webhooks.manage',
      description: 'Allow user to manage web hooks'
    },
    {
      name: 'turnitin.viewFull',
      description: 'Allow user to have full access to Turnitin Viewer'
    }
  ];

  var systemRoles = [
    {
      key: 'system:timeline-owner',
      id: 'system:timeline-owner',
      doc: {
        _id: 'system:timeline-owner',
        title: 'Timeline owner',
        description: '',
        roles: []
      }
    },
    {
      key: 'system:system',
      id: 'system:system',
      doc: {
        _id: 'system:system',
        title: 'System',
        description: '',
        roles: []
      }
    },
    {
      key: 'system:anyone',
      id: 'system:anyone',
      doc: {
        _id: 'system:anyone',
        title: 'Anyone',
        description: '',
        roles: []
      }
    },
    {
      key: 'system:superadmin',
      id: 'system:superadmin',
      doc: {
        _id: 'system:superadmin',
        title: 'risr/advance system admin',
        description: '',
        roles: []
      }
    },
    {
      key: 'system:admin',
      id: 'system:admin',
      doc: {
        _id: 'system:admin',
        title: 'Organisation admin',
        description: '',
        roles: []
      }
    },
    {
      id: 'system:pending-user',
      key: 'system:pending-user',
      doc: {
        _id: 'system:pending-user',
        title: 'Pending user',
        description: '',
        roles: ['events.view', 'events.edit', 'users.edit.own', 'documents.view.own']
      }
    },
    {
      id: 'system:anonymous-external',
      key: 'system:anonymous-external',
      doc: {
        _id: 'system:anonymous-external',
        title: 'Anonymous external user',
        description: ''
      }
    }
  ];

  var defaultPermissions = {
    'events.view.own': ['system:pending-user'],
    'events.edit.own': ['system:pending-user']
  };

  angular.module('component.roles')
  .constant('PERMISSIONS', permissions)
  .constant('DEFAULT_PERMISSIONS', defaultPermissions)
  .constant('SYSTEM_ROLES', systemRoles);
})();
