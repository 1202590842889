(function() {
  'use strict';

  var MAPPING = {
    extra: 'Comment',
    blueprints: 'Blueprint',
    documents: 'Document'
  };

  function EventFeaturedController(
    $scope,
    Lunr,
    EventUtils,
    Utils,
    Form
  ) {
    var ctrl = this;
    ctrl.formOptions = { featuredFieldView: true, ignoreRestricted: true };
    ctrl.event = $scope.event;
    var prepareForm = function() {
      var highlight = $scope.highlight;
      try {
        if (!ctrl.event.highlight) {
          ctrl.event.highlight = Lunr.tokenize(highlight);
        }

        ctrl.highlight = _([]).concat(ctrl.event.highlight, highlight.split(' ')).value();
      } catch (err) {
        ctrl.highligh = ctrl.event.highlight;
      }

      ctrl.fields = [];
      ctrl.submodel = {};

      if (ctrl.event.eventType && ctrl.event.eventType.sections) {
        var excludeTypes = $scope.excludeTypes || [];
        ctrl.event.eventType.sections.forEach(function(section) {
          var sectionData;
          if (ctrl.event.doc.type === 'event') {
            sectionData = ctrl.event.doc.sections[section._id];
          } else if (ctrl.event.doc.section === section._id) {
            sectionData = {
              items: [ctrl.event.doc.data]
            };
          } else if (ctrl.event.doc.originalEvent) {
            sectionData = ctrl.event.doc.originalEvent.sections[section._id];
          }
          if (sectionData === undefined || sectionData.items === undefined) {
            return;
          }

          section.fields.forEach(function(field) {
            if (!field.showOnTimeline) {
              return;
            }
            if (excludeTypes.indexOf(field.type) !== -1) {
              return;
            }

            var value;
            var valueId;
            var turnitinValue;
            var turnitinId;
            if (ctrl.event.highlight || []) {
              sectionData.items.forEach(function(item) {
                if (item.fields) {
                  valueId = ctrl.event.getValueId(field);
                  value = item.fields[valueId];
                  // Try turnit in if there
                  turnitinId = '__turnitin__' + valueId;
                  if (item.fields[turnitinId] !== undefined) {
                    turnitinValue = item.fields[turnitinId];
                  }
                }
              });
            }

            var isEmpty = false;
            if (typeof value === 'boolean' || typeof value === 'number') {
              isEmpty = false;
            } else {
              isEmpty = _.isEmpty(value);
            }

            if (!isEmpty) {
              field.sectionId = section._id; // goal field needs this
              ctrl.fields.push(field);
              ctrl.submodel[valueId] = value;
              if (turnitinValue !== undefined) {
                ctrl.submodel[turnitinId] = turnitinValue;
              }
            }
          });
        });
      }

      // Found highlighted fields
      var source = ctrl.event.getSource ? ctrl.event.getSource() : {};
      if (ctrl.event.highlight && source) {
        var res = {};
        var tkn;
        _.forOwn(source, function(value, key) {
          if (['field', 'extra', 'blueprints', 'documents'].indexOf(key) === -1) {
            return;
          }

          if (_.isArray(value)) {
            _.forEach(value, function(item) {
              if (item === undefined || item === null) {
                return;
              }

              if (item.value !== undefined && item.label !== undefined) {
                key = item.label;
                item = item.value;
              }

              // source contains field and blueprints which might ends with duplicate,
              // as blueprints does not have label then we ignore them based on that.
              if (_.isNull(key)) {
                return;
              }

              if (_.find(ctrl.fields, { name: key })) {
                return;
              }

              tkn = Lunr.tokenize(item);
              if (Utils.stringContains(tkn, ctrl.event.highlight)) {
                res[key] = res[key] || [];
                res[key].push(item);
              }
            });
          }
        });

        _.forOwn(res, function(value, key) {
          ctrl.fields.push({
            _id: key,
            name: MAPPING[key] || key,
            type: 'customField',
            blueprintType: 'string',
            isRequired: true
          });
          ctrl.submodel[key] = value;
        });
      }

      if (ctrl.fields.length) {
        ctrl.form = new Form();
        ctrl.fields.forEach(function(field) {
          var fld = EventUtils.createFormField(
            field,
            { formState: { readOnly: true } },
            { ignoreRestricted: true }
          );
          ctrl.form.addField(fld);
        });
      }
    };
    $scope.$watch(function() {
      return $scope.event.loaded;
    }, function(loaded) {
      if (loaded) {
        prepareForm();
      }
    });
  }

  EventFeaturedController.$inject = [
    '$scope',
    'KZLunr',
    'EventUtils',
    'UtilsService',
    'FormsService'
  ];

  function EventFeaturedDirective() {
    return {
      scope: {
        event: '=',
        hightlight: '=',
        user: '=',
        excludeTypes: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/events/directives/event-featured.html',
      controller: EventFeaturedController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('events.directives')
    .directive('eventFeatured', EventFeaturedDirective)
    .controller('EventFeaturedController', EventFeaturedController);
})();
