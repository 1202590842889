(function() {
  'use strict';

  function EventTypePredefinedFieldsService(ConfService) {
    var service = new ConfService('predefinedField');

    return service;
  }

  EventTypePredefinedFieldsService.$inject = ['ConfService'];

  angular.module('component.eventTypes')
    .factory('EventTypePredefinedFieldsService', EventTypePredefinedFieldsService);
})();
